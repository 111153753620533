import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IReviewInstance, ISection, ISectionQuestion, ISectionQuestionAnswer} from 'src/app/models/interfaces/review-template';
import {DatePipe} from '@angular/common';
import {ReviewState} from 'src/app/store/reducers/review.reducer';
import {select, Store} from '@ngrx/store';
import {getReviewInstanceById, updateReviewInstance} from 'src/app/store/actions/review.actions';
import {selectIsSelectedReviewInstanceLoading, selectSelectedReviewInstance} from 'src/app/store/selectors/review.selectors';
import {EmployeeState} from 'src/app/store/reducers/employee.reducer';
import {selectReviewProfile} from 'src/app/store/selectors/employee.selectors';
import {IEmployeeBambooHR} from 'src/app/models/interfaces/employee-profile';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IStep, STEPSTATUS} from 'src/app/models/interfaces/steps';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-complete-review',
  templateUrl: './complete-review.component.html',
  styleUrls: ['./complete-review.component.scss']
})
export class CompleteReviewComponent implements OnInit, OnDestroy {

  // Review Instances and Sections
  reviewId = '';
  isReviewInstanceLoading: boolean = true;
  reviewInstance: IReviewInstance = null;
  sectionIndex: number = 0;
  firstLoad: boolean = false;

  // Completion
  showStartPage: boolean = true;
  canComplete: boolean = false;
  canPressNext: boolean = false;

  // Employee Details
  reviewCandidate$: Observable<IEmployeeBambooHR>;

  // Memory Leak Management
  private readonly destroy$ = new Subject<void>();

  // User Display
  steps: IStep[] = [];

  constructor(
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private reviewStore: Store<ReviewState>,
    private employeeStore: Store<EmployeeState>,
    private notification: NzNotificationService,
    private zone: NgZone
  ) { }

  /**
   * Method that is called upon component initialization.
   */
  ngOnInit(): void {
    try {
      this.reviewId = this.route.snapshot.paramMap.get('reviewSessionId');
      this.reviewStore.dispatch(getReviewInstanceById({reviewInstanceId: this.reviewId}));
    } catch (e) {
      console.error(e, 'Could not load session');
      this.isReviewInstanceLoading = false;
    }

    this.reviewStore
      .pipe(
        select(selectIsSelectedReviewInstanceLoading),
        takeUntil(this.destroy$)
      )
      .subscribe((isInstanceLoading: boolean) => {
        this.isReviewInstanceLoading = isInstanceLoading;
      });

    this.reviewStore
      .pipe(
        select(selectSelectedReviewInstance),
        takeUntil(this.destroy$)
      )
      .subscribe((reviewInstance: IReviewInstance) => {
        if (reviewInstance) {
          this.determineSectionIndex(reviewInstance);
          this.determineStepProgress(reviewInstance);
        }

      }); // end subs

    this.reviewCandidate$ = this.employeeStore.select(selectReviewProfile);
  }

  /**
   * Destroys and handles all memory leaks where subscriptions are used on observable streams.
   */
  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  /**
   * Start the current review and clear all the current indexes.
   */
  startReview(): void {
    this.canPressNext = false;
    this.showStartPage = false;
    this.canComplete = false;
    this.sectionIndex = 0;
    this.save();
  }

  /**
   * Navigate to the previous section/page
   */
  previousPage(): void {
    this.sectionIndex--;
    this.canContinueNext();
    this.scrollToTop();
  }

  /**
   * Navigate to the next section/page.
   * @param saving Should the review instance be saved or not.
   */
  nextPage(saving: boolean = true): void {
    if (this.canPressNext) {
      this.sectionIndex++;
      if (saving) {
        this.save();
      }
      this.canContinueNext();
      this.scrollToTop();
    } else {
      this.displayErrorNotification('All compulsory questions in this section needs to be answered before moving on to the next section.');
    }
  }

  /**
   * Changes the index between the steps to the selected index.
   * @param index The index of the selected step as a number.
   */
  reviewInstanceProgressChange(index: number): void {
    this.sectionIndex = index;
    this.determineStepProgress(this.reviewInstance);
    this.canContinueNext();
  }

  /**
   * Update an answer om the review instance, and saves to the database when required.
   * @param questionId The questions unique identifier.
   * @param answer The answer to the question within the review instance.
   * @param shouldSave Should the answer be saved to the database.
   */
  updateAnswer(questionId: number, answer: ISectionQuestionAnswer[]): void {
    this.reviewInstance.sections.forEach((section: ISection) => {
      const matchedQuestion = section.questions.find((q: ISectionQuestion) => q.reviewTemplateQuestionId === questionId);
      if (matchedQuestion) {
        matchedQuestion.reviewAnswers = answer;
      }
    });
    this.updateCanComplete();
    this.canContinueNext();
  }

  /**
   * This determines whether the review instance can be completed.
   */
  updateCanComplete(): void {
    if (this.reviewInstance?.sections?.length > 0) {
      this.canComplete = true;
      for (const section of this.reviewInstance.sections) {
        for (const question of section.questions) {
          if (question.isMandatory && question.reviewAnswers.length === 0) {
            this.canComplete = false;
            break;
          }
        }
        if (!this.canComplete) {
          break;
        }
      }
    } else {
      this.canComplete = false;
    }
  }

  /**
   * Can the user continue to the next section.
   */
  canContinueNext(): void {
    const section = this.reviewInstance.sections[this.sectionIndex];
    this.canPressNext = this.checkSectionAnswers(section);
  }

  /**
   * All changes on the review instance as they occur are written to the DB.
   * @param isCompleted Determines when the entire survey has been completed or not.
   */
  save(isCompleted: boolean = false): void {
    // Update any fields that are missing
    if (
      !this.reviewInstance.reviewStartTime ||
      this.reviewInstance.reviewStartTime.length === 0 ||
      this.reviewInstance.reviewStartTime === '0001-01-01T00:00:00'
    ) {
      // 2021-02-28T12:59:33.442Z
      this.reviewInstance.reviewStartTime = this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSS') + 'Z';
    }
    // Denote the review instance as 'completed'
    if (isCompleted) {
      this.canPressNext = true;
      // 2021-02-28T12:59:33.442Z
      this.reviewInstance.reviewEndTime = this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSS') + 'Z';
    }
    this.reviewStore.dispatch(updateReviewInstance({reviewInstance: JSON.parse(JSON.stringify(this.reviewInstance))}));
  }

  /**
   * Complete a survey after all sections have been answered.
   * @param isCompleted Determines when the entire survey has been completed or not.
   */
  completeSurvey(isCompleted: boolean): void {
    this.save(isCompleted);
    this.nextPage(false);
  }

  /**
   * Determine the progress of the user within the steps (Navigation between sections)
   * @param reviewInstance An instance of the current review.
   * @private This method is only used within this file and is set to private as such.
   */
  private determineStepProgress(reviewInstance: IReviewInstance): void {
    let lastCompletedSection: number = null;
    this.steps = [];
    if (reviewInstance?.sections && this.reviewInstance.sections.length > 0) {
      reviewInstance.sections.forEach((s: ISection) => {
        let itemStatus: string = STEPSTATUS.wait;
        const itemIndex: number = reviewInstance.sections.indexOf(s, 0) || 0;
        const sectionCompleted = this.checkSectionAnswers(s);
        if (sectionCompleted) {
          // The section has been completed
          lastCompletedSection = itemIndex;
          itemStatus = STEPSTATUS.finish;
        }

        this.steps.push({
          index: itemIndex,
          sectionName: s.sectionName,
          status: itemStatus
        } as IStep);

      });

      this.steps.push({
        index: reviewInstance.sections.length,
        sectionName: 'Done',
        status: STEPSTATUS.wait
      } as IStep);

      if (lastCompletedSection === null) {
        this.steps[0].status = STEPSTATUS.process;
      } else if (lastCompletedSection < this.steps.length - 1) {
        this.steps[lastCompletedSection + 1].status = STEPSTATUS.process;
      }

    }
  }

  private determineSectionIndex(reviewInstance: IReviewInstance): void {
    // Determine on which sectionIndex we are based on answers completed.
    if (!this.firstLoad) {
      this.sectionIndex = 0;
      if (reviewInstance?.sections?.length > 0) {
        for (const section of reviewInstance.sections) {
          const allQuestionsAnswered = section.questions.reduce(
            (questionsAnswered: boolean, question: ISectionQuestion) => questionsAnswered && question.reviewAnswers.length > 0, true);
          if (allQuestionsAnswered) {
            this.sectionIndex++;
          } else {
            break; // catches the instance where there are no questions and it breaks the calculated index
          }
        }
      }
      this.firstLoad = !this.firstLoad;
    }

    // Safety check to not go past the last page
    if (this.sectionIndex === reviewInstance?.sections?.length) {
      this.sectionIndex = Math.max(0, this.sectionIndex); // Don't go lower than zero
    }

    this.showStartPage =
      !reviewInstance?.reviewStartTime ||
      reviewInstance?.reviewStartTime === '0001-01-01T00:00:00' ||
      reviewInstance?.reviewStartTime === '';

    this.reviewInstance = JSON.parse(JSON.stringify(reviewInstance));

    this.updateCanComplete();

  }

  private checkSectionAnswers(section: ISection): boolean {
    let sectionCompleted: boolean = true;
    section.questions?.forEach((question: ISectionQuestion) => {
      if (question.isMandatory && question.reviewAnswers.length === 0) {
        sectionCompleted = false;
      }
      if (question.isMandatory && question.reviewAnswers.length > 0 && question.questionTypeId === 3) {
        if (question.reviewAnswers[0].answerValue === '') {
          sectionCompleted = false;
        }
      }
    });
    return sectionCompleted;
  }

  private displayErrorNotification(content: string): void {
    this.notification.create(
      'error',
      'Error',
      content
    );
  }

  private scrollToTop(): void {
    this.zone.run(() => {
      try {
        const elements = document.getElementsByTagName('nz-content');
        if (elements?.length > 0) {
          elements[0].scrollTo({ top: 0, behavior: 'smooth'});
        }
      } catch (err) {
        console.error(err);
      }
    });
  }

}
