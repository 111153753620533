import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {IReviewBreakdown} from 'src/app/models/interfaces/review-breakdown';
import {deleteReviewInstance, updateReviewInstanceExclusion} from 'src/app/store/actions/review.actions';
import * as ReviewSelectors from '../../../store/selectors/review.selectors';
import {
  selectIsReviewInstancesLoading,
  selectReviewBreakdownList,
  selectReviewIdsBeingUpdated,
  selectReviewInstances
} from 'src/app/store/selectors/review.selectors';
import {selectEmployeeProfile, selectUserProfile} from 'src/app/store/selectors/employee.selectors';
import {IEmployeeBambooHR} from 'src/app/models/interfaces/employee-profile';
import {IReviewInstance, IReviewInstanceUpdate, IReviewTemplate} from 'src/app/models/interfaces/review-template';
import {environment} from 'src/environments/environment';
import Utils from '../../../../utils';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {Observable} from 'rxjs';

export interface IReviewGroup {
  reviewTemplateName: string;
  reviewTemplateId: number;
  description?: string;
  reviews: IReviewInstance[];
}

@Component({
  selector: 'app-employee-page',
  templateUrl: './employee-page.component.html',
  styleUrls: ['./employee-page.component.scss'],
})
export class EmployeePageComponent implements OnInit {
  reviewIdsUpdating$: Observable<Set<string>>;

  employeeProfile$: Observable<IEmployeeBambooHR>;
  userProfile$: Observable<IEmployeeBambooHR>;

  employeeReviewsLoading = true;
  employeeReviewBreakdowns: IReviewBreakdown[] = [];
  searchText: string;

  employeeReviewGroups: IReviewGroup[] = [];
  selectedReviewGroup: IReviewGroup = null;
  reviewTemplateList: IReviewTemplate[] = [];
  selectedReviewTemplate: IReviewTemplate = null;
  selectedReviewTemplateId: number;

  isViewReviewersVisible = false;
  isAddReviewerVisible = false;
  isDeleteModalVisible = false;
  baseApplicationURL: string;
  reviewInstanceToDelete: IReviewInstance;

  constructor(
    private router: Router,
    private readonly store: Store,
    private notification: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.baseApplicationURL = environment.APPLICATION_BASE_URL;

    this.reviewIdsUpdating$ = this.store.select(selectReviewIdsBeingUpdated);
    this.employeeProfile$ = this.store.select(selectEmployeeProfile);
    this.userProfile$ = this.store.select(selectUserProfile);

    this.store.pipe(select(selectReviewBreakdownList))
      .subscribe(breakdownList => this.employeeReviewBreakdowns = breakdownList.sort((a, b) =>
        b.reviewTemplateId - a.reviewTemplateId
      ));

    this.store.pipe(select(selectIsReviewInstancesLoading))
      .subscribe(isLoading => this.employeeReviewsLoading = isLoading);

    this.store.pipe(select(selectReviewInstances))
      .subscribe(reviewInstances => {
        this.employeeReviewsLoading = false;
        this.employeeReviewGroups = [];
        if (reviewInstances?.length > 0) {
          reviewInstances.forEach(reviewInstance => {
            const tempGroup = this.employeeReviewGroups.find(group => group.reviewTemplateId === reviewInstance.reviewTemplateId);
            if (tempGroup) {
              tempGroup.reviews.push(reviewInstance);
            } else {
              this.employeeReviewGroups.push(
                {
                  reviewTemplateName: reviewInstance.reviewTemplateDisplayName,
                  reviewTemplateId: reviewInstance.reviewTemplateId,
                  reviews: [reviewInstance]
                }
              );
            }
          });

          this.updateSelectedReviewGroup();
        }
      });

    this.store.pipe(select(ReviewSelectors.selectReviewTemplates))
      .subscribe(reviewTemplates => {
        this.reviewTemplateList = reviewTemplates;
      });
  }

  formatPercent = (percent: number) => percent === 0 ? '...' : `${Math.round(percent)}%`;

  showAddReviewer(reviewTemplateId: number, event: Event): void {
    event.stopPropagation();
    this.isAddReviewerVisible = true;
    this.selectedReviewTemplate = this.reviewTemplateList?.find(rt => rt.reviewTemplateId === reviewTemplateId);
  }

  showReviewers(reviewTemplateId: number, event: Event): void {
    event.stopPropagation();
    this.selectedReviewTemplateId = reviewTemplateId;
    this.updateSelectedReviewGroup();
    this.isViewReviewersVisible = true;
  }

  copyMessage(message: string): void {
    Utils.copyMessage(environment.APPLICATION_BASE_URL + message, this.notification);
  }

  showDeleteReviewInstance(reviewInstance: IReviewInstance): void {
    this.reviewInstanceToDelete = reviewInstance;
    this.isDeleteModalVisible = true;
  }

  deleteReviewInstance(): void {
    this.isViewReviewersVisible = false;
    this.isDeleteModalVisible = false;
    this.store.dispatch(deleteReviewInstance({reviewInstanceId: this.reviewInstanceToDelete.reviewId}));
  }

  onPrint(reviewTemplateId: number): void {
    this.router.navigate(['/', {
      outlets: {
        print: ['print', reviewTemplateId]
      }
    }
    ]);
  }

  updateReviewInstanceExclusion(review: IReviewInstance): void {
    const reviewInstanceUpdate: IReviewInstanceUpdate = {
      reviewerFullName: review.reviewerFullName,
      reviewerPosition: review.reviewerPosition,
      reviewerEmailAddress: review.reviewerEmailAddress,
      isExcludedFromCalculation: !review.isExcludedFromCalculation
    };
    this.store.dispatch(updateReviewInstanceExclusion({reviewInstanceUpdate, reviewId: review.reviewId}));
  }

  private updateSelectedReviewGroup(): void {
    this.selectedReviewGroup = this.employeeReviewGroups?.length > 0 && this.selectedReviewTemplateId ?
      this.employeeReviewGroups.find(rg => rg.reviewTemplateId === this.selectedReviewTemplateId) : null;
  }

  // To prevent apexchart bug: https://github.com/apexcharts/apexcharts.js/issues/2865
  onSelectUtil(e): void {
    window.dispatchEvent(new Event('resize'));
  }
}
