import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CompleteReviewComponent} from './components/complete-review/complete-review/complete-review.component';
import {MsalGuard} from '@azure/msal-angular';
import {ReviewTemplatesContainerComponent} from './components/review-templates/review-templates-container/review-templates-container.component';
import {PdfLayoutComponent} from './components/shared/pdf-layout/pdf-layout.component';
import {PrintContentComponent} from './components/shared/print-content/print-content.component';
import {SkillsMatrixComponent} from './components/skills-matrix/skills-matrix-container/skills-matrix.component';
import {CreateSkillsMatrixComponent} from './components/create-skills-matrix/create-skills-matrix-container/create-skills-matrix.component';
import {SkillsMatrixGuard} from './skills-matrix.guard';
import {LandingComponent} from 'src/app/components/landing/landing.component';

const routes: Routes = [
  {path: '', component: LandingComponent},
  {path: 'employee/utilisation', redirectTo: 'employee/detail', pathMatch: 'full'},
  {path: 'employee', redirectTo: 'employee/employee-list', pathMatch: 'full'},
  {path: 'skills-matrix', component: SkillsMatrixComponent, canActivate: [SkillsMatrixGuard]},
  {path: 'create-skills-matrix', component: CreateSkillsMatrixComponent, canActivate: [SkillsMatrixGuard]},
  {path: 'review-templates', component: ReviewTemplatesContainerComponent, canActivate: [MsalGuard]},
  {path: 'review/:reviewSessionId', component: CompleteReviewComponent},
  {
    path: 'print',
    outlet: 'print',
    component: PdfLayoutComponent,
    children: [{path: ':reviewTemplateId', component: PrintContentComponent}]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, initialNavigation: 'disabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
