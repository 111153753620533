import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {IEmployeeBambooHR} from './models/interfaces/employee-profile';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {clearReviewTemplateToUpdate} from './store/actions/review.actions';
import {getUserProfile} from './store/actions/employee.actions';
import {
  selectEmployeeProfile,
  selectIsEmployeeProfileFromEmployeeNumberLoading,
  selectUserProfile
} from './store/selectors/employee.selectors';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import {AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest} from '@azure/msal-browser';
import {Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {selectIsApplicationLoading} from './store/selectors/application.selectors';
import {environment} from '../environments/environment';
import {selectIsApplicationMenuVisible} from './store/selectors/config.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  // Menu Information
  isMenuCollapsed: boolean = false;
  // Employee Information
  empId: string;
  userProfile$: Observable<IEmployeeBambooHR>;
  selectedEmployeeProfile$: Observable<IEmployeeBambooHR>;
  isSelectedEmployeeProfileLoading$: Observable<boolean>;
  isApplicationMenuVisible$: Observable<boolean>;
  // Layout Information
  isIframe: boolean = false;
  loginDisplay: boolean = false;

  // Memory Leak Management
  private readonly destroy$ = new Subject<void>();

  // Skills Matrix Feature Flag
  featureFlagSkillsMatrix = environment.featureFlagSkillsMatrix;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private readonly store: Store,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private titleService: Title,
    public router: Router
  ) { }

  ngOnInit(): void {
   // this.showNavigationMenu(false);
    this.isIframe = window !== window.parent && !window.opener;
    this.isSelectedEmployeeProfileLoading$ = this.store.select(selectIsEmployeeProfileFromEmployeeNumberLoading);
    this.isApplicationMenuVisible$ = this.store.select(selectIsApplicationMenuVisible);
    this.selectedEmployeeProfile$ = this.store.select(selectEmployeeProfile);
    this.userProfile$ = this.store.select(selectUserProfile);

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.router.initialNavigation();
      });

    this.store
      .pipe(
        select(selectIsApplicationLoading),
        takeUntil(this.destroy$)
      )
      .subscribe((isLoading: boolean) => {
        this.setApplicationLoading(isLoading);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  resetCurrentSurvey(): void {
    this.store.dispatch(clearReviewTemplateToUpdate());
  }

  setLoginDisplay(): void {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if (this.loginDisplay) {
      this.store.dispatch(getUserProfile());
    }
  }

  checkAndSetActiveAccount(): void {
    const activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect(): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  loginPopup(): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.store.dispatch(getUserProfile());
          }
        );
    } else {
      this.authService.loginPopup()
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: AuthenticationResult) => {
           this.authService.instance.setActiveAccount(response.account);
          }
        );
    }
  }

  logout(popup: boolean = false): void {
    if (popup) {
      this.authService.logoutPopup({mainWindowRedirectUri: '/'});
    } else {
      this.authService.logoutRedirect();
    }
  }

  private setApplicationLoading(isLoading: boolean): void {
    this.titleService.setTitle(isLoading ? 'LevelUp Loading...' : 'LevelUp');
  }
}
