import { createAction, props } from '@ngrx/store';
import {IReviewInstance, IReviewTemplate, IReviewInstanceUpdate} from '../../models/interfaces/review-template';
import { IReviewBreakdown } from '../../models/interfaces/review-breakdown';
import { ICompetency } from '../../models/interfaces/competency';
import { IExperienceLevel } from '../../models/interfaces/experience-level';

// Get, Create, Update, Clone, Delete Review Templates

export const setIsReviewTemplatesLoading = createAction(
  '[Review] Set Is Review Templates Loading',
  props<{ isReviewTemplatesLoading: boolean }>()
);

export const getReviewTemplates = createAction(
  '[Review] Get Review Templates',
  props<{ competencyId?: string, levelId?: string }>()
);

export const getReviewTemplatesComplete = createAction(
  '[Review] Get Review Templates Complete',
  props<{ reviewTemplates: IReviewTemplate[] }>()
);

export const setIsReviewToUpdateLoading = createAction(
  '[Review] Set Is Review Template To Update Loading',
  props<{ isReviewToUpdateLoading: boolean }>()
);

export const getReviewTemplateById = createAction(
  '[Review] Get Review Templates By ID',
  props<{ reviewTemplateId: string }>()
);

export const getReviewTemplateByIdComplete = createAction(
  '[Review] Get Review Template By ID Complete',
  props<{ reviewTemplate: IReviewTemplate }>()
);

export const setIsReviewTemplateSaving = createAction(
  '[Review] Set Is Review Template Saving',
  props<{ isReviewTemplateSaving: boolean; showModal: boolean; modalType: 'info' | 'warning' | 'success' | 'error'; }>()
);

export const createReviewTemplate = createAction(
  '[Review] Create Review Template',
  props<{ reviewTemplate: IReviewTemplate; navigateAfter: boolean; }>()
);

export const updateReviewTemplate = createAction(
  '[Review] Update Review Template',
  props<{ reviewTemplate: IReviewTemplate; navigateAfter: boolean; }>()
);

export const cloneReviewTemplate = createAction(
  '[Review] Clone Review Template',
  props<{ reviewTemplate: IReviewTemplate; navigateAfter: boolean; }>()
);

export const deleteReviewTemplate = createAction(
  '[Review] Delete Review Template',
  props<{ reviewTemplate: IReviewTemplate; showResult: boolean; }>()
);

export const clearReviewTemplateToUpdate = createAction(
  '[Review] Clear Review Template To Update'
);

export const clearReviewInstances = createAction(
  '[Review] Clear Review Instances'
);

export const getReviewInstances = createAction(
  '[Review] Get Review Instances'
);

export const getReviewInstancesComplete = createAction(
  '[Review] Get Review Instances Complete',
  props<{ reviewInstances: IReviewInstance[] }>()
);

export const getReviewInstanceById = createAction(
  '[Review] Get Review Instance By ID',
  props<{ reviewInstanceId: string }>()
);

export const getReviewInstanceByIdComplete = createAction(
  '[Review] Get Review Instance By ID Complete',
  props<{ reviewInstance: IReviewInstance }>()
);

export const scheduleReviewInstance = createAction(
  '[Review] Schedule Review Instances',
  props<{ reviewTemplateId: string, reviewerFullName: string, reviewerPosition: string, reviewerEmailAddress: string }>()
);

export const updateReviewInstance = createAction(
  '[Review] Update Review Instance',
  props<{ reviewInstance: IReviewInstance }>()
);

// Exclude review instance from results calculations
export const updateReviewInstanceExclusion = createAction(
  '[Review] Update Review Instance Exclusion',
  props<{ reviewInstanceUpdate: IReviewInstanceUpdate; reviewId: string }>()
);

export const updateReviewInstanceExclusionComplete = createAction(
  '[Review] Update Review Instance Exclusion Complete',
  props<{ reviewId: string; isExcludedFromCalculation: boolean }>()
);

export const deleteReviewInstance = createAction(
  '[Review] Delete Review Instance',
  props<{ reviewInstanceId: string }>()
);

export const selectReviewBreakdown = createAction(
  '[Review] Select Review Breakdown',
  props<{ reviewBreakdown: IReviewBreakdown }>()
);

export const updateNewestLink = createAction(
  '[Review] Update Newest Link',
  props<{ link: string }>()
);

export const setReviewTemplateSelectedCompetency = createAction(
  '[Review] Set Review Template Selected Competency',
  props<{ selectedCompetency: ICompetency }>()
);

export const setReviewTemplateSelectedExperienceLevel = createAction(
  '[Review] Set Review Template Selected Experience Level',
  props<{ selectedExperienceLevel: IExperienceLevel }>()
);

export const setReviewTemplateSurveyFilterText = createAction(
  '[Review] Set Review Template Survey Filter Text',
  props<{ surveyFilterText: string }>()
);
