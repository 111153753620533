<div class="employee-container-header">

  <div class="emp-container-head">
    <h3>EMPLOYEE LIST</h3>
    <h6>
      <span *ngIf="!isEmployeeListLoading">{{numberOfEmployees}} records found</span>
      <span *ngIf="isEmployeeListLoading">records loading...</span>
    </h6>
  </div>

  <div *ngIf="!isEmployeeListLoading" class="emp-container-search">
    <nz-input-group
      [nzSuffix]="suffixIcon"
      nzSize="large">
      <input
        type="text"
        nz-input
        placeholder="Filter Employees"
        [ngModel]="employeeFilterText"
        (ngModelChange)="onEmployeeFilterChange($event)"/>
    </nz-input-group>

    <ng-template #suffixIcon>
      <i *ngIf="isIconSearchVisible" nz-icon nzType="search" nzTheme="outline"></i>
      <i *ngIf="!isIconSearchVisible" nz-icon nzType="close-circle" nzTheme="outline" (click)="employeeFilterText = null; onClearFilter();"></i>
    </ng-template>

  </div>

</div>
