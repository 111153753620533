import {createReducer, on} from '@ngrx/store';
import * as ConfigActions from '../actions/config.actions';
import {IWeighting} from '../../models/interfaces/weightings';
import {IRating} from 'src/app/models/interfaces/ratings';
import {ICompetency} from 'src/app/models/interfaces/competency';
import {IExperienceLevel} from 'src/app/models/interfaces/experience-level';
import {ISection} from 'src/app/models/interfaces/review-template';

export const configFeatureKey = 'config';

export interface ConfigState {
  // Competencies ========================
  isCompetenciesLoading: boolean;
  competencies: ICompetency[];
  // Levels of Experience ================
  isExperienceLevelsLoading: boolean;
  experienceLevels: IExperienceLevel[];
  // Ratings =============================
  isRatingsLoading: boolean;
  ratings: IRating[];
  // Sections ============================
  isSectionsLoading: boolean;
  sections: ISection[];
  // Weightings ==========================
  isWeightingsLoading: boolean;
  weightings: IWeighting[];
}

export const initialState: ConfigState = {
  // Competencies ========================
  isCompetenciesLoading: false,
  competencies: [],
  // Levels of Experience ================
  isExperienceLevelsLoading: false,
  experienceLevels: [],
  // Ratings =============================
  isRatingsLoading: false,
  ratings: [],
  // Sections ============================
  isSectionsLoading: false,
  sections: [],
  // Weightings ==========================
  isWeightingsLoading: false,
  weightings: []
};


export const reducer = createReducer(
  initialState,
  // ================================================================
  // Competencies
  // ================================================================
  on(ConfigActions.setIsCompetenciesLoading, (state, {isCompetenciesLoading}) => {
    return {...state, isCompetenciesLoading};
  }),
  on(ConfigActions.getCompetencies, state => {
    return {...state, isCompetenciesLoading: true};
  }),
  on(ConfigActions.getCompetenciesComplete, (state, {competencies}) => {
    return {
      ...state,
      isCompetenciesLoading: false,
      competencies: [...competencies]
        .sort((a: ICompetency, b: ICompetency) => a.competencyName < b.competencyName ? -1 : 1)
    };
  }),
  on(ConfigActions.createCompetency, state => {
    return {...state, isCompetenciesLoading: true};
  }),
  on(ConfigActions.updateCompetency, state => {
    return {...state, isCompetenciesLoading: true};
  }),
  on(ConfigActions.deleteCompetency, state => {
    return {...state, isCompetenciesLoading: true};
  }),
  // ================================================================
  // Levels of Experience
  // ================================================================
  on(ConfigActions.setIsExperienceLevelsLoading, (state, {isExperienceLevelsLoading}) => {
    return {...state, isExperienceLevelsLoading};
  }),
  on(ConfigActions.getExperienceLevels, state => {
    return {...state, isExperienceLevelsLoading: true};
  }),
  on(ConfigActions.getExperienceLevelsComplete, (state, {experienceLevels}) => {
    return {
      ...state,
      isExperienceLevelsLoading: false,
      experienceLevels: [...experienceLevels]
        .sort((a: IExperienceLevel, b: IExperienceLevel) => a.levelName < b.levelName ? -1 : 1)
    };
  }),
  on(ConfigActions.createExperienceLevel, state => {
    return {...state, isExperienceLevelsLoading: true};
  }),
  on(ConfigActions.updateExperienceLevel, state => {
    return {...state, isExperienceLevelsLoading: true};
  }),
  on(ConfigActions.deleteExperienceLevel, state => {
    return {...state, isExperienceLevelsLoading: true};
  }),
  // ================================================================
  // Ratings
  // ================================================================
  on(ConfigActions.setIsRatingsLoading, (state, {isRatingsLoading}) => {
    return {...state, isRatingsLoading};
  }),
  on(ConfigActions.getRatings, state => {
    return {...state, isRatingsLoading: true};
  }),
  on(ConfigActions.getRatingsComplete, (state, {ratings}) => {
    return {
      ...state,
      isRatingsLoading: false,
      ratings: [...ratings]
        .sort((a: IRating, b: IRating) => a.ratingDescription < b.ratingDescription ? -1 : 1)
    };
  }),
  on(ConfigActions.createRating, state => {
    return {...state, isRatingsLoading: true};
  }),
  on(ConfigActions.updateRating, state => {
    return {...state, isRatingsLoading: true};
  }),
  on(ConfigActions.deleteRating, state => {
    return {...state, isRatingsLoading: true};
  }),
  // ================================================================
  // Sections
  // ================================================================
  on(ConfigActions.setIsSectionsLoading, (state, {isSectionsLoading}) => {
    return {...state, isSectionsLoading};
  }),
  on(ConfigActions.getSections, state => {
    return {...state, isSectionsLoading: true};
  }),
  on(ConfigActions.getSectionsComplete, (state, {sections}) => {
    return {
      ...state,
      isSectionsLoading: false,
      sections: [...sections]
        .sort((a: ISection, b: ISection) => a.sectionName < b.sectionName ? -1 : 1)
    };
  }),
  on(ConfigActions.createSection, state => {
    return {...state, isSectionsLoading: true};
  }),
  on(ConfigActions.updateSection, state => {
    return {...state, isSectionsLoading: true};
  }),
  on(ConfigActions.deleteSection, state => {
    return {...state, isSectionsLoading: true};
  }),
  // ================================================================
  // Weightings
  // ================================================================
  on(ConfigActions.setIsWeightingsLoading, (state, {isWeightingsLoading}) => {
    return {...state, isWeightingsLoading};
  }),
  on(ConfigActions.getWeightings, state => {
    return {...state, isWeightingsLoading: true};
  }),
  on(ConfigActions.getWeightingsComplete, (state, {weightings}) => {
    return {
      ...state,
      isWeightingsLoading: false,
      weightings: [...weightings]
        .sort((a: IWeighting, b: IWeighting) => a.weight < b.weight ? -1 : 1)
    };
  }),
  on(ConfigActions.createWeighting, state => {
    return {...state, isWeightingsLoading: true};
  }),
  on(ConfigActions.updateWeighting, state => {
    return {...state, isWeightingsLoading: true};
  }),
  on(ConfigActions.deleteWeighting, state => {
    return {...state, isWeightingsLoading: true};
  }),
);
