<app-review-templates
  [setFilterText]="(filterText$ | ngrxPush)!"
  [competencyListLoading]="(competencyListLoading$ | ngrxPush)!"
  [setCompetencyList]="(competencyList$ | ngrxPush)!"
  [setSelectedCompetency]="(selectedCompetency$ | ngrxPush)!"
  [experienceLevelListLoading]="(experienceLevelListLoading$ | ngrxPush)!"
  [setExperienceLevelList]="(experienceLevelList$ | ngrxPush)!"
  [setSelectedExperienceLevel]="(selectedExperienceLevel$ | ngrxPush)!"
  [reviewTemplateListLoading]="(reviewTemplateListLoading$ | ngrxPush)!"
  [setReviewTemplateList]="(reviewTemplateList$ | ngrxPush)!">
</app-review-templates>
