import { Component, OnInit } from '@angular/core';
import { IWeighting } from '../../../models/interfaces/weightings';
import { IRating } from '../../../models/interfaces/ratings';
import { select, Store } from '@ngrx/store';
import { ConfigState } from '../../../store/reducers/config.reducer';
import * as ConfigActions from '../../../store/actions/config.actions';
import * as ConfigSelectors from '../../../store/selectors/config.selectors';
import { ICompetency } from '../../../models/interfaces/competency';
import { IExperienceLevel } from '../../../models/interfaces/experience-level';
import { ISection } from '../../../models/interfaces/review-template';

@Component({
  selector: 'app-system-configuration',
  templateUrl: './system-configuration.component.html',
  styleUrls: ['./system-configuration.component.scss']
})
export class SystemConfigurationComponent implements OnInit {

  weightingsList: IWeighting[] = [];
  ratingsList: IRating[] = [];

  weightingsListLoading = true;
  ratingsListLoading = true;
  canDeleteExcludeRatings = false;

  showNewWeighting = false;
  showNewRating = false;
  newWeighting: IWeighting;
  newRating: IRating;

  showNewCompetency = false;
  newCompetency: ICompetency = {} as ICompetency;

  showNewExperienceLevel = false;
  newExperienceLevel: IExperienceLevel = {} as IExperienceLevel;

  showNewSection = false;
  newSection: ISection = {} as ISection;

  competencyListLoading = true;
  experienceLevelListLoading = true;
  sectionListLoading = true;

  competencyList: ICompetency[];
  experienceLevelList: IExperienceLevel[];
  sectionList: ISection[];

  constructor(private configStore: Store<ConfigState>) { }

  ngOnInit(): void {
    this.configStore.pipe(select(ConfigSelectors.selectIsWeightingsLoading))
      .subscribe(isWLoading => this.weightingsListLoading = isWLoading);

    this.configStore.pipe(select(ConfigSelectors.selectWeightings))
      .subscribe(weightings => {
      this.weightingsList = [];
      weightings.forEach(w => this.weightingsList.push({...w}));
      this.resetWeighting();
    });

    this.configStore.pipe(select(ConfigSelectors.selectIsRatingsLoading))
      .subscribe(isRLoading => this.ratingsListLoading = isRLoading);

    this.configStore.pipe(select(ConfigSelectors.selectRatings))
      .subscribe(ratings => {
        this.ratingsList = [];
        ratings.forEach(r => this.ratingsList.push({...r}));
        this.canDeleteExcludeRatings = this.checkIfMoreThanOneExcludeRating(this.ratingsList);
        this.resetRating();
      });

    // Competencies

    this.configStore.pipe(select(ConfigSelectors.selectIsCompetenciesLoading))
      .subscribe(isCLoading => this.competencyListLoading = isCLoading);

    this.configStore.pipe(select(ConfigSelectors.selectCompetencies))
      .subscribe(competencies => {
        this.competencyList = [];
        competencies.forEach(c => this.competencyList.push({...c}));
        this.resetCompetency();
      });

    // Levels Of Experience

    this.configStore.pipe(select(ConfigSelectors.selectIsExperienceLevelsLoading))
      .subscribe(isLLoading => this.experienceLevelListLoading = isLLoading);

    this.configStore.pipe(select(ConfigSelectors.selectExperienceLevels))
      .subscribe(experienceLevels => {
        this.experienceLevelList = [];
        experienceLevels.forEach(l => this.experienceLevelList.push({...l}));
        this.resetLevelOfExperience();
      });

    // Sections

    this.configStore.pipe(select(ConfigSelectors.selectIsSectionsLoading))
      .subscribe(isSLoading => this.sectionListLoading = isSLoading);

    this.configStore.pipe(select(ConfigSelectors.selectSections))
      .subscribe(sections => {
        this.sectionList = [];
        sections.forEach(l => this.sectionList.push({...l}));
        this.resetSection();
      });

  }

  // Weightings

  resetWeighting(): void {
    this.showNewWeighting = false;
    this.newWeighting = {
      weightingDescription: '',
      weight: 0,
      isActive: true,
      weightingId: 0
    };
  }

  updateWeighting(weighting: IWeighting): void {
    this.configStore.dispatch(ConfigActions.updateWeighting({weighting}));
  }

  saveNewWeighting(): void {
    this.configStore.dispatch(ConfigActions.createWeighting({weighting: this.newWeighting}));
  }

  deleteWeighting(weighting: IWeighting): void {
    this.configStore.dispatch(ConfigActions.deleteWeighting({weighting}));
  }

  // Ratings

  resetRating(): void {
    this.showNewRating = false;
    this.newRating = {
      ratingDescription: '',
      rating1: -1,
      isActive: true,
      ratingId: 0
    };
  }

  updateRating(rating: IRating): void {
    this.configStore.dispatch(ConfigActions.updateRating({rating}));
  }

  saveNewRating(): void {
    this.configStore.dispatch(ConfigActions.createRating({rating: this.newRating}));
  }

  deleteRating(rating: IRating): void {
    this.configStore.dispatch(ConfigActions.deleteRating({rating}));
  }

  checkIfMoreThanOneExcludeRating(ratingsList: IRating[]): boolean {
    return ratingsList.filter(r => r.rating1 === -1).length > 1;
  }

  // Competencies

  resetCompetency(): void {
    this.showNewCompetency = false;
    this.newCompetency = {} as ICompetency;
  }

  updateCompetency(competency: ICompetency): void {
    this.configStore.dispatch(ConfigActions.updateCompetency({competency}));
  }

  saveNewCompetency(): void {
    this.configStore.dispatch(ConfigActions.createCompetency({competency: this.newCompetency}));
  }

  deleteCompetency(competency: ICompetency): void {
    this.configStore.dispatch(ConfigActions.deleteCompetency({competency}));
  }

  // Levels Of Experience

  resetLevelOfExperience(): void {
    this.showNewExperienceLevel = false;
    this.newExperienceLevel = {} as IExperienceLevel;
  }

  updateLevelOfExperience(experienceLevel: IExperienceLevel): void {
    this.configStore.dispatch(ConfigActions.updateExperienceLevel({experienceLevel}));
  }

  saveNewLevelOfExperience(): void {
    this.configStore.dispatch(ConfigActions.createExperienceLevel({experienceLevel: this.newExperienceLevel}));
  }

  deleteLevelOfExperience(experienceLevel: IExperienceLevel): void {
    this.configStore.dispatch(ConfigActions.deleteExperienceLevel({experienceLevel}));
  }

  // Sections

  resetSection(): void {
    this.showNewSection = false;
    this.newSection = {} as ISection;
  }

  updateSection(section: ISection): void {
    this.configStore.dispatch(ConfigActions.updateSection({section}));
  }

  saveNewSection(): void {
    this.configStore.dispatch(ConfigActions.createSection({section: this.newSection}));
  }

  deleteSection(section: ISection): void {
    this.configStore.dispatch(ConfigActions.deleteSection({section}));
  }

}
