<div class="container-fluid pb-4">

  <div row class="mt-4">
    <h2>SURVEY TEMPLATES</h2>
  </div>

  <div class="mt-4" style="box-shadow: 0 0 8px 0 rgba(12, 12, 13, 0.1)">
    <nz-input-group [nzSuffix]="suffixIconSearch" nzSize="large">
      <input type="text" nz-input placeholder="Filter Surveys" [(ngModel)]="surveyFilterText" (change)="changeSearchText()"/>
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div>

  <h4 class="d-flex mt-4" style="align-items: center;">
    Competency:
    <div [hidden]="!competencyListLoading" class="spinner-border ml-2" style="width: 1.2rem; height: 1.2rem; border-width: 0.1rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </h4>

  <div class="d-flex mt-2" style="flex-wrap: wrap">
    <div
      class="filter-pill mb-2"
      *ngFor="let competency of competencyList"
      [class.filter-pill-selected]="selectedCompetency === competency"
      (click)="selectedCompetency = competency; changeSelectedCompetency()">
      {{competency.competencyName}}
    </div>
    <div
      class="filter-pill mb-2"
      [class.filter-pill-selected]="!selectedCompetency"
      (click)="selectedCompetency = null; changeSelectedCompetency()">
      All
    </div>
  </div>

  <h4 class="d-flex mt-4" style="align-items: center;">
    Level of Experience:
    <div [hidden]="!experienceLevelListLoading" class="spinner-border ml-2" style="width: 1.2rem; height: 1.2rem; border-width: 0.1rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </h4>

  <div class="d-flex mt-2" style="flex-wrap: wrap">
    <div
      class="filter-pill mb-2"
      [class.filter-pill-selected]="selectedExperienceLevel === experienceLevel"
      (click)="selectedExperienceLevel = experienceLevel; changeSelectedExperienceLevel()"
      *ngFor="let experienceLevel of experienceLevelList">
      {{experienceLevel.levelName}}
    </div>
    <div
      class="filter-pill mb-2"
      [class.filter-pill-selected]="!selectedExperienceLevel"
      (click)="selectedExperienceLevel = null; changeSelectedExperienceLevel()">
      All
    </div>
  </div>

  <h4 class="d-flex mt-4" style="align-items: center;">
    {{(reviewTemplateList | filterReviewTemplates : selectedCompetency : selectedExperienceLevel : surveyFilterText)?.length || 0 + ' '}}
    Result{{(reviewTemplateList | filterReviewTemplates : selectedCompetency : selectedExperienceLevel : surveyFilterText)?.length !== 1 ? 's': ''}}:
    <div [hidden]="!reviewTemplateListLoading" class="spinner-border ml-2" style="width: 1.2rem; height: 1.2rem; border-width: 0.1rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </h4>

  <div class="mt-2">
    <div
      class="selectable mb-2"
      style="width: 100%; cursor: unset;"
      *ngFor="let reviewTemplate of reviewTemplateList | filterReviewTemplates : selectedCompetency : selectedExperienceLevel : surveyFilterText">
      <h5 class="template-name">
        {{reviewTemplate.reviewTemplateDisplayName}}
        <i nz-icon nzType="edit" nzTheme="outline" class="space" nz-tooltip nzTooltipTitle="Edit Survey Template" (click)="editReview(reviewTemplate.reviewTemplateId)"></i>
        <i nz-icon nzType="delete" nzTheme="outline" nz-tooltip nzTooltipTitle="Delete Survey Template" (click)="reviewTemplateToDelete = reviewTemplate; isDeleteModalVisible = true;"></i>
      </h5>
      <p class="date-created" *ngIf="reviewTemplate.createdDate">Date Created: {{reviewTemplate.createdDate}}</p>
      <nz-divider></nz-divider>
      <p class="description">{{reviewTemplate.description}}</p>
      <p class="counts">
        Section{{reviewTemplate.sections?.length !== 1 ? 's' : ''}}: <span>{{reviewTemplate.sections?.length}}</span>
        Question{{reviewTemplate.questionCount !== 1 ? 's' : ''}}: <span>{{reviewTemplate.questionCount}}</span>
      </p>
    </div>
  </div>
</div>

<nz-modal
  [(nzVisible)]="isDeleteModalVisible"
  (nzOnCancel)="isDeleteModalVisible = false"
  [nzFooter]="null"
  [nzTitle]="null"
  [nzWidth]="'550px'"
  [nzBodyStyle]="{ padding: '0', background: 'transparent' }"
  [nzAutofocus]="null"
  [nzContent]="deleteConfirmationModal">
</nz-modal>

<ng-template #deleteConfirmationModal>
  <app-modal-notify-content
    type="warning"
    colorOverrideByType="info"
    title="Delete Survey Template"
    subtitle="You are about to delete the survey template"
    [furtherInfo]="reviewTemplateToDelete?.reviewTemplateDisplayName"
    [createdDate]="reviewTemplateToDelete?.createdDate"
    confirmationMessage="Are you sure you want to continue?"
    cancelText="Cancel"
    okText="Delete"
    (cancel)="isDeleteModalVisible = false"
    (ok)="deleteReview(reviewTemplateToDelete); isDeleteModalVisible = false;"
  ></app-modal-notify-content>
</ng-template>
