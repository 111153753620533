import { Component, Input } from '@angular/core';
import {
  IReviewBreakdown,
  IReviewBreakdownSection,
  IReviewBreakdownSectionQuestion
} from '../../../models/interfaces/review-breakdown';
import { IEmployeeBambooHR } from '../../../models/interfaces/employee-profile';
import Utils from 'src/utils';

@Component({
  selector: 'app-survey-breakdown',
  templateUrl: './survey-breakdown.component.html',
  styleUrls: ['./survey-breakdown.component.scss']
})
export class SurveyBreakdownComponent {

  @Input() set setSelectedEmployeeReviewBreakdown(empReviewBreakdown: IReviewBreakdown) {
    this.selectedEmployeeReviewBreakdown = Utils.determineSpeechBubbles(empReviewBreakdown);
  }
  @Input() empProfile: IEmployeeBambooHR;

  selectedEmployeeReviewBreakdown: IReviewBreakdown = {} as IReviewBreakdown;

  formatPercentSmall = (percent: number) => `${Math.round(percent)}%`;

  getMaxRating(question: IReviewBreakdownSectionQuestion): number {
    let maxRating = 0;
    question.templateAnswers?.forEach(r => maxRating = Math.max(r.rating?.rating1, maxRating));
    return maxRating;
  }
}
