import {AfterContentChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {
  selectAverageUtilisation,
  selectEmployeeProfile,
  selectIsEmployeeProfileFromEmployeeNumberLoading,
  selectIsMyProfileLoading,
  selectIsUtilisationLoading,
  selectUserProfile,
  selectUtilisation
} from 'src/app/store/selectors/employee.selectors';
import {getUserProfile} from 'src/app/store/actions/employee.actions';
import {Utilisation} from 'src/app/models/utilisation/utilisation';
import {IEmployeeBambooHR} from 'src/app/models/interfaces/employee-profile';
import {MsalService} from '@azure/msal-angular';

@Component({
  selector: 'app-employee-utilisation-container',
  templateUrl: './employee-utilisation-container.component.html',
  styleUrls: ['./employee-utilisation-container.component.scss']
})
export class EmployeeUtilisationContainerComponent implements OnInit, AfterContentChecked {

  /** Logged In Employee Information */
  isMyEmployeeProfileLoading$: Observable<boolean>;
  myEmployeeProfile$: Observable<IEmployeeBambooHR>;

  /** Selected Employee Information */
  isSelectedEmployeeProfileLoading$: Observable<boolean>;
  selectedEmployeeProfile$: Observable<IEmployeeBambooHR>;

  /** Selected Employee Utilisation Information */
  isUtilisationLoading$: Observable<boolean>;
  utilisation$: Observable<Utilisation[]>;
  average$: Observable<number>;

  constructor(
    private readonly store: Store,
    private cdr: ChangeDetectorRef,
    private authService: MsalService,
  ) {
  }

  ngOnInit(): void {
    this.isMyEmployeeProfileLoading$ = this.store.select(selectIsMyProfileLoading);
    this.myEmployeeProfile$ = this.store.select(selectUserProfile);
    this.isSelectedEmployeeProfileLoading$ = this.store.select(selectIsEmployeeProfileFromEmployeeNumberLoading);
    this.selectedEmployeeProfile$ = this.store.select(selectEmployeeProfile);
    this.isUtilisationLoading$ = this.store.select(selectIsUtilisationLoading);
    this.utilisation$ = this.store.select(selectUtilisation);
    this.average$ = this.store.select(selectAverageUtilisation);
    const loggedIn = this.authService.instance.getAllAccounts().length > 0;
    if (!loggedIn ) {
      this.store.dispatch(getUserProfile());
    }
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

}
