import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationResult, PopupRequest} from '@azure/msal-browser';
import {takeUntil} from 'rxjs/operators';
import {getUserProfile} from 'src/app/store/actions/employee.actions';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy{
  private readonly destroy$ = new Subject<void>();
  loginDisplay = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    this.loginDisplay = this.authService.instance.getAllAccounts().length === 0;
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  login(): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.store.dispatch(getUserProfile());
            this.loginDisplay = false;
          }
        );
    } else {
      this.authService.loginPopup()
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.loginDisplay = false;
          }
        );
    }
  }
}
