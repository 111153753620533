<div id="loadingIndicatorContainer" class="">

  <div class="hand">

    <div class="finger finger-1">
      <div class="knuckels"></div>
      <span class="stretch"></span>
      <div class="nail"></div>
    </div>

    <div class="finger finger-2">
      <div class="knuckels"></div>
      <span class="stretch"></span>
      <div class="nail"></div>
    </div>

    <div class="finger finger-3">
      <div class="knuckels"></div>
      <span class="stretch"></span>
      <div class="nail"></div>
    </div>

    <div class="finger finger-4">
      <div class="knuckels"></div>
      <span class="stretch"></span>
      <div class="nail"></div>
    </div>

    <div class="finger-thumb finger-5">
    </div>

  </div>

</div>

