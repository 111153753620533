<nz-layout *ngrxLet="userProfile$; let userProfile">

  <nz-sider nzCollapsible nzBreakpoint="lg" nzWidth="264px" nzTheme="light" *ngIf="( isApplicationMenuVisible$ | ngrxPush)" [(nzCollapsed)]="isMenuCollapsed">

    <!-- Application Name -->
    <div
      class="header"
      [routerLink]="['']"
      [class.header-application]="!isMenuCollapsed"
      [class.hide-content]="isMenuCollapsed">
      <img class="dvt-logo" src="./assets/img/dvt-logo-white.png">
      <div>level<span>up</span></div>
    </div>

    <!-- Logged in as -->
    <div
      class="greetings greeting-powered"
      *ngIf="userProfile?.firstName && userProfile?.lastName && !isMenuCollapsed">
      <small>Logged in as:</small>
    </div>

    <div
      class="greetings"
      *ngIf="userProfile?.firstName && userProfile?.lastName"
      [class.greeting]="!isMenuCollapsed"
      [class.greeting-collapsed]="isMenuCollapsed">
      <div>{{ userProfile?.firstName + ' ' + userProfile?.lastName|| 'welcome to LevelUP' }}</div>
    </div>

    <!-- Employee Selected -->
    <div
      class="greetings greeting-powered"
      *ngIf="!isMenuCollapsed">
      <small>Reviewee:</small>
    </div>

    <div
      class="greetings greeting-selected-employee"
      *ngrxLet="selectedEmployeeProfile$; let selectedEmployeeProfile"
      [class.greeting]="!isMenuCollapsed"
      [class.greeting-collapsed]="isMenuCollapsed"
      [class.greeting-selected-employee-selected]="router.url === '/employee/detail'"
      [routerLink]="['/employee/detail']">
      <div *ngIf="selectedEmployeeProfile && !(isSelectedEmployeeProfileLoading$ | ngrxPush)">{{ selectedEmployeeProfile?.firstName + ' ' + selectedEmployeeProfile?.lastName || 'welcome to LevelUP' }}</div>
      <div *ngIf="!selectedEmployeeProfile && !(isSelectedEmployeeProfileLoading$ | ngrxPush)">No Employee Selected</div>
      <div *ngIf="(isSelectedEmployeeProfileLoading$ | ngrxPush)">Selected Employee Loading...</div>
    </div>

    <!-- Employee List -->
    <div
      class="menu-item-simple"
      nz-tooltip
      nzTooltipPlacement="right"
      nzTooltipTitle="Employee List"
      [routerLink]="['/employee/employee-list']"
      [class.menu-item-simple-selected]="router.url === '/employee/employee-list'"
      [class.menu-item-simple-collapsed]="isMenuCollapsed"
      [nzTooltipTrigger]="isMenuCollapsed ? 'hover' : null">
      <i nz-icon nzType="team" nzTheme="outline"></i> <span>Employee List</span>
    </div>

    <!-- Survey Templates -->
    <div
      class="menu-item-simple"
      nz-tooltip
      nzTooltipPlacement="right"
      nzTooltipTitle="Survey Templates"
      [routerLink]="['/review-templates']"
      [class.menu-item-simple-selected]="router.url === '/review-templates'"
      [class.menu-item-simple-collapsed]="isMenuCollapsed"
      [nzTooltipTrigger]="isMenuCollapsed ? 'hover' : null">
      <i nz-icon nzType="container" nzTheme="outline"></i> <span>Survey Templates</span>
    </div>

    <!-- Create a Survey -->
    <div
      class="menu-item-simple"
      nz-tooltip
      nzTooltipPlacement="right"
      nzTooltipTitle="Create Survey"
      [routerLink]="['/create-review']"
      [class.menu-item-simple-selected]="router.url === '/create-review'"
      [class.menu-item-simple-collapsed]="isMenuCollapsed"
      (click)="resetCurrentSurvey()"
      [nzTooltipTrigger]="isMenuCollapsed ? 'hover' : null">
      <i nz-icon nzType="file-add" nzTheme="outline"></i> <span>Create Survey</span>
    </div>

    <!-- Skills matrix -->
    <div
      *ngIf="featureFlagSkillsMatrix"
      class="menu-item-simple"
      nz-tooltip
      nzTooltipPlacement="right"
      nzTooltipTitle="Skills Matrix"
      [routerLink]="['/skills-matrix']"
      [class.menu-item-simple-selected]="router.url === '/skills-matrix'"
      [class.menu-item-simple-collapsed]="isMenuCollapsed"
      (click)="resetCurrentSurvey()"
      [nzTooltipTrigger]="isMenuCollapsed ? 'hover' : null">
      <i nz-icon nzType="apartment" nzTheme="outline"></i> <span>Skills Matrix</span>
    </div>

    <!-- Create Skills matrix -->
    <div
      *ngIf="featureFlagSkillsMatrix"
      class="menu-item-simple"
      nz-tooltip
      nzTooltipPlacement="right"
      nzTooltipTitle="Create Skills Matrix"
      [routerLink]="['/create-skills-matrix']"
      [class.menu-item-simple-selected]="router.url === '/create-skills-matrix'"
      [class.menu-item-simple-collapsed]="isMenuCollapsed"
      (click)="resetCurrentSurvey()"
      [nzTooltipTrigger]="isMenuCollapsed ? 'hover' : null">
      <i nz-icon nzType="appstore-add" nzTheme="outline"></i> <span>Create Skills Matrix</span>
    </div>

    <!-- System Config -->
    <div
      class="menu-item-simple"
      nz-tooltip
      nzTooltipPlacement="right"
      nzTooltipTitle="System Config"
      [routerLink]="['/system']"
      [class.menu-item-simple-selected]="router.url === '/system'"
      [class.menu-item-simple-collapsed]="isMenuCollapsed"
      [nzTooltipTrigger]="isMenuCollapsed ? 'hover' : null">
      <i nz-icon nzType="setting" nzTheme="outline"></i> <span>System Config</span>
    </div>

    <!-- Logging In -->
    <div
      *ngIf="!loginDisplay"
      class="menu-item-simple"
      nz-tooltip
      nzTooltipPlacement="right"
      nzTooltipTitle="Login"
      (click)="loginPopup()"
      [class.menu-item-simple-collapsed]="isMenuCollapsed"
      [nzTooltipTrigger]="isMenuCollapsed ? 'hover' : null">
      <i nz-icon nzType="login" nzTheme="outline"></i> <span>Login</span>
    </div>

    <!-- Logging Out -->
    <div
      *ngIf="loginDisplay"
      class="menu-item-simple"
      nz-tooltip
      nzTooltipPlacement="right"
      nzTooltipTitle="Logout"
      (click)="logout()"
      [class.menu-item-simple-collapsed]="isMenuCollapsed"
      [nzTooltipTrigger]="isMenuCollapsed ? 'hover' : null">
      <i nz-icon nzType="logout" nzTheme="outline"></i> <span>Logout</span>
    </div>

  </nz-sider>

  <!-- Top bar - Logged in as -->
  <nz-layout>
    <nz-content>
      <router-outlet *ngIf="!isIframe"></router-outlet>
      <router-outlet name="print"></router-outlet>
    </nz-content>
  </nz-layout>

</nz-layout>
