import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {IEmployeeBambooHR} from '../../../models/interfaces/employee-profile';
import {Store} from '@ngrx/store';
import {setEmployeeListFilter} from '../../../store/actions/employee.actions';
import {UtilsEmployee} from '../../../utils/utils-employee';

@Component({
  selector: 'app-employee-container-header',
  templateUrl: './employee-container-header.component.html',
  styleUrls: ['./employee-container-header.component.scss']
})
export class EmployeeContainerHeaderComponent implements OnInit {

  @Input() isEmployeeListLoading: boolean = false;

  @Input() set setEmployeeListAndFilter(data: {employees: IEmployeeBambooHR[], filter: string}) {
    const employeeList = UtilsEmployee.filterEmployeeList(data.employees, data.filter);
    this.numberOfEmployees = employeeList?.length;
    this.employeeFilterText = data.filter;
  }

  numberOfEmployees: number = 0;
  employeeFilterText: string = '';

  isIconSearchVisible = true;

  constructor(
    private readonly store: Store,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.cdr.detectChanges();
  }

  onEmployeeFilterChange(filterText: string): void {
    this.isIconSearchVisible = false;
    this.cdr.detectChanges();
    this.dispatchSetEmployeeListFilter(filterText);
  }

  onClearFilter(): void {
    this.isIconSearchVisible = true;
    this.dispatchSetEmployeeListFilter(null);
  }

  private dispatchSetEmployeeListFilter(filterText: string | null): void {
    this.employeeFilterText = filterText;
    this.store.dispatch(setEmployeeListFilter({filterText}));
  }

}
