import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-modal-notify-content',
  templateUrl: './modal-notify-content.component.html',
  styleUrls: ['./modal-notify-content.component.scss']
})
export class ModalNotifyContentComponent {
  @Input() type: 'info' | 'warning' | 'success' | 'error' = 'warning';
  @Input() colorOverrideByType: 'info' | 'warning' | 'success' | 'error' = null;
  @Input() title: string; // Delete Survey Template
  @Input() subtitle: string; // You are about to delete the review template
  @Input() furtherInfo: string; // Review template name
  @Input() createdDate: string = ''; // Review template date created
  @Input() confirmationMessage: string; // Are you sure you want to continue?
  @Input() okText = 'Delete';
  @Input() cancelText = 'Cancel';
  @Output() ok = new EventEmitter();
  @Output() cancel = new EventEmitter();

  onOk(): void {
    this.ok.emit();
  }

  onCancel(): void {
    this.cancel.emit();
  }

}
