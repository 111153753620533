/** Angular */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

/** Plugin */
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzCollapseModule} from 'ng-zorro-antd/collapse';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';

/** Components */
import {EmployeePageComponent} from './employee-page/employee-page.component';
import {EmployeeContainerComponent} from './employee-container/employee-container.component';
import {EmployeeContainerListComponent} from './employee-container-list/employee-container-list.component';
import {EmployeeContainerHeaderComponent} from './employee-container-header/employee-container-header.component';
import {EmployeeListCardComponent} from './employee-list-card/employee-list-card.component';
import {ScheduleReviewComponent} from './schedule-review/schedule-review.component';
import {SurveyBreakdownComponent} from './survey-breakdown/survey-breakdown.component';

/** Pipes */
import {FilterReviewAnswerSectionPipe} from '../../pipes/filter-review-answer-section.pipe';
import {CalculateReviewDurationPipe} from '../../pipes/calculate-review-duration.pipe';
import {FilterReviewBreakdownsPipe} from '../../pipes/filter-review-breakdowns.pipe';
import {IsTemplateActivePipe} from '../../pipes/is-template-active.pipe';

/** Feature Modules */
import {SharedModule} from '../shared/shared.module';
import {FormsModule} from '@angular/forms';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MsalGuard} from '@azure/msal-angular';
import {LetModule, PushModule} from '@ngrx/component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {EmployeeUtilisationContainerComponent} from './employee-utilisation-container/employee-utilisation-container.component';
import {EmployeeUtilisationComponent} from './employee-utilisation/employee-utilisation.component';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {userResolver} from 'src/app/utils/resolvers';

@NgModule({
  declarations: [
    // Components
    EmployeePageComponent,
    SurveyBreakdownComponent,
    ScheduleReviewComponent,
    EmployeeContainerComponent,
    EmployeeContainerListComponent,
    EmployeeContainerHeaderComponent,
    EmployeeListCardComponent,
    // Pipes
    FilterReviewAnswerSectionPipe,
    CalculateReviewDurationPipe,
    FilterReviewBreakdownsPipe,
    IsTemplateActivePipe,
    EmployeeUtilisationContainerComponent,
    EmployeeUtilisationComponent,
  ],
  imports: [
    CommonModule,
    NgApexchartsModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: 'employee/employee-list',
        component: EmployeeContainerComponent,
        canActivate: [MsalGuard]
      },
      {
        path: 'employee/detail',
        component: EmployeePageComponent,
        canActivate: [MsalGuard],
        resolve: {emp: userResolver},
      },
    ]),
    FormsModule,
    AccordionModule,
    ScrollingModule,
    LetModule, PushModule,
    // Plugins
    NzTabsModule,
    NzCollapseModule,
    NzFormModule,
    NzCardModule,
    NzTagModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NgxChartsModule
  ],
})
export class EmployeeModule {
}
