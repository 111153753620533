import { environment } from '../environments/environment';

export const Config = {
  baseUrl: environment.API_BASE_URL,
  API_KEY: '164610325e364bea976e0fa2c3b12897',
  /** Utilisation ================================================================================================ */
  utilisation: {
    /** Get the utilisation data for the user for the last 12 months */
    getUtilisation: environment.API_BASE_URL + `/Utlilisation`,
    getUtilisationByDateRange: environment.API_BASE_URL + `/Utlilisation/get-by-date-range?fromDate={dateFrom}&toDate={dateTo}`,
    getUtilisationByDateRangeAndEmail: environment.API_BASE_URL + `/Utlilisation/get-by-email-and-date-range?emailAddress={email}&fromDate={dateFrom}&toDate={dateTo}`,
    getAverageUtilisationByDateRange: environment.API_BASE_URL + `/Utlilisation/get-average-by-email-and-date-range?fromDate={dateFrom}&toDate={dateTo}`,
  },
  employeeProfiles: {
    getEmployeeList: environment.API_BASE_URL + '/employee-list',
    getEmployeeProfileDetailsFromEmployeeNumber: environment.API_BASE_URL + '/employees/employee-number/{employeeNumber}',
    getEmployeeProfileDetailsFromEmployeeNumberProtected: environment.API_BASE_URL + '/internal-employees/employee-details/employee-number/{employeeNumber}',
    getMyEmployeeProfileDetails: environment.API_BASE_URL + '/employees/profiles/me',
  },
  reviews: {
    scheduledReviews: environment.API_BASE_URL + '/reviews/employees/employee-number/{employeeNumber}',
    getReview: environment.API_BASE_URL + '/reviews/{reviewId}', // gets review instance
    updateReview: environment.API_BASE_URL + '/reviews/{reviewId}', // updates review instance
    deleteReview: environment.API_BASE_URL + '/reviews/revoke/review/{reviewId}',
    scheduleReview: environment.API_BASE_URL + '/reviews/review-templates/{reviewTemplateId}/employees/employee-number/{employeeNumber}',
    updateReviewInstanceExclusion: environment.API_BASE_URL + '/reviews/updates/review/{reviewId}'
  },
  competency: {
    getCompetency: environment.API_BASE_URL + '/competency',
    deleteCompetency: environment.API_BASE_URL + '/competency/{competencyId}',
    postCompetency: environment.API_BASE_URL + '/competency/create',
    updateCompetency: environment.API_BASE_URL + '/competency/update'
  },
  experienceLevel: {
    getExperienceLevel: environment.API_BASE_URL + '/level',
    deleteExperienceLevel: environment.API_BASE_URL + '/level/{levelId}',
    createExperienceLevel: environment.API_BASE_URL + '/level/create',
    updateExperienceLevel: environment.API_BASE_URL + '/level/update'
  },
  sections: {
    getSections: environment.API_BASE_URL + '/section',
    deleteSection: environment.API_BASE_URL + '/section/{sectionId}',
    createSection: environment.API_BASE_URL + '/section/create',
    updateSection: environment.API_BASE_URL + '/section/update'
  },
  reviewAnswers: {
    getReviewAnswers: environment.API_BASE_URL + '/review/section',
  },
  weightings: {
    getWeightings: environment.API_BASE_URL + '/weighting',
    createWeighting: environment.API_BASE_URL + '/weighting/create',
    deleteWeighting: environment.API_BASE_URL + '/weighting/{weightingId}',
    updateWeighting: environment.API_BASE_URL + '/weighting/update',
  },
  ratings: {
    getRatings: environment.API_BASE_URL + '/rating',
    createRating: environment.API_BASE_URL + '/rating/create',
    deleteRating: environment.API_BASE_URL + '/rating/delete',
    updateRating: environment.API_BASE_URL + '/rating/update',
  },
  reviewTemplates: {
    getReviewTemplates: environment.API_BASE_URL + '/review-templates/competencies/{competencyId}/levels/{levelId}',
    getReviewTemplate: environment.API_BASE_URL + '/review-templates/{reviewTemplateId}',
    createReviewTemplate: environment.API_BASE_URL + '/review-templates',
    deleteReviewTemplate: environment.API_BASE_URL + '/review-templates/{reviewTemplateId}',
    updateReviewTemplate: environment.API_BASE_URL + '/review-templates/{reviewTemplateId}',
  }
};
