import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {
  selectCompetencies,
  selectExperienceLevels,
  selectIsCompetenciesLoading,
  selectIsExperienceLevelsLoading,
  selectIsSectionsLoading,
  selectSections
} from '../../../store/selectors/config.selectors';
import {ICompetency} from '../../../models/interfaces/competency';
import {IExperienceLevel} from '../../../models/interfaces/experience-level';
import {ISection} from '../../../models/interfaces/review-template';

@Component({
  selector: 'app-create-review-container',
  templateUrl: './create-review-container.component.html',
  styleUrls: ['./create-review-container.component.scss']
})
export class CreateReviewContainerComponent implements OnInit {

  isCompetencyLoading$!: Observable<boolean>;
  competencies$!: Observable<ICompetency[]>;
  isExperienceLevelsLoading$!: Observable<boolean>;
  experienceLevels$!: Observable<IExperienceLevel[]>;
  isSectionsLoading$!: Observable<boolean>;
  sections$!: Observable<ISection[]>;

  constructor(private readonly store: Store) {
  }

  ngOnInit(): void {
    this.isCompetencyLoading$ = this.store.select(selectIsCompetenciesLoading);
    this.competencies$ = this.store.select(selectCompetencies);
    this.isExperienceLevelsLoading$ = this.store.select(selectIsExperienceLevelsLoading);
    this.experienceLevels$ = this.store.select(selectExperienceLevels);
    this.isSectionsLoading$ = this.store.select(selectIsSectionsLoading);
    this.sections$ = this.store.select(selectSections);
  }

}
