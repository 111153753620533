import {createAction, props} from '@ngrx/store';
import {ICompetency} from 'src/app/models/interfaces/competency';
import {IExperienceLevel} from 'src/app/models/interfaces/experience-level';
import {IRating} from 'src/app/models/interfaces/ratings';
import {IWeighting} from '../../models/interfaces/weightings';
import {ISection} from '../../models/interfaces/review-template';

// ================================================================
// Competencies
// ================================================================

export const setIsCompetenciesLoading = createAction(
  '[Config] Set Is Competencies Loading',
  props<{ isCompetenciesLoading: boolean }>()
);

export const getCompetencies = createAction(
  '[Config] Get Competencies'
);

export const getCompetenciesComplete = createAction(
  '[Config] Get Competencies Complete',
  props<{ competencies: ICompetency[] }>()
);

export const createCompetency = createAction(
  '[Config] Create Competency',
  props<{ competency: ICompetency }>()
);

export const updateCompetency = createAction(
  '[Config] Update Competency',
  props<{ competency: ICompetency }>()
);

export const deleteCompetency = createAction(
  '[Config] Delete Competency',
  props<{ competency: ICompetency }>()
);

// ================================================================
// Levels of Experience
// ================================================================

export const setIsExperienceLevelsLoading = createAction(
  '[Config] Set Is Experience Levels Loading',
  props<{ isExperienceLevelsLoading: boolean }>()
);

export const getExperienceLevels = createAction(
  '[Config] Get Experience Levels'
);

export const getExperienceLevelsComplete = createAction(
  '[Config] Get Experience Levels Complete',
  props<{ experienceLevels: IExperienceLevel[] }>()
);

export const createExperienceLevel = createAction(
  '[Config] Create Experience Level',
  props<{ experienceLevel: IExperienceLevel }>()
);

export const updateExperienceLevel = createAction(
  '[Config] Update Experience Level',
  props<{ experienceLevel: IExperienceLevel }>()
);

export const deleteExperienceLevel = createAction(
  '[Config] Delete Experience Level',
  props<{ experienceLevel: IExperienceLevel }>()
);

// ================================================================
// Ratings
// ================================================================

export const setIsRatingsLoading = createAction(
  '[Config] Set Is Ratings Loading',
  props<{ isRatingsLoading: boolean }>()
);

export const getRatings = createAction(
  '[Config] Get Ratings'
);

export const getRatingsComplete = createAction(
  '[Config] Get Ratings Complete',
  props<{ ratings: IRating[] }>()
);

export const createRating = createAction(
  '[Config] Create Rating',
  props<{ rating: IRating }>()
);

export const updateRating = createAction(
  '[Config] Update Rating',
  props<{ rating: IRating }>()
);

export const deleteRating = createAction(
  '[Config] Delete Rating',
  props<{ rating: IRating }>()
);

// ================================================================
// Sections
// ================================================================

export const setIsSectionsLoading = createAction(
  '[Config] Set Is Sections Loading',
  props<{ isSectionsLoading: boolean }>()
);

export const getSections = createAction(
  '[Config] Get Sections'
);

export const getSectionsComplete = createAction(
  '[Config] Get Sections Complete',
  props<{ sections: ISection[] }>()
);

export const createSection = createAction(
  '[Config] Create Section',
  props<{ section: ISection }>()
);

export const updateSection = createAction(
  '[Config] Update Section',
  props<{ section: ISection }>()
);

export const deleteSection = createAction(
  '[Config] Delete Section',
  props<{ section: ISection }>()
);

// ================================================================
// Weightings
// ================================================================

export const setIsWeightingsLoading = createAction(
  '[Config] Set Is Weightings Loading',
  props<{ isWeightingsLoading: boolean }>()
);

export const getWeightings = createAction(
  '[Config] Get Weightings'
);

export const getWeightingsComplete = createAction(
  '[Config] Get Weightings Complete',
  props<{ weightings: IWeighting[] }>()
);

export const createWeighting = createAction(
  '[Config] Create Weighting',
  props<{ weighting: IWeighting }>()
);

export const updateWeighting = createAction(
  '[Config] Update Weighting',
  props<{ weighting: IWeighting }>()
);

export const deleteWeighting = createAction(
  '[Config] Delete Weighting',
  props<{ weighting: IWeighting }>()
);
