/**
 * This is an enum to display the various loading indicator sizes available
 */
export enum LoadingIndicatorSizes {
  'xLarge',
  'large',
  'normal',
  'small',
  'xSmall'
}
