<div class="container-fluid">
  <div row class="mt-4">
    <h2>SYSTEM CONFIGURATION</h2>
  </div>

  <!--  ================================================================-->
  <!--  Competencies -->
  <!--  ================================================================-->
  <nz-collapse nzBordered="false" [nzExpandIconPosition]="'right'" class="collapse-pane-no-summary">
    <nz-collapse-panel
      [nzHeader]="competencyHeader"
      [nzActive]="false"
    >
      <div style="padding: 1em 2em;">
        <!-- List of Competencies -->
        <div *ngFor="let competency of competencyList" style="margin-bottom: 1em;">
          <nz-input-group nzCompact [nzAddOnAfter]="competencySuffix">
            <input
              type="text"
              nz-input
              [value]="competency.competencyName"
              [(ngModel)]="competency.competencyName"
              (blur)="updateCompetency(competency)"/>
          </nz-input-group>
          <ng-template #competencySuffix>
            <div style="display: flex;">
              <button
                nz-button
                nzSize="small"
                nzType="text"
                class="suffix-btn"
                (click)="deleteCompetency(competency)">
                <i nz-icon [nzType]="'delete'"></i>
              </button>
            </div>
          </ng-template>
        </div>

        <!-- New Competency -->
        <div *ngIf="showNewCompetency">
          <nz-input-group nzCompact [nzAddOnAfter]="newCompetencySuffix" style="margin-bottom: 1em;">
            <input
              type="text"
              nz-input
              [value]="newCompetency.competencyName"
              [(ngModel)]="newCompetency.competencyName"/>
          </nz-input-group>
          <ng-template #newCompetencySuffix>
            <div style="display: flex;">
              <button
                nz-button
                nzSize="small"
                nzType="text"
                class="suffix-btn"
                (click)="saveNewCompetency()">
                <i nz-icon [nzType]="'save'"></i>
              </button>
            </div>
          </ng-template>
        </div>


        <!-- Add New Competency -->
        <!--        <button nz-button nzType="primary" *ngIf="!showNewCompetency" (click)="showNewCompetency = true">+ Add Competency</button>-->
        <!--        <button nz-button *ngIf="showNewCompetency" (click)="showNewCompetency= false" style="display: flex; align-items: center;"><i nz-icon [nzType]="'stop'"></i> Cancel</button>-->
        <div
          class="filter-pill"
          style="width: fit-content;"
          *ngIf="!showNewCompetency"
          (click)="showNewCompetency = true">
          + Add Competency
        </div>
        <div
          class="filter-pill"
          style="width: fit-content;"
          *ngIf="showNewCompetency"
          (click)="showNewCompetency = false">
          <i nz-icon [nzType]="'stop'"></i> Cancel
        </div>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
  <ng-template #competencyHeader>
    <div class="d-flex flex-column">
      <h4 class="d-flex" style="align-items: center;">
        Competency
        <div [hidden]="!competencyListLoading" class="spinner-border ml-2"
             style="width: 1.2rem; height: 1.2rem; border-width: 0.1rem" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </h4>
      <p>Competency is the field of work for the employee</p>
    </div>
  </ng-template>

  <!--  ================================================================-->
  <!--  Levels of Experience -->
  <!--  ================================================================-->
  <nz-collapse nzBordered="false" [nzExpandIconPosition]="'right'" class="collapse-pane-no-summary">
    <nz-collapse-panel
      [nzHeader]="experienceHeader"
      [nzActive]="false"
    >
      <div style="padding: 1em 2em;">
        <!-- List of Experience -->
        <div *ngFor="let experience of experienceLevelList" style="margin-bottom: 1em;">
          <nz-input-group nzCompact [nzAddOnAfter]="experienceSuffix">
            <input
              type="text"
              nz-input
              [value]="experience.levelName"
              [(ngModel)]="experience.levelName"
              (blur)="updateLevelOfExperience(experience)"/>
          </nz-input-group>
          <ng-template #experienceSuffix>
            <div style="display: flex;">
              <button
                nz-button
                nzSize="small"
                nzType="text"
                class="suffix-btn"
                (click)="deleteLevelOfExperience(experience)">
                <i nz-icon [nzType]="'delete'"></i>
              </button>
            </div>
          </ng-template>
        </div>

        <!-- New Level of experience -->
        <div *ngIf="showNewExperienceLevel" style="margin-bottom: 1em;">
          <nz-input-group nzCompact [nzAddOnAfter]="newExperienceSuffix">
            <input
              type="text"
              nz-input
              [value]="newExperienceLevel.levelName"
              [(ngModel)]="newExperienceLevel.levelName"/>
          </nz-input-group>
          <ng-template #newExperienceSuffix>
            <div style="display: flex;">
              <button
                nz-button
                nzSize="small"
                nzType="text"
                class="suffix-btn"
                (click)="saveNewLevelOfExperience()">
                <i nz-icon [nzType]="'save'"></i>
              </button>
            </div>
          </ng-template>
        </div>


        <!-- Add New Experience -->
        <!--        <button nz-button nzType="primary" *ngIf="!showNewExperienceLevel" (click)="showNewExperienceLevel= true">+ Add Experience</button>-->
        <!--        <button nz-button nzType="primary" *ngIf="showNewCompetency" (click)="showNewCompetency= false" style="display: flex; align-items: center;"><i nz-icon [nzType]="'stop'"></i> Cancel</button>-->
        <div
          class="filter-pill"
          style="width: fit-content;"
          *ngIf="!showNewExperienceLevel"
          (click)="showNewExperienceLevel = true">
          + Add Experience
        </div>
        <div
          class="filter-pill"
          style="width: fit-content;"
          *ngIf="showNewExperienceLevel"
          (click)="showNewExperienceLevel = false">
          <i nz-icon [nzType]="'stop'"></i> Cancel
        </div>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
  <ng-template #experienceHeader>
    <div class="d-flex flex-column">
      <h4 class="d-flex" style="align-items: center;">
        Level of Experience
        <div [hidden]="!experienceLevelListLoading" class="spinner-border ml-2"
             style="width: 1.2rem; height: 1.2rem; border-width: 0.1rem" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </h4>
      <p>The grade of the employee</p>
    </div>
  </ng-template>

  <!--  ================================================================-->
  <!--  Ratings -->
  <!--  ================================================================-->
  <nz-collapse nzBordered="false" [nzExpandIconPosition]="'right'" class="collapse-pane-no-summary">
    <nz-collapse-panel
      [nzHeader]="ratingHeader"
      [nzActive]="false"
    >

      <div style="padding: 1em 2em;">
        <!-- List of Ratings -->
        <div *ngFor="let rating of ratingsList" style="margin-bottom: 1em;">
          <nz-input-group nzCompact [nzAddOnAfter]="ratingSuffix">
            <input
              type="text"
              nz-input
              [value]="rating.ratingDescription"
              [(ngModel)]="rating.ratingDescription"
              (blur)="updateRating(rating)"/>
          </nz-input-group>
          <ng-template #ratingSuffix>
            <div style="display: flex;">
              <nz-select
                [(ngModel)]="rating.rating1"
                (ngModelChange)="updateRating(rating)"
                style="min-width: 110px; border-right: 1px solid #d9d9d9; margin: -1px 0">
                <nz-option *ngFor="let _ of [].constructor(ratingsList.length + 1); index as i"
                           [nzLabel]="i === 0 ? 'Excluded' : 'Rating (' + (i - 1) + ')'" [nzValue]="i - 1"></nz-option>
              </nz-select>
              <button
                nz-button
                nzSize="small"
                nzType="text"
                class="suffix-btn"
                (click)="deleteRating(rating)">
                <i nz-icon [nzType]="'delete'"></i>
              </button>
            </div>
          </ng-template>
        </div>

        <!-- New Rating -->
        <div *ngIf="showNewRating" style="margin-bottom: 1em;">
          <nz-input-group nzCompact [nzAddOnAfter]="newRatingSuffix">
            <input
              type="text"
              nz-input
              [value]="newRating.ratingDescription"
              [(ngModel)]="newRating.ratingDescription"/>
          </nz-input-group>
          <ng-template #newRatingSuffix>
            <div style="display: flex;">
              <nz-select
                [(ngModel)]="newRating.rating1"
                style="min-width: 110px; border-right: 1px solid #d9d9d9; margin: -1px 0">
                <nz-option *ngFor="let _ of [].constructor(ratingsList.length + 1); index as i"
                           [nzLabel]="i === 0 ? 'Excluded' : 'Weight (' + i" [nzValue]="i"></nz-option>
              </nz-select>
              <button
                nz-button
                nzSize="small"
                nzType="text"
                class="suffix-btn"
                (click)="saveNewRating()">
                <i nz-icon [nzType]="'save'"></i>
              </button>
            </div>
          </ng-template>
        </div>

        <!-- Add New Rating -->
        <!--        <button nz-button nzType="primary" *ngIf="!showNewRating" (click)="showNewRating = true">+ Add Rating</button>-->
        <!--        <button nz-button nzType="primary" *ngIf="showNewRating" (click)="showNewRating = false" style="display: flex; align-items: center;"><i nz-icon [nzType]="'stop'"></i> Cancel</button>-->
        <div
          class="filter-pill"
          style="width: fit-content;"
          *ngIf="!showNewRating"
          (click)="showNewRating = true">
          + Add Rating
        </div>
        <div
          class="filter-pill"
          style="width: fit-content;"
          *ngIf="showNewRating"
          (click)="showNewRating = false">
          <i nz-icon [nzType]="'stop'"></i> Cancel
        </div>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
  <ng-template #ratingHeader>
    <div class="d-flex flex-column">
      <h4 class="d-flex" style="align-items: center;">
        Ratings
        <div [hidden]="!ratingsListLoading" class="spinner-border ml-2"
             style="width: 1.2rem; height: 1.2rem; border-width: 0.1rem" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </h4>
      <p>Ratings are applied to responses.</p>
    </div>
  </ng-template>

  <!--  ================================================================-->
  <!--  Sections -->
  <!--  ================================================================-->
  <nz-collapse nzBordered="false" [nzExpandIconPosition]="'right'" class="collapse-pane-no-summary">
    <nz-collapse-panel
      [nzHeader]="sectionHeader"
      [nzActive]="false"
    >

      <div style="padding: 1em 2em;">
        <!-- List of Sections -->
        <div *ngFor="let section of sectionList" class="section-container">

          <form nz-form [formGroup]="">
            <nz-form-item>
              <nz-form-label [nzLg]="6" [nzMd]="24" nzRequired [nzFor]="section.sectionId + '_Name'">Section Name
              </nz-form-label>
              <nz-form-control [nzLg]="18" [nzMd]="24">
                <input [id]="section.sectionId + '_Name'"
                       type="text"
                       nz-input
                       [value]="section.sectionName"
                       [(ngModel)]="section.sectionName"
                       [ngModelOptions]="{standalone: true}"
                       (blur)="updateSection(section)"/>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzLg]="6" [nzMd]="24" nzRequired [nzFor]="section.sectionId + '_Description'">Section
                Description
              </nz-form-label>
              <nz-form-control [nzLg]="18" [nzMd]="24">
                <textarea [id]="section.sectionId + '_Description'"
                          type="text"
                          nz-input
                          rows="2"
                          [value]="section.sectionDescription"
                          [(ngModel)]="section.sectionDescription"
                          [ngModelOptions]="{standalone: true}"
                          (blur)="updateSection(section)">
                </textarea>
              </nz-form-control>
            </nz-form-item>
          </form>

          <button
            nz-button
            nzType="text"
            (click)="deleteSection(section)">
            <i nz-icon [nzType]="'delete'"></i>
          </button>
        </div>

        <!-- New Section-->
        <div *ngIf="showNewSection" class="section-container">

          <form nz-form [formGroup]="">
            <nz-form-item>
              <nz-form-label [nzLg]="6" [nzMd]="24" nzRequired [nzFor]="'New_Name'">Section Name</nz-form-label>
              <nz-form-control [nzLg]="18" [nzMd]="24">
                <input [id]="'New_Name'"
                       type="text"
                       nz-input
                       [value]="newSection.sectionName"
                       [(ngModel)]="newSection.sectionName"
                       [ngModelOptions]="{standalone: true}"
                       (keyup.escape)="showNewSection = false;"/>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzLg]="6" [nzMd]="24" nzRequired [nzFor]="'New_Description'">Section Description
              </nz-form-label>
              <nz-form-control [nzLg]="18" [nzMd]="24">
                <textarea [id]="'New_Description'"
                          type="text"
                          nz-input
                          rows="2"
                          [value]="newSection.sectionDescription"
                          [(ngModel)]="newSection.sectionDescription"
                          [ngModelOptions]="{standalone: true}"
                          (keyup.escape)="showNewSection = false;">
                </textarea>
              </nz-form-control>
            </nz-form-item>
          </form>

          <button
            nz-button
            nzType="text"
            (click)="saveNewSection()">
            <i nz-icon [nzType]="'save'"></i>
          </button>
        </div>


        <!-- Add New Section -->
        <!--        <button nz-button nzType="primary" *ngIf="!showNewSection" (click)="showNewSection= true">+ Add Section</button>-->
        <!--        <button nz-button *ngIf="showNewSection" (click)="showNewSection= false" style="display: flex; align-items: center;"><i nz-icon [nzType]="'stop'"></i> Cancel</button>-->
        <div
          class="filter-pill"
          style="width: fit-content;"
          *ngIf="!showNewSection"
          (click)="showNewSection = true">
          + Add Section
        </div>
        <div
          class="filter-pill"
          style="width: fit-content;"
          *ngIf="showNewSection"
          (click)="showNewSection = false">
          <i nz-icon [nzType]="'stop'"></i> Cancel
        </div>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
  <ng-template #sectionHeader>
    <div class="d-flex flex-column">
      <h4 class="d-flex" style="align-items: center;">
        Sections
        <div [hidden]="!sectionListLoading" class="spinner-border ml-2"
             style="width: 1.2rem; height: 1.2rem; border-width: 0.1rem" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </h4>
      <p>Sections will be used as categories in the survey</p>
    </div>
  </ng-template>

  <!--  ================================================================-->
  <!--  Weightings -->
  <!--  ================================================================-->
  <nz-collapse nzBordered="false" [nzExpandIconPosition]="'right'" class="collapse-pane-no-summary">
    <nz-collapse-panel
      [nzHeader]="weightingHeader"
      [nzActive]="false"
    >

      <div style="padding: 1em 2em;">
        <!-- List of Weightings -->
        <div *ngFor="let weighting of weightingsList" style="margin-bottom: 1em;">
          <nz-input-group nzCompact [nzAddOnAfter]="weightingSuffix">
            <input
              type="text"
              nz-input
              [value]="weighting.weightingDescription"
              [(ngModel)]="weighting.weightingDescription"
              (blur)="updateWeighting(weighting)"/>
          </nz-input-group>
          <ng-template #weightingSuffix>
            <div style="display: flex;">
              <nz-select
                [(ngModel)]="weighting.weight"
                (ngModelChange)="updateWeighting(weighting)"
                style="min-width: 110px; border-right: 1px solid #d9d9d9; margin: -1px 0">
                <nz-option *ngFor="let _ of [].constructor(weightingsList.length + 1); index as i"
                           [nzLabel]="i === 0 ? 'Excluded' : 'Weight (' + i + ')'" [nzValue]="i"></nz-option>
              </nz-select>
              <button
                nz-button
                nzSize="small"
                nzType="text"
                class="suffix-btn"
                (click)="deleteWeighting(weighting)">
                <i nz-icon [nzType]="'delete'"></i>
              </button>
            </div>
          </ng-template>
        </div>

        <!-- New Weighting -->
        <div *ngIf="showNewWeighting" style="margin-bottom: 1em;">
          <nz-input-group nzCompact [nzAddOnAfter]="newWeightingSuffix">
            <input
              type="text"
              nz-input
              [value]="newWeighting.weightingDescription"
              [(ngModel)]="newWeighting.weightingDescription"/>
          </nz-input-group>
          <ng-template #newWeightingSuffix>
            <div style="display: flex;">
              <nz-select
                [(ngModel)]="newWeighting.weight"
                style="min-width: 110px; border-right: 1px solid #d9d9d9; margin: -1px 0">
                <nz-option *ngFor="let _ of [].constructor(weightingsList.length + 1); index as i"
                           [nzLabel]="i === 0 ? 'Excluded' : 'Weight (' + i" [nzValue]="i"></nz-option>
              </nz-select>
              <button
                nz-button
                nzSize="small"
                nzType="text"
                class="suffix-btn"
                (click)="saveNewWeighting()">
                <i nz-icon [nzType]="'save'"></i>
              </button>
            </div>
          </ng-template>
        </div>

        <!-- Add New Weighting -->
        <div
          class="filter-pill"
          style="width: fit-content;"
          *ngIf="!showNewWeighting"
          (click)="showNewWeighting = true">
          + Add Weighting
        </div>
        <div
          class="filter-pill"
          style="width: fit-content;"
          *ngIf="showNewWeighting"
          (click)="showNewWeighting = false">
          <i nz-icon [nzType]="'stop'"></i> Cancel
        </div>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
  <ng-template #weightingHeader>
    <div class="d-flex flex-column">
      <h4 class="d-flex" style="align-items: center;">
        Weightings
        <div [hidden]="!weightingsListLoading" class="spinner-border ml-2"
             style="width: 1.2rem; height: 1.2rem; border-width: 0.1rem" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </h4>
      <p>Weightings are applicable to the questions that are created.</p>
    </div>
  </ng-template>

</div>
