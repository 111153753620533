import {Utilisation} from './utilisation';
import {ApexAxisChartSeries} from 'ng-apexcharts';

/**
 * A class for Utilisation Utilities
 */
export class UtilsUtilisation {

  static setApexChartSeries(utilisation: Utilisation[]): ApexAxisChartSeries[] {
    const barChartBillable = {name: 'Billable Hours', type: 'bar',  data: [], group: 'actual', color: '#007fba', };
    const barChartSick = {name: 'SickLeave Hours', type: 'bar',  data: [], group: 'actual', color: '#00aaad'};
    const barChartNonBillable = {name: 'Non-Billable Hours', type: 'bar',  data: [], group: 'actual', color: '#0f4876'};
    const barChartAvailable = {name: 'Available Hours', type: 'bar',  data: [], group: 'available', color: '#6f6f6f'};
    const lineChart = {name: 'Utilisation Percentage', type: 'line', data: [] , group: 'percentage', color: '#ffc72c'};
    utilisation.forEach((util: Utilisation) => {
      barChartBillable.data.push(Math.round(util.billableHours + util.annualLeaveHours));
      barChartAvailable.data.push(Math.round(util.availableHours));
      barChartSick.data.push(Math.round(util.sickLeaveHours));
      barChartNonBillable.data.push(Math.round(util.nonBillableHours - util.sickLeaveHours));
      lineChart.data.push(util.utilisationPercentage );
    });
    return [
      barChartBillable as unknown as ApexAxisChartSeries,
      barChartSick as unknown as ApexAxisChartSeries,
      barChartNonBillable as unknown as ApexAxisChartSeries,
      barChartAvailable as unknown as ApexAxisChartSeries,
      lineChart as unknown as ApexAxisChartSeries,
    ];
  }

  static setApexChartSeriesLabels(utilisation: Utilisation[]): string[] {
    const labels: string[] = [];
    utilisation.forEach((util: Utilisation) => {
      labels.push(util.monthYear);
    });
    return labels;
  }

  static setApexPieChartSeries(utilisation: Utilisation): number[] {
    const billableHours = Math.round(utilisation.billableHours + utilisation.annualLeaveHours);
    const nonbillableHours = Math.round(utilisation.nonBillableHours - utilisation.sickLeaveHours - utilisation.annualLeaveHours);
    const sickLeave = Math.round(utilisation.sickLeaveHours);
    return [billableHours, sickLeave, nonbillableHours];
  }

  static setApexPieChartSeriesLabel(averages: number[]): string[] {

    return [
      `Billable Hours  ${averages[0] ? averages[0] : 0} hours`,
      `Sick Leave  ${averages[1] ? averages[1] : 0} hours`,
      `Non-Billable Hours  ${averages[2] ? averages[2] : 0} hours`,
    ];
  }

  static setGuageLabels(value: number): string {
    return `Average Utilization: ${value}%`;
  }
}
