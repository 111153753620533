import {Component, Input, OnInit} from '@angular/core';
import {IReviewTemplate} from '../../../models/interfaces/review-template';
import {ReviewState} from '../../../store/reducers/review.reducer';
import {select, Store} from '@ngrx/store';
import * as ReviewSelectors from '../../../store/selectors/review.selectors';
import {EmployeeState} from '../../../store/reducers/employee.reducer';
import {scheduleReviewInstance, updateNewestLink} from '../../../store/actions/review.actions';


@Component({
  selector: 'app-schedule-review',
  templateUrl: './schedule-review.component.html',
  styleUrls: ['./schedule-review.component.scss'],
})
export class ScheduleReviewComponent implements OnInit {

  @Input() selectedReviewTemplate: IReviewTemplate = null;
  @Input() showTemplateList = true;

  reviewTemplateListLoading = true;
  reviewTemplateList: IReviewTemplate[] = [];

  reviewerFullName = '';
  reviewerPosition = '';
  reviewerEmail = '';

  schedulingReview = false;

  newestLink: string;

  constructor(
    private employeeStore: Store<EmployeeState>,
    private reviewStore: Store<ReviewState>,
  ) {}

  ngOnInit(): void {

    // Get reviews available for scheduling
    this.reviewStore.pipe(select(ReviewSelectors.selectReviewTemplates))
      .subscribe(reviewTemplates => {
        this.reviewTemplateList = reviewTemplates.sort((a, b) => b.reviewTemplateId - a.reviewTemplateId);
      });

    this.reviewStore.pipe(select(ReviewSelectors.selectIsReviewTemplatesLoading))
      .subscribe(isLoading => this.reviewTemplateListLoading = isLoading);

    this.reviewStore.pipe(select(ReviewSelectors.selectNewestLink))
      .subscribe(link => this.newestLink = link);
  }

  scheduleReview(): void {
    this.schedulingReview = true;

    this.reviewStore.dispatch(scheduleReviewInstance({
      reviewTemplateId: this.selectedReviewTemplate.reviewTemplateId + '',
      reviewerFullName: this.reviewerFullName,
      reviewerPosition: this.reviewerPosition,
      reviewerEmailAddress: this.reviewerEmail
    }));

    // Reset form
    this.reviewerFullName = '';
    this.reviewerPosition = '';
    this.reviewerEmail = '';
    this.schedulingReview = false;
  }

  clearNewestLink(): void {
    this.reviewStore.dispatch(updateNewestLink({link: null}));
  }
}
