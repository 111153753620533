import {Component, Input, OnInit} from '@angular/core';
import {LoadingIndicatorSizes} from '../../../models/general/loading-indicator-sizes';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {

  @Input() size: LoadingIndicatorSizes = LoadingIndicatorSizes.xLarge;

  constructor() { }

  ngOnInit(): void {
    this.addClass('loadingIndicatorContainer');
  }

  private addClass(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      element.classList.add(this.getElementSize());
    } else {
      // Todo: add error logger for missing element
    }
  }

  private getElementSize(): string {
    switch (this.size) {
      case 0:
        return 'c-x-large';
        break;
      case 1:
        return 'c-large';
        break;
      case 2:
        return 'c-normal';
        break;
      case 3:
        return 'c-small';
        break;
      case 4:
        return 'c-x-small';
        break;
    }
  }

}
