<!-- ================================================================================ -->
<!-- Header and Logo -->
<!-- ================================================================================ -->
<div class="complete-survey-header">
  <img src="assets/img/dvt-logo.png">
  <div>level<span>up</span></div>
</div>

<!-- ================================================================================ -->
<!-- Start Page - Displays if the survey has not been started yet -->
<!-- ================================================================================ -->
<div class="container-fluid" *ngIf="showStartPage">
  <div class="employee-intro" *ngrxLet="reviewCandidate$; let reviewCandidate">
    <div class="img-container">
      <img
        class="rounded-circle"
        alt="Profile picture"
        *ngIf="reviewCandidate?.photoUrl"
        [src]="reviewCandidate.photoUrl"
        [style.object-position]="'center'">
    </div>
    <div *ngIf="reviewCandidate" class="employee-profile">{{reviewCandidate?.firstName}} {{reviewCandidate?.lastName}}</div>
    <div *ngIf="reviewInstance" class="review-instance-name">{{reviewInstance?.reviewTemplateDisplayName}}</div>
    <div *ngIf="reviewInstance" class="review-instance-date">Requested Date: {{reviewInstance?.reviewDate | date: 'dd/MM/yyyy'}}</div>
  </div>

  <div class="description-container">
    {{reviewInstance?.description}}
    <div class="custom-button" (click)="startReview()">START</div>
  </div>
</div>

<!-- ================================================================================ -->
<!-- Displays when the survey has been started -->
<!-- ================================================================================ -->
<div class="container-fluid" *ngIf="!showStartPage">

  <!-- Section to display employee as well as the reviewer details -->
  <div class="employee-details">
    <app-user-profile [employeeProfile]="reviewCandidate$ | ngrxPush"></app-user-profile>

    <div class="d-flex" *ngIf="reviewInstance">
      <div class="name-title-group">
        <div class="name-title-reviewer-name">Reviewer name</div>
        <div class="name-title-reviewer-fullname">{{reviewInstance.reviewerFullName}}</div>
        <div class="name-title-reviewer-position">{{reviewInstance.reviewerPosition}}</div>
      </div>
    </div>

  </div>

  <div class="review-name">{{reviewInstance?.reviewTemplateDisplayName}}</div>

  <!-- Survey Progress Section -->
  <div *ngIf="reviewInstance">

    <nz-steps
      nzType="default"
      nzLabelPlacement="vertical"
      nzSize="small"
      [nzCurrent]="sectionIndex"
      (nzIndexChange)="reviewInstanceProgressChange($event)">
      <nz-step
        *ngFor="let step of steps;"
        [nzTitle]="step.sectionName"
        [nzStatus]="step.status"
        [nzDisabled]="step.status === 'wait'"
        [class.selected-step]="sectionIndex === step.index">
      </nz-step>
    </nz-steps>

  </div>

  <!-- Review and Questions Section -->
  <ng-container *ngIf="reviewInstance">
    <div
      class="section-container"
      *ngIf="(reviewInstance?.sections?.length > 0) && (sectionIndex < reviewInstance.sections.length)" >
      <div class="heading">
        <h4>{{reviewInstance.sections[sectionIndex]?.sectionName}}:</h4>
        <div class="info-segment">
          {{reviewInstance.sections[sectionIndex]?.sectionDescription}}
        </div>
      </div>

      <div class="mb-2" *ngFor="let question of reviewInstance.sections[sectionIndex]?.questions; let i = $index">
        <app-complete-review-question
          [disabled]="!!reviewInstance.reviewEndTime && reviewInstance.reviewEndTime !== ''"
          [question]="question"
          [reviewInstance]="reviewInstance"
          (updateAnswer)="updateAnswer($event.questionId, $event.answer)">
        </app-complete-review-question>
      </div>
    </div>

    <div
      class="review-instance-thank-you"
      *ngIf="reviewInstance?.sections?.length > 0 && sectionIndex === reviewInstance.sections.length">
      <h1>THANK YOU!</h1>
      <div class="review-instance-check-circle">
        <i nz-icon nzType="check-circle" nzTheme="fill"></i>
      </div>
      <div class="review-instance-check-circle-message">Thanks for completing the survey</div>
    </div>
  </ng-container>

  <!-- Section for buttons that appear during a survey while it is being completed -->
  <div class="mb-4 d-flex justify-content-end" *ngIf="reviewInstance && (!reviewInstance.reviewEndTime || reviewInstance.reviewEndTime === '')">
    <div
      *ngIf="reviewInstance?.sections?.length > 0 && sectionIndex > 0"
      class="custom-button custom-button-clear mr-2"
      (click)="previousPage()">Back</div>
    <div
      *ngIf="reviewInstance?.sections?.length > 0 && sectionIndex < reviewInstance.sections.length - 1"
      class="custom-button"
      (click)="nextPage()"
      [class.button-disabled]="!canPressNext">Next</div>

    <div
      *ngIf="reviewInstance?.sections?.length > 0 && sectionIndex === reviewInstance?.sections?.length - 1"
      class="custom-button"
      [style.cursor]="!canComplete ? 'not-allowed' : 'pointer'"
      [style.opacity]="!canComplete ? 0.4 : 1"
      (click)="canComplete ? completeSurvey(true): null">
      Complete Survey
      <b class="text-danger" *ngIf="!canComplete">*</b>
    </div>
  </div>

  <div class="mb-4 d-flex justify-content-end" *ngIf="!!reviewInstance?.reviewEndTime && reviewInstance?.reviewEndTime !== ''">
    <div
      *ngIf="reviewInstance?.sections?.length > 0 && sectionIndex > 0"
      class="custom-button custom-button-clear mr-2"
      (click)="previousPage()">Back</div>
    <div
      *ngIf="reviewInstance?.sections?.length > 0 && sectionIndex < reviewInstance.sections.length"
      class="custom-button"
      (click)="nextPage(false)">Next</div>
  </div>

</div>

