import {Component, Input, OnInit} from '@angular/core';
import {ICompetency} from '../../../models/interfaces/competency';
import {IExperienceLevel} from '../../../models/interfaces/experience-level';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {IWeighting} from '../../../models/interfaces/weightings';
import {IRating} from '../../../models/interfaces/ratings';
import {IReviewTemplate, ISection, ISectionQuestion} from '../../../models/interfaces/review-template';
import {DatePipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {selectRatings, selectWeightings} from '../../../store/selectors/config.selectors';
import {ConfigState} from '../../../store/reducers/config.reducer';
import {ReviewState} from '../../../store/reducers/review.reducer';
import {
  cloneReviewTemplate,
  createReviewTemplate,
  getReviewTemplateById,
  setIsReviewTemplateSaving,
  updateReviewTemplate
} from '../../../store/actions/review.actions';
import {selectIsReviewTemplateSaving, selectReviewTemplateToUpdate} from '../../../store/selectors/review.selectors';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-create-review',
  templateUrl: './create-review.component.html',
  styleUrls: ['./create-review.component.scss'],
})
export class CreateReviewComponent implements OnInit {

  @Input() isCompetenciesLoading: boolean;
  @Input() competencyList: ICompetency[];
  @Input() isExperienceLevelLoading: boolean;
  @Input() experienceLevelList: IExperienceLevel[];
  @Input() isSectionListLoading: boolean;
  @Input() sectionList: ISection[];

  // Update Existing
  reviewTemplateId = '';
  mustCreateNew = false;
  reviewTemplateToUpdate: IReviewTemplate = null;

  currentPageIndex = 0;
  descriptionLoading = true;

  saving = false;
  isCompleteReviewDisabled = true;

  selectedCompetency: ICompetency = null;
  selectedExperienceLevel: IExperienceLevel = null;
  description = '';
  selectedSections: ISection[] = [];
  sectionsCollapsed: boolean[] = [];
  selectedSectionsQuestions: ISectionQuestion[] = [];

  sectionsQuestions: {
    [sectionId: number]: ISectionQuestion[]
  } = {};

  // Would be unnecessary with state management
  weightings: IWeighting[] = [];
  ratings: IRating[] = [];

  dateTime = new Date();

  isSaveModalVisible = false;
  isSurveyComplete = false;
  wasSaveSuccessful = true;
  friendlyName =  '';
  isEditingFriendlyName = false;

  constructor(
    private configStore: Store<ConfigState>,
    private reviewStore: Store<ReviewState>,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {

    // Todo: Move to private functions
    try {
      this.reviewTemplateId = this.route.snapshot.paramMap.get('reviewTemplateId');
      if (this.reviewTemplateId) {
        this.currentPageIndex = 1;
        this.reviewStore.dispatch(getReviewTemplateById({reviewTemplateId: this.reviewTemplateId}));
      } else {
        this.currentPageIndex = 0; // Might have come back here from already updating a review
        this.descriptionLoading = false;
      }
    } catch (e) {
      if (environment.production) {
        console.log('No review sessionID found. Continuing to create new');
      }
      this.currentPageIndex = 0; // Might have come back here from already updating a review
      this.descriptionLoading = false;
    }

    // Todo: Move to asynchronous observable streams - Remove memory leaks
    this.configStore.pipe(select(selectWeightings))
      .subscribe(weightings => {
        this.weightings = weightings;
      });

    // Todo: Move to asynchronous observable streams - Remove memory leaks
    this.configStore.pipe(select(selectRatings))
      .subscribe(ratings => {
        this.ratings = ratings;
      });

    // Todo: Move to asynchronous observable streams - Remove memory leaks
    this.reviewStore.pipe(select(selectReviewTemplateToUpdate))
      .subscribe(reviewTemplate => {
        if (reviewTemplate) { // Set everything up here
          this.reviewTemplateToUpdate = JSON.parse(JSON.stringify(reviewTemplate));
          this.friendlyName = this.reviewTemplateToUpdate.friendlyName;
          this.mustCreateNew = reviewTemplate.isLocked;
          this.selectedSections = this.reviewTemplateToUpdate.sections.map(s => ({
            ...s,
            exclude: !!s.exclude ? s.exclude : false
          }));
          this.sectionsCollapsed = new Array(this.selectedSections.length).fill(false);
          this.description = reviewTemplate.description;
          this.descriptionLoading = false;
          this.selectedSectionsQuestions = [];

          reviewTemplate.sections.forEach((s: ISection) => {
            const sQuestions: ISectionQuestion[] = [];
            s.questions.map((q: ISectionQuestion) => {
              sQuestions.push({
                ...q,
                sectionId: s.sectionId,
                reviewAnswers: q.reviewAnswers ? q.reviewAnswers.map(a => ({...a})) : q.reviewAnswers,
                templateAnswers: q.templateAnswers ? q.templateAnswers.map(a => ({...a})) : q.templateAnswers,
              });
            });
            this.sectionsQuestions = {
              ...this.sectionsQuestions,
              [s.sectionId]: sQuestions
            };
            this.orderReviewQuestionsOnIndex(s.sectionId);
          });

          this.updateCanSave();
        } else {
          this.reviewTemplateToUpdate = null;
        }
      });

    // Todo: Move to asynchronous observable streams - Remove memory leaks
    this.reviewStore.pipe(select(selectIsReviewTemplateSaving))
      .subscribe(({saving, showModal, modalType}) => {
        this.saving = saving;
        this.isSaveModalVisible = showModal;
        this.wasSaveSuccessful = modalType !== 'warning' && modalType !== 'error';
      });
  }

  toggleSection(section: ISection): void {
    const matchedIndex = this.selectedSections.findIndex(s => s.sectionId === section.sectionId);
    if (matchedIndex > -1) {
      this.sectionsCollapsed.splice(matchedIndex, 1);
      this.selectedSections.splice(matchedIndex, 1);
    } else {
      this.selectedSections.push({...section, exclude: !!section.exclude ? section.exclude : false});
      this.sectionsCollapsed.push(false);
    }
  }

  // Drag n drop
  dropSection(event: CdkDragDrop<ISection[]>): void {
    moveItemInArray(this.sectionList, event.previousIndex, event.currentIndex);
  }

  dropQuestion(event: CdkDragDrop<ISectionQuestion[]>, section: ISection): void {
    moveItemInArray(this.sectionsQuestions[section.sectionId], event.previousIndex, event.currentIndex);
    this.assignReviewQuestionsIndex(section.sectionId);
  }

  nextPage(): void {
    this.currentPageIndex++;
    // In future this will save the new review template and route to the next screen

    this.sortSelectedSections();
  }

  previousPage(): void {
    this.currentPageIndex--;
    // In future this will save the new review template and route to the previous screen
  }

  sortSelectedSections(): void {
    const sortedSelectedSections: ISection[] = [];
    this.sectionList.forEach((section: ISection) => {
      if (this.selectedSections.findIndex(s => s.sectionId === section.sectionId) > -1) {
        sortedSelectedSections.push(section);
      }
    });
    this.selectedSections = sortedSelectedSections.map(s => ({...s, exclude: !!s.exclude ? s.exclude : false}));
    this.sectionsCollapsed = new Array(this.selectedSections.length).fill(false);
    this.selectedSections.forEach((s: ISection) => {
      this.sectionsQuestions[s.sectionId] = [];
    });
  }

  addQuestion(section: ISection): void {
    if (section.sectionId) {
      this.sectionsQuestions[section.sectionId].push({
        templateAnswers: [],
        answerLength: 200,
        isActive: false,
        reviewTemplateQuestionId: 0,
        isMandatory: true,
        questionTitle: '',
        questionTypeId: 0,
        weighting: null,
        sectionId: section.sectionId,
        position: 0
      });
      this.isCompleteReviewDisabled = true;
      this.updateCanSave();
    }
  }

  deleteQuestion(question: ISectionQuestion): void {
    if (question.sectionId) {
      this.sectionsQuestions[question.sectionId] = [...this.sectionsQuestions[question.sectionId]]
        .filter((q: ISectionQuestion) => q.questionTitle !== question.questionTitle);
    }
  }

  updateCanSave(): void {
    this.isCompleteReviewDisabled = false;
    for (const question of this.selectedSectionsQuestions) {
      if (question.questionTypeId === 2 && !question.weighting) {
        this.isCompleteReviewDisabled = true;
        break;
      }
    }
  }

  save(navigateAfter = true, isSurveyComplete = false): void {
    this.isSurveyComplete = isSurveyComplete;
    // Update
    if (this.reviewTemplateToUpdate && !this.mustCreateNew) {
      const reviewToBeUpdated: IReviewTemplate = {
        ...this.reviewTemplateToUpdate,
        description: this.description,
        sections: this.selectedSections.map(s => (
          {
            ...s,
            questions: this.sectionsQuestions[s.sectionId]
          }
        )),
        friendlyName: this.friendlyName
      };
      this.reviewStore.dispatch(updateReviewTemplate({reviewTemplate: reviewToBeUpdated, navigateAfter}));
    } else if (this.reviewTemplateToUpdate && this.mustCreateNew) {
      // Create new by cloning existing scheduled review
      const reviewToBeUpdated: IReviewTemplate = {
        reviewTemplateId: this.reviewTemplateToUpdate.reviewTemplateId,
        reviewTemplateName: this.reviewTemplateToUpdate.reviewTemplateName,
        competencyId: this.reviewTemplateToUpdate.competencyId,
        levelId: this.reviewTemplateToUpdate.levelId,
        description: this.description,
        sections: this.selectedSections.map((s: ISection) => (
          {
            ...s,
            questions: this.selectedSectionsQuestions.filter(sectionQs => s.sectionId === sectionQs.sectionId)
          }
        )),
        friendlyName: this.friendlyName
      };

      this.reviewStore.dispatch(cloneReviewTemplate({reviewTemplate: reviewToBeUpdated, navigateAfter}));

    } else { // All New Review!
      const reviewToBeSaved: IReviewTemplate = {
        reviewTemplateId: -1,
        reviewTemplateName: this.selectedExperienceLevel?.levelName + ' ' + this.selectedCompetency?.competencyName +
          ' Review - ' + this.datePipe.transform(this.dateTime, 'dd/MM/yyyy'),
        competencyId: this.selectedCompetency?.competencyId,
        levelId: this.selectedExperienceLevel?.levelId,
        description: this.description,
        sections: this.selectedSections.map(s => (
          {
            ...s,
            questions: this.sectionsQuestions[s.sectionId]
          }
        )),
        friendlyName: this.friendlyName
      };
      this.reviewStore.dispatch(createReviewTemplate({reviewTemplate: reviewToBeSaved, navigateAfter}));
    }
  }

  closeModal(): void {
    this.reviewStore.dispatch(setIsReviewTemplateSaving({
      isReviewTemplateSaving: this.saving,
      showModal: false,
      modalType: 'info'
    }));
  }

  navigateToTemplates(): void {
    this.router.navigate(['review-templates']);
  }

  private assignReviewQuestionsIndex(sectionId: number): void {
    const questions: ISectionQuestion[] = [];
    this.sectionsQuestions[sectionId].forEach((q: ISectionQuestion, index: number) => {
      questions.push({
        ...q,
        position: index
      });
    });
    this.sectionsQuestions[sectionId] = questions;
  }

  private orderReviewQuestionsOnIndex(sectionId: number): void {
    this.sectionsQuestions[sectionId].sort((a: ISectionQuestion, b: ISectionQuestion) => a.position > b.position ? 1 : -1);
  }

}
