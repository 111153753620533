import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Config as config} from '../config';
import {Observable} from 'rxjs';
import {IEmployeeReviews, IReviewInstance, IReviewInstanceUpdate} from '../models/interfaces/review-template';

@Injectable({
  providedIn: 'root'
})
export class ReviewInstancesService {

  constructor(private http: HttpClient) {}

  getReviewInstanceListURL = config.reviews.scheduledReviews;
  scheduleNewReviewInstanceURL = config.reviews.scheduleReview;
  getReviewInstanceURL = config.reviews.getReview;
  deleteReviewInstanceURL = config.reviews.deleteReview;
  updateReviewInstanceURL = config.reviews.updateReview;
  updateReviewInstanceExclusionURL = config.reviews.updateReviewInstanceExclusion;

  headers = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Ocp-Apim-Subscription-Key': config.API_KEY,
  });

  getReviewInstanceList(employeeNumber: string): Observable<IEmployeeReviews> {
    return this.http.get<IEmployeeReviews>(this.getReviewInstanceListURL.replace('{employeeNumber}', employeeNumber), {
      headers: this.headers,
    });
  }

  scheduleReviewInstance(reviewTemplateId: string, employeeNumber: string, reviewerFullName: string, reviewerPosition: string, reviewerEmailAddress: string): Observable<IReviewInstance> {
    return this.http.post<IReviewInstance>(
      this.scheduleNewReviewInstanceURL.replace('{reviewTemplateId}', reviewTemplateId).replace('{employeeNumber}', employeeNumber),
      {
        reviewerFullName,
        reviewerPosition,
        reviewerEmailAddress
      },
      {
        headers: this.headers,
      }
    );
  }

  getReviewInstance(reviewId: string): Observable<IReviewInstance> {
    return this.http.get<IReviewInstance>(this.getReviewInstanceURL.replace('{reviewId}', reviewId), {
      headers: this.headers,
    });
  }

  updateReviewInstance(reviewInstance: IReviewInstance): Observable<IReviewInstance> {
    return this.http.put<IReviewInstance>(this.updateReviewInstanceURL.replace('{reviewId}', reviewInstance.reviewId), {...reviewInstance}, {
      headers: this.headers,
    });
  }

  updateReviewInstanceExclusion(reviewInstanceUpdate: IReviewInstanceUpdate, reviewId: string): Observable<IReviewInstance> {
    return this.http.put<IReviewInstance>(this.updateReviewInstanceExclusionURL.replace('{reviewId}', reviewId), {...reviewInstanceUpdate}, {
      headers: this.headers,
    });
  }

  deleteReviewInstance(reviewId: string): Observable<boolean> {
    return this.http.delete<boolean>(this.deleteReviewInstanceURL.replace('{reviewId}', reviewId), {
      headers: this.headers,
    });
  }
}
