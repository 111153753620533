import {IReviewInstance, ISectionQuestion} from './app/models/interfaces/review-template';
import {IReviewBreakdown, IReviewBreakdownSection} from './app/models/interfaces/review-breakdown';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {IRating} from './app/models/interfaces/ratings';

export default class Utils {

  static calculateReviewBreakdowns(reviews: IReviewInstance[], ratings: IRating[] = []): IReviewBreakdown[] {
    const breakdownList: IReviewBreakdown[] = [];

    reviews.forEach(reviewInstance => {

      const reviewCompleted = !!reviewInstance.reviewEndTime && reviewInstance.reviewEndTime !== '0001-01-01T00:00:00' && reviewInstance.reviewEndTime !== '' && !!new Date(reviewInstance.reviewEndTime);
      const reviewStarted = !!reviewInstance.reviewStartTime && reviewInstance.reviewStartTime !== '0001-01-01T00:00:00' && reviewInstance.reviewStartTime !== '' && !!new Date(reviewInstance.reviewStartTime) && !reviewCompleted;

      // Get existing breakdown
      const matchedBreakdown = this.getMatchedBreakDown(breakdownList, reviewInstance, reviewCompleted, reviewStarted);

      // Skip incomplete
      if (!reviewCompleted) {
        return;
      }

      // Populate section question answers
      reviewInstance.sections.forEach(section => {
        // Match section or continue
        const matchedSection = matchedBreakdown.reviewBreakdownSections.find(s => s.sectionId === section.sectionId);
        if (!matchedSection) {
          return;
        }

        // Populate question answers
        section.questions.forEach(question => {
          this.populateQuestionAnswers(matchedSection, question, ratings, reviewInstance);
        });

        // Calculate Section percentage
        this.calculateSectionPercentage(matchedSection);
      });

      // Calculate Breakdown percentage
      // Could have this running throughout the section loop
      this.calculateBreakdownPercentage(matchedBreakdown);
    });

    return breakdownList;
  }

  // https://stackoverflow.com/a/49121680/4331033
  static copyMessage(val: string, notification: NzNotificationService): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    notification.create(
      'info',
      'Copied',
      `Copied ${val} to clipboard.`
    );
  }

  static getLetterHue(c: string): number {
    if (!c || c.length === 0) {
      return 0;
    }
    switch (c.toUpperCase()[0]) {
      case 'A':
      case 'O':
        return 0;
      case 'B':
      case 'P':
        return 20;
      case 'C':
      case 'Q':
        return 78;
      case 'D':
      case 'R':
        return 125;
      case 'E':
      case 'S':
        return 163;
      case 'F':
      case 'T':
        return 190;
      case 'G':
      case 'U':
        return 215;
      case 'H':
      case 'V':
        return 237;
      case 'I':
      case 'W':
        return 260;
      case 'J':
      case 'X':
        return 280;
      case 'K':
      case 'Y':
        return 295;
      case 'L':
      case 'Z':
        return 315;
      case 'M':
        return 325;
      case 'N':
        return 355;
      default:
        return 0;
    }
  }

  static determineSpeechBubbles(empReviewBreakdown: IReviewBreakdown): IReviewBreakdown {
    return {
      ...empReviewBreakdown,
      reviewBreakdownSections: empReviewBreakdown.reviewBreakdownSections.map((section: IReviewBreakdownSection) => {
        return {
          ...section,
          speechBubble: section.questions.reduce(
            (showSpeechBubble: boolean, currentQuestion) => showSpeechBubble && currentQuestion.questionTypeId !== 2, true)
        };
      })
    };
  }

  private static getMatchedBreakDown(breakdownList: IReviewBreakdown[], reviewInstance: IReviewInstance, reviewCompleted: boolean, reviewStarted: boolean): IReviewBreakdown {
    let matchedBreakdown = breakdownList.find(b => b.reviewTemplateId === reviewInstance.reviewTemplateId);

    // If the breakdown for this review template does not exist
    if (!matchedBreakdown) {
      matchedBreakdown = {
        reviewTemplateId: reviewInstance.reviewTemplateId,
        reviewTemplateName: reviewInstance.reviewTemplateDisplayName,
        description: reviewInstance.description,
        createdDate: reviewInstance.createdDate,
        totalReviewers: 1,
        totalStartedReviewers: reviewStarted ? 1 : 0,
        totalCompletedReviewers: reviewCompleted ? 1 : 0,
        reviewBreakdownSections: reviewInstance.sections.map(s => (
          {
            ...s,
            questions: s.questions.map(q => ({...q, questionAnswers: []}))
          } as IReviewBreakdownSection)
        ),
        expanded: false
      };

      // Match "a" date in the title if there is one
      let createdDate = matchedBreakdown.createdDate || '';
      if (!matchedBreakdown.createdDate) {
        const tokens = matchedBreakdown.reviewTemplateName.split(' ');
        const re = /\d{2}[./-]\d{2}[./-]20\d{2}/;
        tokens.forEach(t => {
          if (t.match(re)) {
            createdDate = t;
          }
        });
      }
      const regexDate = / *-? *\d{2}[./-]\d{2}[./-]20\d{2}/;
      matchedBreakdown =  {...matchedBreakdown, createdDate, reviewTemplateName: matchedBreakdown.reviewTemplateName.split(regexDate)[0]};

      breakdownList.push(matchedBreakdown);
    } else {
      matchedBreakdown.totalReviewers++;
      if (reviewStarted) matchedBreakdown.totalStartedReviewers++;
      if (reviewCompleted) matchedBreakdown.totalCompletedReviewers++;
    }

    return matchedBreakdown;
  }

  private static populateQuestionAnswers(matchedSection: IReviewBreakdownSection, question: ISectionQuestion, ratings: IRating[], reviewInstance: IReviewInstance): void {

    const matchedQuestion = matchedSection.questions.find(q => q.reviewTemplateQuestionId === question.reviewTemplateQuestionId);

    let maxRating = 0;
    question.templateAnswers?.forEach(r => maxRating = Math.max(r.rating?.rating1, maxRating));

    // Add Answer to Question
    if (matchedQuestion) {
      // Prep best answer
      let bestAnswer = {...question.reviewAnswers?.[0]};
      if (!bestAnswer.rating && bestAnswer.ratingId && ratings.length > 0) {
        bestAnswer.rating = ratings.find(r => r.ratingId === bestAnswer.ratingId);
      }
      question.reviewAnswers.forEach(qa => {
        const tempQA = {...qa};

        if (!tempQA.rating && tempQA.ratingId && ratings?.length > 0) {
          tempQA.rating = ratings.find(r => r.ratingId === tempQA.ratingId);
        }

        if (tempQA.rating?.rating1 > bestAnswer.rating?.rating1) {
          bestAnswer = tempQA;
        }
      });

      if (reviewInstance.isExcludedFromCalculation) {
        bestAnswer.rating = {...bestAnswer.rating, excludeAnswerFromCalculation: true};
      }

      matchedQuestion.questionAnswers.push({
        ...bestAnswer,
        reviewerFullName: reviewInstance.reviewerFullName,
        reviewerPosition: reviewInstance.reviewerPosition,
        reviewEndTime: reviewInstance.reviewEndTime,
        answerValue: bestAnswer?.answerValue
      });

      // Calculate Question percentage
      if (matchedQuestion.questionTypeId === 2) {
        matchedQuestion.calculatedPercentage = 0; // clear existing calc
        let answersThatCount = 0;
        matchedQuestion.questionAnswers.forEach(answer => {
          if (!isNaN(answer.rating?.rating1) && answer.rating?.rating1 !== -1 && !answer.rating?.excludeAnswerFromCalculation) {
            // Add all the ratings together
            answersThatCount++;
            matchedQuestion.calculatedPercentage += answer.rating.rating1;
          }
        });

        matchedQuestion.calculatedPercentage = maxRating > 0 && answersThatCount > 0 ? 100 * matchedQuestion.calculatedPercentage / (maxRating * answersThatCount) : 0;
      }
    }
  }

  private static calculateSectionPercentage(matchedSection: IReviewBreakdownSection): void {
    matchedSection.calculatedPercentage = 0;
    let weightingSum = 0;
    matchedSection.questions.forEach(q => {
      if (q.questionTypeId === 2 && q.questionAnswers.find(ans => !isNaN(ans.rating?.rating1) && ans.rating?.rating1 !== -1)) {
        weightingSum += q.weighting.weight;
        matchedSection.calculatedPercentage += q.calculatedPercentage * q.weighting.weight;
      }
    });
    matchedSection.calculatedPercentage = weightingSum > 0 ? matchedSection.calculatedPercentage / weightingSum : 0;
  }

  private static calculateBreakdownPercentage(matchedBreakdown: IReviewBreakdown): void {

    matchedBreakdown.calculatedPercentage = 0;
    let includedCount = 0;
    matchedBreakdown.reviewBreakdownSections.forEach(s => {

      let hasRating = false;
      s.questions.forEach(q => {
        hasRating = hasRating || q.questionTypeId === 2 && !!q.questionAnswers.find(ans => ans.rating?.rating1 > -1);
      });

      if (s.exclude !== true && hasRating) {
        matchedBreakdown.calculatedPercentage += s.calculatedPercentage || 0;
        includedCount ++;
      }
    });
    matchedBreakdown.calculatedPercentage = includedCount > 0 ? matchedBreakdown.calculatedPercentage / includedCount : 0;
  }
}
