<div *ngIf="question">
  <i nz-icon nzType="more" nzTheme="outline" class="move-icon"></i>
  <h6 class="mb-3">Question:</h6>

  <nz-input-group nzSize="large" [nzAddOnAfter]="question.questionTypeId === 2 ? weightingAddon : null">
    <input
      type="text"
      nz-input
      placeholder="Enter a question title"
      [value]="question.questionTitle"
      [(ngModel)]="question.questionTitle"/>
  </nz-input-group>
  <ng-template #weightingAddon>
    <nz-select
      [(ngModel)]="question.weighting"
      (ngModelChange)="weightingChanged()"
      nzShowSearch
      [nzPlaceHolder]="!question.weighting ? 'Make a selection' : question.weighting?.weight === 0 ? 'Exclude' : 'Weighting (' + question.weighting?.weight + ')'">
      <nz-option
        *ngFor="let weighting of weightings"
        [nzLabel]="weighting.weightingDescription + ' - ' + (weighting.weight > 0 ? weighting.weight : 'Exclude')"
        [nzValue]="weighting"></nz-option>
    </nz-select>
  </ng-template>

  <h6 class="mb-1 mt-4">Response Type:</h6>

  <!-- Choice, Rating, Text -->

  <nz-radio-group [(ngModel)]="question.questionTypeId" (ngModelChange)="weightingChanged()" nzButtonStyle="solid">
    <label nz-radio-button [nzValue]="1">
      <i nz-icon nzType="check-circle" nzTheme="outline"></i> Choice
    </label>
    <label nz-radio-button [nzValue]="2" (click)="preAddRatingsAsAnswers()">
      <i nz-icon nzType="star" nzTheme="outline"></i> Rating
    </label>
    <label nz-radio-button [nzValue]="3" (click)="checkForAtLeastOneAnswer()">
      <i nz-icon nzType="edit" nzTheme="outline"></i> Text
    </label>
  </nz-radio-group>

  <div *ngIf="question.questionTypeId === 1">
    <h6 class="mb-1 mt-4">Choices:</h6>
    <div *ngFor="let choice of question.templateAnswers" class="d-flex mb-2">

      <nz-input-group nzSize="large" nzSearch [nzAddOnAfter]="choiceAddon">
        <input
          type="text"
          nz-input
          placeholder="Enter a choice"
          [value]="choice.answerValue"
          [(ngModel)]="choice.answerValue"/>
      </nz-input-group>
      <ng-template #choiceAddon>
        <button
          style="display: flex; align-items: center; justify-content: center;"
          nz-button
          nzType="secondary"
          nzSearch
          (click)="deleteAnswer(choice)">
          <i nz-icon nzType="delete"></i>
        </button>
      </ng-template>

    </div>

    <button nz-button nzType="text" style="color: #027fba;" (click)="addAnswer()">+ Add Choice</button>
  </div>

  <div *ngIf="question.questionTypeId === 2">
    <h6 class="mb-1 mt-4">Ratings:</h6>

    <div *ngFor="let answer of question.templateAnswers" class="d-flex mb-2">

      <nz-input-group nzSize="large" [nzAddOnAfter]="ratingAddon">
        <input
          type="text"
          nz-input
          placeholder="Enter a rating title"
          [value]="answer.answerValue"
          [(ngModel)]="answer.answerValue"/>
      </nz-input-group>
      <ng-template #ratingAddon>
        <nz-select
          [(ngModel)]="answer.ratingId"
          nzShowSearch
          style="min-width: 330px; border-right: 1px solid #d9d9d9; height: 38px;"
          [nzPlaceHolder]="question.weighting?.weight === 0 ? 'Exclude' : 'Weighting (' + question.weighting?.weight + ')'">
          <nz-option
            *ngFor="let rating of ratings"
            [nzLabel]="rating.ratingDescription + ' - ' + (rating.rating1 === -1 ? 'Exclude' : rating.rating1)"
            [nzValue]="rating.ratingId"></nz-option>
        </nz-select>
        <button
          style="margin-right: -12px; margin-left: 10px;"
          nz-button
          nzSize="small"
          nzType="text"
          class="suffix-btn"
          (click)="deleteAnswer(answer)">
          <i nz-icon [nzType]="'delete'"></i>
        </button>
      </ng-template>
    </div>

    <button nz-button nzType="text" style="color: #027fba;" (click)="addAnswer()">+ Add Rating</button>

  </div>

  <div *ngIf="question.questionTypeId === 3">
    <h6 class="mb-1 mt-4">Answer Length ({{question.answerLength}} chars):</h6>

    <nz-radio-group [(ngModel)]="question.answerLength" nzButtonStyle="solid">
      <label nz-radio-button [nzValue]="200"><i nz-icon nzType="star" nzTheme="outline"></i> Short answer</label>
      <label nz-radio-button [nzValue]="500"><i nz-icon nzType="edit" nzTheme="outline"></i> Long answer</label>
    </nz-radio-group>
  </div>


  <div class="mt-4 d-flex" style="justify-content: flex-end">
    <nz-switch style="margin: auto 0" [(ngModel)]="question.isMandatory" nzCheckedChildren="Yes"
               nzUnCheckedChildren="No" (click)="$event.stopPropagation()"></nz-switch>
    <span style="font-size: 0.75rem; font-weight: 300; color: #027fba; margin: auto 0.5rem;">Required</span>

    <button nz-button nzType="default" style="margin-left: 0.5rem; display: flex; align-items: center;" (click)="deleteThisQuestion()">
      <i nz-icon nzType="delete"></i>
      Delete Question
    </button>
  </div>
</div>

