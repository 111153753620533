import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SystemConfigurationComponent} from './system-configuration/system-configuration.component';
import { IsDuplicateCompetencyPipe } from '../../pipes/is-duplicate-competency.pipe';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import {A11yModule} from '@angular/cdk/a11y';
import {NzFormModule} from 'ng-zorro-antd/form';
import { MsalGuard } from '@azure/msal-angular';


@NgModule({
  declarations: [
    SystemConfigurationComponent,
    IsDuplicateCompetencyPipe
  ],
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule.forChild([
            {
                path: 'system',
                component: SystemConfigurationComponent,
                canActivate: [MsalGuard]
            }
        ]),
        NzCollapseModule,
        NzInputModule,
        NzSelectModule,
        NzButtonModule,
        NzIconModule,
        A11yModule,
        NzFormModule
    ],
})
export class ConfigurationModule {}
