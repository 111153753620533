<div class="employee-utilisation">

  <div class="eu-container-head">
    <div><h3>EMPLOYEE UTILISATION</h3>
      <h6>
        <span *ngIf="!loadingIndicator.loading">{{ records }} Utilisation Record{{ records === 1 ? '' : 's' }}
          found</span>
        <span *ngIf="loadingIndicator.loading">utilisation records loading...</span>
      </h6></div>
    <div class="search-section">
      <nz-range-picker
        nzMode="month"
        nzSize="large"
        [nzDisabledDate]="disableAdjustmentDates"
        [disabled]="loadingIndicator.loading"
        [(ngModel)]="dateSelected"
        (ngModelChange)="onDatesSelected($event)">
      </nz-range-picker>
      <button
        nz-button nzType="primary"
        (click)="getEmployeeUtilizationBetweenDates()"
        [disabled]="loadingIndicator.loading">Search
      </button>
    </div>
  </div>

  <div
    class="eu-loading"
    *ngIf="loadingIndicator.loading">
    <app-loading-indicator [size]="loadingIndicator.size"></app-loading-indicator>
  </div>

  <div
    class="eu-utilisation-data"
    *ngIf="!loadingIndicator.loading && records">
    <ng-container *ngIf="comboChart.options.series.length > 1">
      <div class="chart-months">
        <apx-chart #line
          id="line-id"
          [series]="comboChart.options.series"
          [chart]="comboChart.options.chart"
          [yaxis]="comboChart.options.yaxis"
          [xaxis]="comboChart.options.xaxis"
          [labels]="comboChart.options.labels"
          [stroke]="comboChart.options.stroke"
          [title]="comboChart.options.title"
          [dataLabels]="comboChart.options.dataLabels"
          [theme]="comboChart.options.theme">
        </apx-chart>
      </div>
    </ng-container>
    <ng-container >
      <div class="chart-year">
        <div>Average Utilization</div>
        <apx-chart #guageChart
          id="guage-id"
          [chart]="guage.options.chart"
          [series]="guage.options.series"
          [plotOptions]="guage.options.plotOptions"
          [stroke]="guage.options.stroke"
          [labels]="guage.options.labels">
        </apx-chart>

<!--        We might need it again -->
<!--        <apx-chart #chart-->
<!--          [series]="pieChart.options.series"-->
<!--          id="pie-chart-id"-->
<!--          [noData]="pieChart.options.nodata"-->
<!--          [chart]="pieChart.options.chart"-->
<!--          [title]="pieChart.options.title"-->
<!--          [labels]="pieChart.options.labels"-->
<!--          [dataLabels]="pieChart.options.dataLabels"-->
<!--          [responsive]="pieChart.options.responsive"-->
<!--          [legend]="pieChart.options.legend">-->
<!--        </apx-chart>-->
      </div>
    </ng-container>
  </div>

</div>
