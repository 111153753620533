import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromReview from '../reducers/review.reducer';
import * as fromConfig from '../reducers/config.reducer';
import {ReviewState} from '../reducers/review.reducer';
import Utils from '../../../utils';
import {selectAllRatings} from './config.selectors';
import {IRating} from '../../models/interfaces/ratings';
import {ConfigState} from '../reducers/config.reducer';
import {IReviewInstance, IReviewTemplate, ISection} from '../../models/interfaces/review-template';
import {ICompetency} from '../../models/interfaces/competency';
import {IExperienceLevel} from '../../models/interfaces/experience-level';

export const selectReviewState = createFeatureSelector<fromReview.ReviewState>(
  fromReview.reviewFeatureKey
);

export const selectConfigState = createFeatureSelector<fromConfig.ConfigState>(
  fromConfig.configFeatureKey
);

// ========================================================================
// Review Templates
// ========================================================================
export const selectIsReviewTemplatesLoading = createSelector(
  selectReviewState,
  (state: ReviewState) => state.isReviewTemplatesLoading
);

export const selectIsReviewTemplateSaving = createSelector(
  selectReviewState,
  (state: ReviewState) => ({
    saving: state.isReviewTemplateSaving,
    showModal: state.showSaveModal,
    modalType: state.saveModalType
  })
);

export const selectIsReviewToUpdateLoading = createSelector(
  selectReviewState,
  (state: ReviewState) => state.isReviewToUpdateLoading
);

export const selectReviewTemplates = createSelector(
  selectReviewState,
  selectConfigState,
  (reviewState: ReviewState, configState: ConfigState) =>
    reviewState.reviewTemplates
      .filter((t: IReviewTemplate) => !!t.reviewTemplateId && t.isActive)
      .map((rt: IReviewTemplate) => ({
        ...mapReviewTemplate(rt, configState)
      }))
);

export const selectReviewTemplateToUpdate = createSelector(
  selectReviewState,
  selectConfigState,
  (reviewState: ReviewState, configState: ConfigState) => {
    return mapReviewTemplate(reviewState.reviewTemplateToUpdate, configState);
  }
);

export const selectReviewTemplateSelectedCompetency = createSelector(
  selectReviewState,
  (state: ReviewState) => state.reviewTemplateSelectedCompetency
);

export const selectReviewTemplateSelectedExperienceLevel = createSelector(
  selectReviewState,
  (state: ReviewState) => state.reviewTemplateSelectedExperienceLevel
);

export const selectReviewTemplateSurveyFilterText = createSelector(
  selectReviewState,
  (state: ReviewState) => state.reviewTemplateSurveyFilterText
);

// ========================================================================
// Review Instances
// ========================================================================

export const selectReviewInstances = createSelector(
  selectReviewState,
  selectConfigState,
  (reviewState: ReviewState, configState: ConfigState) =>
    reviewState.reviewInstances.map((ri: IReviewInstance) => ({
      ...mapReviewInstance(ri, configState)
    }))
);

export const selectIsReviewInstancesLoading = createSelector(
  selectReviewState,
  (state: ReviewState) => state.isReviewInstancesLoading
);

export const selectSelectedReviewInstance = createSelector(
  selectReviewState,
  selectConfigState,
  (reviewState: ReviewState, configState: ConfigState) => {
    return mapReviewInstance(reviewState.selectedReviewInstance, configState);
  }
);

export const selectIsSelectedReviewInstanceLoading = createSelector(
  selectReviewState,
  (state: ReviewState) => state.isSelectedReviewInstanceLoading
);

export const selectReviewBreakdownList = createSelector(
  selectReviewState,
  selectAllRatings,
  selectConfigState,
  (reviewState: ReviewState, ratings: IRating[], configState: ConfigState) => {
    const reviews: IReviewInstance[] = [];
    reviewState.reviewInstances.forEach((ri: IReviewInstance) => {
      reviews.push(mapReviewInstance(ri, configState, reviewState.reviewTemplates));
    });
    return Utils.calculateReviewBreakdowns(reviews, ratings);
  }
);

export const selectSelectedReviewBreakdown = createSelector(
  selectReviewState,
  (state: ReviewState) => state.selectedReviewBreakdown
);

export const selectReviewIdsBeingUpdated = createSelector(
  selectReviewState,
  (state: ReviewState) => state.reviewIdsBeingUpdated
);

export const selectNewestLink = createSelector(
  selectReviewState,
  (state: ReviewState) => state.newestLink
);

// =======================================================================
// Review Management
// =======================================================================
export const selectIsReviewManagementLoading = createSelector(
  selectIsReviewTemplatesLoading,
  selectIsReviewToUpdateLoading,
  selectIsReviewInstancesLoading,
  selectIsSelectedReviewInstanceLoading,
  (reviewTemplates: boolean, reviewToUpdate: boolean, reviewInstances: boolean, selectedReviewInstance: boolean) => {
    return reviewTemplates || reviewToUpdate || reviewInstances || selectedReviewInstance;
  }
);

// =======================================================================
// Functions and Mappers
// =======================================================================

function mapReviewInstance(reviewInstance: IReviewInstance, configState: ConfigState, reviewTemplates?: IReviewTemplate[]): IReviewInstance {
  const matchingTemplate = reviewTemplates?.find(rt => rt.reviewTemplateId === reviewInstance.reviewTemplateId);
  const reviewInstanceWithFriendlyName: IReviewTemplate = {
    ...reviewInstance,
    friendlyName: matchingTemplate?.friendlyName
  };
  return mapReviewTemplate(reviewInstanceWithFriendlyName as IReviewTemplate, configState) as IReviewInstance;
}

function mapReviewTemplate(reviewTemplate: IReviewTemplate, configState: ConfigState): IReviewTemplate {
  if (!reviewTemplate?.reviewTemplateId) {
    return reviewTemplate;
  }
  const competency =  getReviewCompetency(reviewTemplate, configState);
  const experienceLevel =  getReviewExperienceLevel(reviewTemplate, configState);
  const rt: IReviewTemplate = {
    ...reviewTemplate,
    questionCount: getSectionQuestionCount(reviewTemplate),
    competencyName: competency.competencyName,
    competency,
    levelName: experienceLevel.levelName,
    level: experienceLevel,
    createdDate: getCreatedDateFromName(reviewTemplate)
  };
  rt.reviewTemplateDisplayName = `${rt.levelName} ${rt.competencyName} Review ${rt.friendlyName ? '- ' + rt.friendlyName : ''}`;
  return rt;
}

function getReviewCompetency(reviewTemplate: IReviewTemplate, configState: ConfigState): ICompetency {
  const competencies = configState.competencies.length > 0 ? configState.competencies : [];
  const competency = competencies.find((c: ICompetency) => c.competencyId === reviewTemplate.competencyId);
  return competency ? competency :
    {
      competencyId: reviewTemplate.competencyId,
      competencyName: reviewTemplate.competencyName,
    } as unknown as ICompetency;
}

function getReviewExperienceLevel(reviewTemplate: IReviewTemplate, configState: ConfigState): IExperienceLevel {
  const experienceLevels = configState.experienceLevels.length > 0 ? configState.experienceLevels : [];
  const experienceLevel = experienceLevels.find((c: IExperienceLevel) => c.levelId === reviewTemplate.levelId);
  return experienceLevel ? experienceLevel :
    {
      levelId: reviewTemplate.levelId,
      levelName: reviewTemplate.levelName,
    } as unknown as IExperienceLevel;
}

function getSectionQuestionCount(reviewTemplate: IReviewTemplate): number {
  if (!reviewTemplate?.sections && reviewTemplate?.sections?.length === 0) {
    return 0;
  }
  const questionCount = reviewTemplate?.sections?.reduce((count: number, section: ISection) => (count + section.questions?.length), 0);
  return questionCount || 0;
}

function getCreatedDateFromName(reviewTemplate: IReviewTemplate): string {
  const regexDate = /\d{2}[./-]\d{2}[./-]20\d{2}$/;
  const dateMatches = reviewTemplate?.reviewTemplateName?.match(regexDate);
  return dateMatches?.length > 0 ? dateMatches[0] : '';
}
