import {Component, Input} from '@angular/core';
import {ICompetency} from '../../../models/interfaces/competency';
import {IExperienceLevel} from '../../../models/interfaces/experience-level';
import {IReviewTemplate} from '../../../models/interfaces/review-template';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {
  deleteReviewTemplate,
  setReviewTemplateSelectedCompetency,
  setReviewTemplateSelectedExperienceLevel,
  setReviewTemplateSurveyFilterText
} from '../../../store/actions/review.actions';
import {getCompetencies, getExperienceLevels} from '../../../store/actions/config.actions';

@Component({
  selector: 'app-review-templates',
  templateUrl: './review-templates.component.html',
  styleUrls: ['./review-templates.component.scss']
})
export class ReviewTemplatesComponent {

  @Input() set setFilterText(filteredText: string) {
    this.surveyFilterText = filteredText ? filteredText : '';
  }

  @Input() competencyListLoading = true;

  @Input() set setCompetencyList(competencies: ICompetency[]) {
    this.competencyList = [];
    competencies?.length <= 0
      ? this.store.dispatch(getCompetencies())
      : this.setCompetencies(competencies);
  }

  @Input() experienceLevelListLoading = true;

  @Input() set setExperienceLevelList(experienceLevels: IExperienceLevel[]) {
    this.experienceLevelList = [];
    experienceLevels?.length <= 0
      ? this.store.dispatch(getExperienceLevels())
      : this.setExperienceLevels(experienceLevels);
  }

  @Input() reviewTemplateListLoading = true;

  @Input() set setReviewTemplateList(reviewTemplates: IReviewTemplate[]) {
    this.reviewTemplateList = [];
    if (reviewTemplates?.length > 0) {
      this.reviewTemplateList = reviewTemplates.sort((a: IReviewTemplate, b: IReviewTemplate) => b.reviewTemplateId - a.reviewTemplateId);
      this.reviewTemplateList = this.reviewTemplateList.map((rt: IReviewTemplate) => {
        // Match 'a' date in the title if there is one
        let createdDate = rt.createdDate || '';
        if (!rt.createdDate) {
          const tokens = rt.reviewTemplateName.split(' ');
          const re = /\d{2}[./-]\d{2}[./-]20\d{2}/;
          tokens.forEach((t: string) => {
            if (t.match(re)) {
              createdDate = t;
            }
          });
        }
        const regexDate = / *-? *\d{2}[./-]\d{2}[./-]20\d{2}/;
        return {...rt, createdDate, reviewTemplateName: rt.reviewTemplateName.split(regexDate)[0]};
      });
    }
  }

  @Input() set setSelectedCompetency(competency: ICompetency) {
    if (!competency) {
      this.selectedCompetency = null;
    } else {
      this.selectedCompetency = this.competencyList.find((c: ICompetency) => c.competencyId === competency.competencyId);
    }
  }

  @Input() set setSelectedExperienceLevel(experienceLevel: IExperienceLevel) {
    if (!experienceLevel) {
      this.selectedExperienceLevel = null;
    } else {
      this.selectedExperienceLevel = this.experienceLevelList.find((e: IExperienceLevel) => e.levelId === experienceLevel.levelId);
    }
  }

  isDeleteModalVisible = false;
  surveyFilterText = '';
  competencyList: ICompetency[];
  selectedCompetency: ICompetency = null;
  experienceLevelList: IExperienceLevel[];
  selectedExperienceLevel: IExperienceLevel = null;
  reviewTemplateList: IReviewTemplate[] = [];
  reviewTemplateToDelete: IReviewTemplate;

  constructor(
    private readonly store: Store,
    private router: Router,
  ) {
  }

  editReview(reviewTemplateId): void {
    this.router.navigate([`create-review/${reviewTemplateId}`]);
  }

  deleteReview(review): void {
    this.store.dispatch(deleteReviewTemplate({reviewTemplate: review, showResult: true}));
  }

  changeSelectedCompetency(): void {
    this.clearSurveyFilterText();
    this.store.dispatch(setReviewTemplateSelectedCompetency({
      selectedCompetency: this.selectedCompetency ? this.selectedCompetency : null
    }));
  }

  changeSelectedExperienceLevel(): void {
    this.clearSurveyFilterText();
    this.store.dispatch(setReviewTemplateSelectedExperienceLevel({
      selectedExperienceLevel: this.selectedExperienceLevel ? this.selectedExperienceLevel : null
    }));
  }

  changeSearchText(): void {
    this.store.dispatch(setReviewTemplateSurveyFilterText({
      surveyFilterText: this.surveyFilterText
    }));
  }

  private clearSurveyFilterText(): void {
    this.store.dispatch(setReviewTemplateSurveyFilterText({
      surveyFilterText: ''
    }));
  }

  private setCompetencies(competencies: ICompetency[]): void {
    const notRequired = competencies.find((c: ICompetency) => c.competencyName === 'Not Required');
    this.competencyList = [
      ...competencies.filter((c: ICompetency) => c.competencyName !== 'Not Required')
    ];
    if (notRequired) {
      this.competencyList = [...this.competencyList, {...notRequired}];
    }
  }

  private setExperienceLevels(experienceLevels: IExperienceLevel[]): void {
    const notRequired = experienceLevels.find((c: IExperienceLevel) => c.levelName === 'Not Required');
    this.experienceLevelList = [
      ...experienceLevels.filter((c: IExperienceLevel) => c.levelName !== 'Not Required')
    ];
    if (notRequired) {
      this.experienceLevelList = [...this.experienceLevelList, {...notRequired}];
    }
  }

}
