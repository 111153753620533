import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LoadingIndicatorSizes} from 'src/app/models/general/loading-indicator-sizes';
import {Store} from '@ngrx/store';
import {getUtilisation, setUtilisationDates,} from 'src/app/store/actions/employee.actions';
import {Utilisation} from 'src/app/models/utilisation/utilisation';
import {UtilsUtilisation} from 'src/app/models/utilisation/utils-utilisation';
import {LoadingIndicator} from 'src/app/models/general/loading';
import {APEX_CHART_LINE_COLUMN_DEFAULT} from 'src/app/models/apex-charts/apex-chart-line-column-default';
import {ApexChartPie} from 'src/app/models/apex-charts/apex-chart-pie';
import {APEX_CHART_PIE_DEFAULT} from 'src/app/models/apex-charts/apex-chart-pie-default';
import {APEX_CHART_GUAGE_DEFAULT} from 'src/app/models/apex-charts/apex-chart-guage-default';
import {lastDayOfMonth} from 'date-fns';

@Component({
  selector: 'app-employee-utilisation',
  templateUrl: './employee-utilisation.component.html',
  styleUrls: ['./employee-utilisation.component.scss']
})
export class EmployeeUtilisationComponent implements OnInit {

  /**
   * Is selected employee profile still loading
   * @param isLoading This is a flag for showing whether the selected employee profile is still loading or not
   */
  @Input() isEmployeeProfileLoading: boolean;

  /**
   * Is the employee utilisation data still loading
   * @param isLoading This is a flag for showing whether the employee utilisation is still loading or not
   */
  @Input() set setIsEmployeeUtilizationLoading(isLoading: boolean) {
    this.loadingIndicator.loading = isLoading;
  }

  /**
   * Set the employee utilisation to a local variable
   * @param data The employee utilisation data that needs to be set to a local variable
   */
  @Input() set setUtilisation(data: Utilisation[]) {
    this.records = 0;
    if (data?.length > 0) {
      this.setChartData(data);
    }
  }

  @Input() set setAverage(data: number) {
    this.guage.options.chart.id = 'guage-id';
    if (data) {
      this.guage.options.series = [data];
      this.guage.options.labels = [UtilsUtilisation.setGuageLabels(data)];
    }
  }

  loadingIndicator: LoadingIndicator = {
    size: LoadingIndicatorSizes.large,
    loading: false
  };

  comboChart = {
    options: {
      ...APEX_CHART_LINE_COLUMN_DEFAULT.options,
      series: [],
      title: { ...APEX_CHART_LINE_COLUMN_DEFAULT.options.title, text: 'Employee Utilisation' },
      chart: { ...APEX_CHART_LINE_COLUMN_DEFAULT.options.chart,  id: 'combo-chart-id', stacked: true },
      xaxis: {  ...APEX_CHART_LINE_COLUMN_DEFAULT.options.xaxis, title: { text: 'Monthly Utilisation' } },
      dataLabels: { ...APEX_CHART_LINE_COLUMN_DEFAULT.options.dataLabels,
        textAnchor: 'end',
        formatter: (value, { seriesIndex, dataPointIndex, w }) => {
          if (seriesIndex === 4) {
            return `${value} %`;
          } else {
          return value;
          }
        }
      },
      yaxis: [
        { ...APEX_CHART_LINE_COLUMN_DEFAULT.options.yaxis, title: { text: 'Utilisation Hours' } }
      ],
    },
  };

  pieChart: ApexChartPie = {
    options: {
      ...APEX_CHART_PIE_DEFAULT.options,
      title: {...APEX_CHART_PIE_DEFAULT.options.title,  text: 'Employee Utilisation Chart' },
      dataLabels: { ...APEX_CHART_PIE_DEFAULT.options.dataLabels, enabled: true, textAnchor: 'middle' },
      series: []
    }
  };

  guage = {
    options: {
      ...APEX_CHART_GUAGE_DEFAULT.options,
      title: { ...APEX_CHART_GUAGE_DEFAULT.options.title, text: 'Average Utilisation' },
      chart: { ...APEX_CHART_GUAGE_DEFAULT.options.chart,  id: 'guage-id' },
      series: []
    }
  };

  records: number = 0;

  dateSelectedLast: Date = new Date();
  dateSelectedPrevious: Date = new Date();
  dateSelected: Date[] = [this.dateSelectedPrevious, this.dateSelectedLast];

  constructor(
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.setDateBackSixMonths();
    this.store.dispatch(setUtilisationDates({utilisationDates: this.dateSelected}));
  }

  disableAdjustmentDates = (current: Date): boolean => {
    return current > new Date();
  }

  /**
   * The dates selected for the search for the utilisation data for the logged in employee
   * @param result The dates selected on the HTML template
   */
  onDatesSelected(result: Date[]): void {
    this.dateSelected = result;
    this.store.dispatch(setUtilisationDates({utilisationDates: result}));
  }

  /**
   * Get the employee utilisation data for the logged in employee between specific dates
   */
  getEmployeeUtilizationBetweenDates(): void {
    this.store.dispatch(getUtilisation());
  }

  /**
   * Set the selected date on the HTML template to one year back from today
   * @private This method can only be accessed from this component
   */
  private setDateBackOneYear(): void {
    this.dateSelectedPrevious.setMonth(this.dateSelectedPrevious.getMonth() - 1);
    this.dateSelectedPrevious.setFullYear(this.dateSelectedPrevious.getFullYear() - 1);
    this.dateSelected = [this.dateSelectedPrevious, this.dateSelectedLast];
  }

  private setDateBackSixMonths(): void {
    this.dateSelectedPrevious.setMonth(this.dateSelectedPrevious.getMonth() - 6);
    this.dateSelectedPrevious.setDate(1);

    this.dateSelectedLast.setMonth(this.dateSelectedLast.getMonth() - 1);
    this.dateSelectedLast = lastDayOfMonth(this.dateSelectedLast);

    this.dateSelected = [this.dateSelectedPrevious, this.dateSelectedLast];
  }

  /**
   * Set the various charts data for the Employee Utilisation
   * @private This method can only be accessed from this component
   */
  private setChartData(data: Utilisation[]): void {

    this.comboChart.options.series = UtilsUtilisation.setApexChartSeries(data);
    this.comboChart.options.labels = UtilsUtilisation.setApexChartSeriesLabels(data);

    this.comboChart.options.chart.events = {
      dataPointSelection: (event, chartContext, config) => {
        this.populatePie(config, data);
      },
    };

    this.records = data.length;

    // We might need it again
    // this.pieChart.options.chart.id = 'pie-chart-id';
    // this.pieChart.options.series = [] as number[];
    // this.pieChart.options.labels = UtilsUtilisation.setApexPieChartSeriesLabel([]);

  }

  private populatePie(config, data): void {
    const pieChartData = UtilsUtilisation.setApexPieChartSeries(data[config.dataPointIndex]);
    window.ApexCharts.exec('pie-chart-id', 'updateOptions', {
      series: pieChartData,
      title: { text: `Employee Utilisation Chart for ${data[config.dataPointIndex].monthYear}` },
      labels: UtilsUtilisation.setApexPieChartSeriesLabel(pieChartData)
    });
  }
}
