import {Injectable} from '@angular/core';
import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import * as EmployeeActions from '../actions/employee.actions';
import * as ReviewActions from 'src/app/store/actions/review.actions';
import * as ConfigActions from 'src/app/store/actions/config.actions';
import {EmployeeProfileService} from '../../services/employee-profile.service';
import {IEmployeeBambooHR} from '../../models/interfaces/employee-profile';
import {UtilisationService} from '../../services/utilisation.service';
import {Utilisation} from '../../models/utilisation/utilisation';
import {selectEmployeeProfile, selectUtilisationDates} from 'src/app/store/selectors/employee.selectors';
import {Action, Store} from '@ngrx/store';
import {EmployeeState} from 'src/app/store/reducers/employee.reducer';
import {LocalStorageDirectory} from 'src/app/models/enums/local-storage-directory';

@Injectable()
export class EmployeeEffects {

  // =======================================================================
  // Employee List
  // =======================================================================

  // [Employee] Get Employee List
  getEmployeeList$ = createEffect(() => this.actions$.pipe(
    ofType(EmployeeActions.getEmployeeList),
    mergeMap((_) =>
      this.employeeProfileService.getEmployeeList().pipe(
        map((response: IEmployeeBambooHR[]) => {
          return EmployeeActions.getEmployeeListComplete({employeeList: response});
        }),
        catchError((err) => {
          console.error(err);
          return of(EmployeeActions.getEmployeeListComplete({employeeList: []}));
        })
      )
    )
  ));

  // =======================================================================
  // Employee Profile
  // =======================================================================

  // [Employee] Get Employee Profile From Employee Number
  getEmployeeProfileFromEmployeeNumber$ = createEffect(() => this.actions$.pipe(
    ofType(EmployeeActions.getEmployeeProfileWithEmployeeNumber),
    mergeMap(({employeeNumber, isProtected, isReview}) =>
      this.employeeProfileService.getEmployeeWithEmployeeNumber(employeeNumber, isProtected).pipe(
        mergeMap((response: IEmployeeBambooHR) => {
          const actions: Action[] = [
            EmployeeActions.getEmployeeProfileWithEmployeeNumberComplete({employeeProfile: {...response}})
          ];

          if (!isReview) {
            actions.push(EmployeeActions.getUtilisation());
          }
          if (isProtected) {
            actions.push(ReviewActions.getReviewInstances());
          }

          return actions;
        }),
        catchError((err) => {
          console.error(err);
          return of(EmployeeActions.getEmployeeProfileWithEmployeeNumberComplete({employeeProfile: null}));
        })
      )
    )
  ));

  // [Employee] Get My Employee Profile
  getMyEmployeeProfile$ = createEffect(() => this.actions$.pipe(
    ofType(EmployeeActions.getUserProfile),
    mergeMap((_) =>
      this.employeeProfileService.getUserProfile().pipe(
        mergeMap((response: IEmployeeBambooHR) => {
          if (response.isAdmin) {
            return [
              // Employee List
              EmployeeActions.getEmployeeList(),
              EmployeeActions.getUserProfileComplete({userProfile: response}),
              // System Config
              ConfigActions.getCompetencies(),
              ConfigActions.getExperienceLevels(),
              ConfigActions.getWeightings(),
              ConfigActions.getRatings(),
              ConfigActions.getSections(),
              // Review Templates
              ReviewActions.getReviewTemplates({})
            ];
          } else {
            localStorage.setItem(LocalStorageDirectory.employeeNumber, response.employeeNumber);
            return [
              EmployeeActions.getUserProfileComplete({userProfile: response}),
            ];
          }
        }),
        catchError((err) => {
          console.error(err);
          return of(EmployeeActions.getUserProfileComplete({userProfile: null}));
        })
      )
    )
  ));

   // * ===============================================================
   // * Utilisation
   // * ===============================================================

  /** [Utilisation] Get Utilisation For Logged In Employee */
  getUtilisationForLoggedInEmployee$ = createEffect(() => this.actions$.pipe(
    ofType(EmployeeActions.getUtilisationForLoggedInEmployee),
    mergeMap(() =>
      this.utilisationService.getUtilisation().pipe(
        map((utilization: Utilisation[]) => {
          return EmployeeActions.getUtilisationForLoggedInEmployeeComplete({utilization});
        }),
        catchError((err) => {
          console.error(err);
          return of(EmployeeActions.setIsUtilisationLoading({isLoading: false}));
        })
      )
    )
  ));

  /** [Utilisation] Get Utilisation For Logged In Employee by Date Range */
  getUtilisationForLoggedInEmployeeByDateRange$ = createEffect(() => this.actions$.pipe(
    ofType(EmployeeActions.getUtilisationForLoggedInEmployeeByDateRange),
    mergeMap(({datesSelected}) =>
      this.utilisationService.getUtilisationByDateRange(datesSelected).pipe(
        map((utilization: Utilisation[]) => {
          return EmployeeActions.getUtilisationForLoggedInEmployeeComplete({utilization});
        }),
        catchError((err) => {
          console.error(err);
          return of(EmployeeActions.setIsUtilisationLoading({isLoading: false}));
        })
      )
    )
  ));

  /** [Utilisation] Get Utilisation For Logged In Employee by Date Range and Email Address */
  getUtilisationForLoggedInEmployeeByDateRangeAndEmailAddress$ = createEffect(() => this.actions$.pipe(
    ofType(EmployeeActions.getUtilisationForLoggedInEmployeeByDateRangeAndEmailAddress),
    mergeMap(({datesSelected, emailAddress}) =>
      this.utilisationService.getUtilisationByDateRangeAndEmailAddress(datesSelected, emailAddress).pipe(
        map((utilization: Utilisation[]) => {
          return EmployeeActions.getUtilisationForLoggedInEmployeeComplete({utilization});
        }),
        catchError((err) => {
          console.error(err);
          return of(EmployeeActions.setIsUtilisationLoading({isLoading: false}));
        })
      )
    )
  ));

  getUtilisation$ = createEffect(() => this.actions$.pipe(
    ofType(EmployeeActions.getUtilisation),
    concatLatestFrom(() => [
      this.employeeStore.select(selectEmployeeProfile),
      this.employeeStore.select(selectUtilisationDates),
    ]),
    mergeMap(([action, employee, dates]) => {
      // if (employee) {
        let email: string;
        email = employee.workEmail;
        return [
          EmployeeActions.getUtilisationForLoggedInEmployeeByDateRangeAndEmailAddress({
            datesSelected: dates,
            emailAddress: email
          }),
          EmployeeActions.getAverageUtilisationByDateRangeAndEmailAddress({
            datesSelected: dates,
            emailAddress: email
          })
        ];
      // } else {
      //   return [noop()];
      // }
    }),
  ));

  getAverageUtilisationByDateRangeAndEmailAddress$ = createEffect(() => this.actions$.pipe(
    ofType(EmployeeActions.getAverageUtilisationByDateRangeAndEmailAddress),
    mergeMap(({datesSelected, emailAddress}) =>
      this.utilisationService.getAverageUtilisationByDateRangeAndEmailAddress(datesSelected, emailAddress).pipe(
        map((utilization: number) => {
          return EmployeeActions.getAverageUtilisationComplete({utilization});
        }),
        catchError((err) => {
          console.error(err);
          return of(EmployeeActions.setIsUtilisationLoading({isLoading: false}));
        })
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private employeeProfileService: EmployeeProfileService,
    private utilisationService: UtilisationService,
    private employeeStore: Store<EmployeeState>,
  ) {}
}
