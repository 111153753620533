import { Injectable } from '@angular/core';
import { ISection } from '../models/interfaces/review-template';
import { Config as config } from '../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SectionsService {
  constructor(private http: HttpClient) {}

  headers = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Ocp-Apim-Subscription-Key': config.API_KEY,
  });

  getAllSections(): Observable<ISection[]> {
    return this.http.get<ISection[]>(config.sections.getSections, {
      headers: this.headers,
    });
  }

  deleteSection(sectionId: number): Observable<void> {
    return this.http.delete<void>(config.sections.deleteSection.replace('{sectionId}', sectionId.toString()), {
      headers: this.headers,
    });
  }

  createSection(section: ISection): Observable<ISection> {
    return this.http.post<ISection>(config.sections.createSection, section, {
      headers: this.headers
    });
  }

  updateSection(section: ISection): Observable<ISection> {
    return this.http.put<ISection>(config.sections.updateSection, section, {
      headers: this.headers
    });
  }
}
