import {Component, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';
import * as ConfigSelectors from '../../../store/selectors/config.selectors';
import * as ReviewSelectors from '../../../store/selectors/review.selectors';
import {ICompetency} from '../../../models/interfaces/competency';
import {IExperienceLevel} from '../../../models/interfaces/experience-level';
import {IReviewTemplate} from '../../../models/interfaces/review-template';

@Component({
  selector: 'app-review-templates-container',
  templateUrl: './review-templates-container.component.html',
  styleUrls: ['./review-templates-container.component.scss']
})
export class ReviewTemplatesContainerComponent implements OnInit {

  filterText$: Observable<string> = of('');
  competencyListLoading$!: Observable<boolean>;
  competencyList$: Observable<ICompetency[]> = of([]);
  selectedCompetency$: Observable<ICompetency> = of(null);
  experienceLevelListLoading$!: Observable<boolean>;
  experienceLevelList$: Observable<IExperienceLevel[]> = of([]);
  selectedExperienceLevel$: Observable<IExperienceLevel> = of(null);
  reviewTemplateListLoading$!: Observable<boolean>;
  reviewTemplateList$: Observable<IReviewTemplate[]> = of([]);

  constructor(
    private readonly store: Store,
  ) {
  }

  ngOnInit(): void {
    // Filter Text
    this.filterText$ = this.store.select(ReviewSelectors.selectReviewTemplateSurveyFilterText);

    // Competency Levels
    this.competencyListLoading$ = this.store.select(ConfigSelectors.selectIsCompetenciesLoading);
    this.competencyList$ = this.store.select(ConfigSelectors.selectCompetencies);
    this.selectedCompetency$ = this.store.select(ReviewSelectors.selectReviewTemplateSelectedCompetency);

    // Experience Levels
    this.experienceLevelListLoading$ = this.store.select(ConfigSelectors.selectIsExperienceLevelsLoading);
    this.experienceLevelList$ = this.store.select(ConfigSelectors.selectExperienceLevels);
    this.selectedExperienceLevel$ = this.store.select(ReviewSelectors.selectReviewTemplateSelectedExperienceLevel);

    // Review Templates
    this.reviewTemplateListLoading$ = this.store.select(ReviewSelectors.selectIsReviewTemplatesLoading);
    this.reviewTemplateList$ = this.store.select(ReviewSelectors.selectReviewTemplates);
  }

}
