import {createAction, props} from '@ngrx/store';
import {IEmployeeBambooHR} from '../../models/interfaces/employee-profile';
import {Utilisation} from '../../models/utilisation/utilisation';

// =======================================================================
// Employee List
// =======================================================================

export const setEmployeeListFilter = createAction(
  '[Employee] Set Employee List Filter (Search)',
  props<{ filterText: string | null }>()
);

export const getEmployeeList = createAction(
  '[Employee] Get Employee List'
);

export const getEmployeeListComplete = createAction(
  '[Employee] Get Employee List Complete',
  props<{ employeeList: IEmployeeBambooHR[] }>()
);

// =======================================================================
// Employee Profile
// =======================================================================

export const getEmployeeProfileWithEmployeeNumber = createAction(
  '[Employee] Get Employee Profile From Employee Number',
  props<{ employeeNumber: string; isProtected?: boolean; isReview?: boolean }>()
);

export const getEmployeeProfileWithEmployeeNumberComplete = createAction(
  '[Employee] Get Employee Profile From Employee Number Complete',
  props<{ employeeProfile: IEmployeeBambooHR }>()
);

export const getUserProfile = createAction(
  '[Employee] Get User Profile'
);

export const getUserProfileComplete = createAction(
  '[Employee] Get User Profile Complete',
  props<{ userProfile: IEmployeeBambooHR }>()
);

//  * ===============================================================
//  * Utilisation
//  * ===============================================================

export const getUtilisation = createAction(
  '[Utilisation] Get Utilisation'
);

/**
 * This action gets the employee utilisation for the past year for the logged in employee
 */
export const getUtilisationForLoggedInEmployee = createAction(
  '[Utilisation] Get Utilisation For Logged In Employee'
);

/**
 * This action gets the employee utilisation for the date range specified for the logged in employee
 */
export const getUtilisationForLoggedInEmployeeByDateRange = createAction(
  '[Utilisation] Get Utilisation For Logged In Employee by Date Range',
  props<{ datesSelected: Date[] }>()
);

/**
 * This action gets the employee utilisation for the date range specified and the email address for the logged in employee
 */
export const getUtilisationForLoggedInEmployeeByDateRangeAndEmailAddress = createAction(
  '[Utilisation] Get Utilisation For Logged In Employee by Date Range and Email Address',
  props<{ datesSelected: Date[], emailAddress: string }>()
);

/**
 * This action returns the utilisation retrieved for the logged in employee
 */
export const getUtilisationForLoggedInEmployeeComplete = createAction(
  '[Utilisation] Get Employee Utilisation Complete',
  props<{ utilization: Utilisation[] }>()
);

/**
 * This action sets the flag for employee utilisation as loading or not
 */
export const setIsUtilisationLoading = createAction(
  '[Utilisation] Set Is Utilisation Loading',
  props<{ isLoading: boolean }>()
);

export const setUtilisationDates = createAction(
  '[Utilisation] Set Dates',
  props<{ utilisationDates: Date[] }>()
);

export const getAverageUtilisationByDateRangeAndEmailAddress = createAction(
  '[Utilisation] Get Average Utilisation by Date Range and Email Address',
  props<{ datesSelected: Date[], emailAddress?: string }>()
);

export const getAverageUtilisationComplete = createAction(
  '[Utilisation] Get Average Utilisation Complete',
  props<{ utilization: number }>()
);

export const noop = createAction(
  'NOOP Action',
);
