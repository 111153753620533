import { Pipe, PipeTransform } from '@angular/core';
import {IReviewTemplate} from '../models/interfaces/review-template';

@Pipe({
  name: 'isTemplateActive'
})
export class IsTemplateActivePipe implements PipeTransform {

  transform(reviewTemplates: IReviewTemplate[], reviewTemplateId: number): boolean {
    return !!reviewTemplates.find(rt => rt.reviewTemplateId === reviewTemplateId);
  }

}
