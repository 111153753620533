import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectReviewBreakdownList } from '../../../store/selectors/review.selectors';
import { IReviewBreakdown, IReviewBreakdownSectionQuestion } from '../../../models/interfaces/review-breakdown';
import { ActivatedRoute, Router } from '@angular/router';
import { selectEmployeeProfile } from '../../../store/selectors/employee.selectors';
import { IEmployeeBambooHR } from '../../../models/interfaces/employee-profile';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import Utils from 'src/utils';

@Component({
  selector: 'app-print-content',
  templateUrl: './print-content.component.html',
  styleUrls: ['./print-content.component.scss']
})
export class PrintContentComponent implements OnInit, OnDestroy {
  reviewTemplateId: number;
  selectedEmployeeReviewBreakdown: IReviewBreakdown;
  employeeProfile: IEmployeeBambooHR;

  unsubscribe$: Subject<void> = new Subject();

  constructor(private readonly store: Store, private activatedRoute: ActivatedRoute, private router: Router) {
    this.reviewTemplateId = Number(activatedRoute.snapshot.params['reviewTemplateId']);
  }

  ngOnInit(): void {
    window.onbeforeprint = e => {
      e.target.document.title = `DVT Review Results - ${this.selectedEmployeeReviewBreakdown.reviewTemplateName} - ${this.employeeProfile?.firstName} ${this.employeeProfile?.lastName}`;
    };

    window.onafterprint = e => {
      e.target.document.title = 'PerformanceReviewPortal';
    };

    this.store.pipe(select(selectEmployeeProfile), takeUntil(this.unsubscribe$))
      .subscribe(profile => this.employeeProfile = profile);

    if (this.reviewTemplateId) {
      this.store.pipe(select(selectReviewBreakdownList), takeUntil(this.unsubscribe$))
        .subscribe(breakdownList => {
          this.selectedEmployeeReviewBreakdown = Utils.determineSpeechBubbles(breakdownList.find(breakdown => breakdown.reviewTemplateId === this.reviewTemplateId));

          if (this.selectedEmployeeReviewBreakdown) {
            setTimeout(() => {
              window.print();
              this.router.navigate([{ outlets: { print: null }}]);
            });
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  formatPercentSmall = (percent: number) => `${Math.round(percent)}%`;

  getMaxRating(question: IReviewBreakdownSectionQuestion): number {
    let maxRating = 0;
    question.templateAnswers?.forEach(r => maxRating = Math.max(r.rating?.rating1, maxRating));
    return maxRating;
  }

  getStrokeColor(calculatedPercentage: number): string {
    return calculatedPercentage < 50 ? '#66d2fa' : calculatedPercentage < 75 ? '#47c0ec' : '#0d88c7';
  }
}
