import { Injectable } from '@angular/core';
import { ICompetency } from '../models/interfaces/competency';
import { Config as config } from '../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompetencyService {
  constructor(private http: HttpClient) {}

  getCompetencyURL = config.competency.getCompetency;
  deleteCompetencyURL = config.competency.deleteCompetency;
  postCompetencyURL = config.competency.postCompetency;
  updateCompetencyURL = config.competency.updateCompetency;
  competencyCache$: ReplaySubject<Array<ICompetency>>;

  headers = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Ocp-Apim-Subscription-Key': config.API_KEY,
  });

  getAllCompetencies(): Observable<ICompetency[]> {
    return this.http.get<Array<ICompetency>>(this.getCompetencyURL, {
      headers: this.headers,
    });
  }

  deleteCompetency(competencyId: number): Observable<void> {
    return this.http.delete<void>(this.deleteCompetencyURL.replace('{competencyId}', competencyId.toString()), {
      headers: this.headers,
    });
  }

  createCompetency(formData): Observable<ICompetency> {
    return this.http.post<ICompetency>(this.postCompetencyURL, formData, {
      headers: this.headers
    });
  }

  updateCompetency(formData): Observable<ICompetency> {
    return this.http.put<ICompetency>(this.updateCompetencyURL, formData, {
      headers: this.headers
    });
  }


}
