import {Component, Input} from '@angular/core';
import {IEmployeeBambooHR} from 'src/app/models/interfaces/employee-profile';
import {LocalStorageDirectory} from 'src/app/models/enums/local-storage-directory';
import {clearReviewInstances} from 'src/app/store/actions/review.actions';
import {Store} from '@ngrx/store';
import {getEmployeeProfileWithEmployeeNumber, setUtilisationDates} from 'src/app/store/actions/employee.actions';
import {Router} from '@angular/router';

@Component({
  selector: 'app-employee-list-card',
  templateUrl: './employee-list-card.component.html',
  styleUrls: ['./employee-list-card.component.scss']
})
export class EmployeeListCardComponent {

  @Input() set setEmployee(employee: IEmployeeBambooHR) {
    this.employee = employee;
    this.checkIfProfileImageHasLoaded(employee);
  }

  employee: IEmployeeBambooHR;
  employeeProfileImageLoaded: boolean = false;

  constructor(
    private readonly store: Store,
    private router: Router
  ) {
  }

  navigateToEmployeeDetail(): void {
    if (this.employee?.employeeNumber?.length > 0) {
      const today: Date = new Date();
      const previous: Date = new Date();
      previous.setMonth(previous.getMonth() - 6);

      localStorage.setItem(LocalStorageDirectory.employeeNumber, this.employee.employeeNumber);

      this.store.dispatch(clearReviewInstances());
      this.store.dispatch(setUtilisationDates({utilisationDates: [previous, today]}));
      this.router.navigate(['/employee/detail']);
    }
  }

  private checkIfProfileImageHasLoaded(employee: IEmployeeBambooHR): void {
    this.employeeProfileImageLoaded = false;
    if (employee.photoUrl) {
      const image = document.createElement('img');
      image.src = employee.photoUrl;
      image.onload = () => {
        this.employeeProfileImageLoaded = image.complete;
      };
    }
  }

}
