import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Config as config} from '../config';
import {IEmployeeBambooHR} from '../models/interfaces/employee-profile';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EmployeeProfileService {

  constructor(
    private http: HttpClient
  ) {
  }

  getEmployeeListURL = config.employeeProfiles.getEmployeeList;
  getEmployeeFromEmployeeNumberURLProtected = config.employeeProfiles.getEmployeeProfileDetailsFromEmployeeNumberProtected;
  getEmployeeFromEmployeeNumberURL = config.employeeProfiles.getEmployeeProfileDetailsFromEmployeeNumber;
  getMyEmployeeURL = config.employeeProfiles.getMyEmployeeProfileDetails;

  headers = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Ocp-Apim-Subscription-Key': config.API_KEY,
  });

  getEmployeeList(): Observable<IEmployeeBambooHR[]> {
    return this.http.get<IEmployeeBambooHR[]>(
      this.getEmployeeListURL,
      {headers: this.headers})
      .pipe(
        map((response: IEmployeeBambooHR[]) => {
          return response.map((employee: IEmployeeBambooHR) => this.mapEmployeeProfile(employee));
        })
      );
  }

  getUserProfile(): Observable<IEmployeeBambooHR> {
    return this.http.get<IEmployeeBambooHR>(
      this.getMyEmployeeURL,
      {headers: this.headers})
      .pipe(
        map((response: IEmployeeBambooHR) => {
          return this.mapEmployeeProfile(response);
        })
      );
  }

  getEmployeeWithEmployeeNumber(employeeNumber: string, isProtected: boolean = true): Observable<IEmployeeBambooHR> {
    const url = isProtected ? this.getEmployeeFromEmployeeNumberURLProtected : this.getEmployeeFromEmployeeNumberURL;
    return this.http.get<IEmployeeBambooHR>(
      url.replace('{employeeNumber}', employeeNumber),
      {headers: this.headers})
      .pipe(
        map((response: IEmployeeBambooHR) => {
          return this.mapEmployeeProfile(response);
        })
      );
  }

  private mapEmployeeProfile(employeeProfile: IEmployeeBambooHR): IEmployeeBambooHR {
    employeeProfile.employeeOpenReviewCount = employeeProfile.employeeOpenReviewTemplates?.length || 0;
    employeeProfile.jobTitle = employeeProfile.jobTitle || 'No Job Title Assigned';
    employeeProfile.teamName = !employeeProfile.teamName || employeeProfile.teamName === 'Not assigned'
      ? 'Not Assigned'
      : employeeProfile.teamName;
    employeeProfile.isAdmin = !employeeProfile.showUtilisation;
    return employeeProfile;
  }
}
