import {ApexChartGuage} from './apex-chart-guage';

export const APEX_CHART_GUAGE_DEFAULT: ApexChartGuage = {
  options: {
    series: [],
    chart: {
      type: 'radialBar'
    },
    title: {
      text: 'no chart title set'
    },
    labels: [],
    theme: {
      palette: 'palette8'
    },
    legend: {
      position: 'bottom'
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      lineCap: 'butt'
    },
    plotOptions: {
      radialBar: {
        startAngle: -120,
        endAngle: 120,
        track: {
          background: '#6f6f6f',
          startAngle: -120,
          endAngle: 120,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            fontSize: '30px',
            show: true
          }
        }
      }
    }
  }
};
