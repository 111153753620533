import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromEmployee from '../reducers/employee.reducer';
import {EmployeeState} from '../reducers/employee.reducer';
import {IEmployeeBambooHR} from '../../models/interfaces/employee-profile';

export const employeeState = createFeatureSelector<fromEmployee.EmployeeState>(
  fromEmployee.employeeFeatureKey
);

// =======================================================================
// Employee List
// =======================================================================

export const selectEmployeeListFilterText = createSelector(
  employeeState,
  (state: EmployeeState) => state.filterText
);

export const selectIsEmployeeListLoading = createSelector(
  employeeState,
  (state: EmployeeState) => state.isEmployeeListLoading
);

export const selectEmployeeList = createSelector(
  employeeState,
  (state: EmployeeState) => state.employeeList
);

export const selectEmployeeListAndFilter = createSelector(
  selectEmployeeList,
  selectEmployeeListFilterText,
  (employeeList: IEmployeeBambooHR[], filterText: string) => {
    return {
      employees: employeeList,
      filter: filterText
    };
  }
);

// =======================================================================
// Employee Profile
// =======================================================================

export const selectIsEmployeeProfileFromEmployeeNumberLoading = createSelector(
  employeeState,
  (state: EmployeeState) => state.isEmployeeProfileLoading
);

export const selectEmployeeProfile = createSelector(
  employeeState,
  (state: EmployeeState) => {
    return state.userProfile?.isAdmin ? state.employeeProfile : state.userProfile;
  }
);

export const selectReviewProfile = createSelector(
  employeeState,
  (state: EmployeeState) => {
    return state.employeeProfile;
  }
);

export const selectIsMyProfileLoading = createSelector(
  employeeState,
  (state: EmployeeState) => state.isMyProfileLoading
);

export const selectUserProfile = createSelector(
  employeeState,
  (state: EmployeeState) => state.userProfile
);

// =======================================================================
// Employee Management
// =======================================================================
export const selectIsEmployeeManagementLoading = createSelector(
  selectIsEmployeeListLoading,
  selectIsEmployeeProfileFromEmployeeNumberLoading,
  selectIsMyProfileLoading,
  (employeeList: boolean, employeeProfile: boolean, myProfile: boolean) => {
    return employeeList || employeeProfile || myProfile;
  }
);

/** ===============================================================
 * Utilisation
 * ================================================================ */
export const selectIsUtilisationLoading = createSelector(
  employeeState,
  (state: EmployeeState) => {
    return state.isUtilisationLoading;
  }
);

export const selectUtilisation = createSelector(
  employeeState,
  (state: EmployeeState) => {
    return state.utilisation || [];
  }
);

export const selectUtilisationDates = createSelector(
  employeeState,
  (state: EmployeeState) => state.utilisationDates
);

export const selectAverageUtilisation = createSelector(
  employeeState,
  (state: EmployeeState) => state.averageUtilisation
);
