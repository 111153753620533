import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateReviewDuration'
})
export class CalculateReviewDurationPipe implements PipeTransform {

  transform(reviewEndTime: string, reviewStartTime: string): unknown {
    if (!reviewEndTime || !reviewStartTime) {
      return ''
    }
    
    let date1 = new Date(reviewStartTime);
    let date2 = new Date(reviewEndTime);
    let duration = date2.getTime() - date1.getTime();

    let days = Math.floor((duration / (1000 * 60 * 60 * 24)));
    let hours = Math.floor((duration / (1000 * 60 * 60) % 24));
    let minutes = Math.floor((duration / (1000 * 60) % 60));
    let seconds = Math.floor(((duration / 1000) % 60));

    let values = []

    if (days > 0) values.push(days + 'd');
    if (hours > 0) values.push(hours + 'h');
    if (minutes > 0) values.push(minutes + 'm');
    if (seconds > 0) values.push(seconds + 's');

    return values.join(', ');
  }

}
