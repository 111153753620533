<div style="width:300px;">
  <nz-card>
    <div
      class="card-heading"
      [ngClass]="employee.employeeOpenReviewCount > 0 ? 'card-heading-blue' : 'card-heading-white'">
      <span>No. of Active Reviews</span><span>{{employee.employeeOpenReviewCount}}</span>
    </div>
    <div class="card-image">
      <nz-avatar *ngIf="!employeeProfileImageLoaded" [nzSize]="120" nzIcon="user"></nz-avatar>
      <nz-avatar *ngIf="employeeProfileImageLoaded" [nzSize]="120" nzIcon="user" [nzSrc]="employee.photoUrl"></nz-avatar>
    </div>
    <div class="card-details">
      <span>{{employee.firstName + ' ' + employee.lastName}}</span>
      <span [ngClass]="employee.jobTitle === 'No Job Title Assigned' ? 'cd-red' : ''">{{employee.jobTitle}}</span>
      <span [ngClass]="employee.teamName === 'Not Assigned' ? 'cd-red' : ''">{{employee.teamName}}</span>
    </div>
    <div class="card-mail">
      <span><a *ngIf="employee.workEmail" [href]="'mailto:' + employee.workEmail">{{employee.workEmail.toLowerCase()}}</a></span>
    </div>
    <div class="card-action">
      <button *ngIf="employee.employeeNumber" nz-button [nzSize]="'large'" nzType="default" (click)="navigateToEmployeeDetail()">Navigate to Reviews</button>
      <button *ngIf="!employee.employeeNumber" nz-button [nzSize]="'large'" nzType="default">Employee Profile Error</button>
    </div>
  </nz-card>
</div>
