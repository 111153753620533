import { createReducer, on } from '@ngrx/store';
import * as ReviewActions from '../actions/review.actions';
import { IReviewInstance, IReviewTemplate } from '../../models/interfaces/review-template';
import { IReviewBreakdown } from '../../models/interfaces/review-breakdown';
import { ICompetency } from '../../models/interfaces/competency';
import { IExperienceLevel } from '../../models/interfaces/experience-level';

export const reviewFeatureKey = 'review';

export interface ReviewState {
  isReviewTemplatesLoading: boolean;
  isReviewTemplateSaving: boolean;
  showSaveModal: boolean;
  saveModalType: 'info' | 'warning' | 'success' | 'error';
  isReviewToUpdateLoading: boolean;
  reviewTemplates: IReviewTemplate[];
  reviewTemplateToUpdate: IReviewTemplate;
  reviewTemplateSelectedCompetency: ICompetency;
  reviewTemplateSelectedExperienceLevel: IExperienceLevel;
  reviewTemplateSurveyFilterText: string;
  isReviewInstancesLoading: boolean;
  reviewInstances: IReviewInstance[];
  isSelectedReviewInstanceLoading: boolean;
  selectedReviewInstance: IReviewInstance; // Fill in this review instance
  selectedReviewBreakdown: IReviewBreakdown;
  reviewIdsBeingUpdated: Set<string>;
  newestLink: string;
}

export const initialState: ReviewState = {
  isReviewTemplatesLoading: false,
  isReviewTemplateSaving: false,
  showSaveModal: false,
  saveModalType: 'info',
  isReviewToUpdateLoading: false,
  reviewTemplates: [],
  reviewTemplateToUpdate: null,
  reviewTemplateSelectedCompetency: null,
  reviewTemplateSelectedExperienceLevel: null,
  reviewTemplateSurveyFilterText: '',
  isReviewInstancesLoading: false,
  reviewInstances: [],
  isSelectedReviewInstanceLoading: false,
  selectedReviewInstance: null,
  selectedReviewBreakdown: null,
  reviewIdsBeingUpdated: new Set(),
  newestLink: null,
};

export const reducer = createReducer(
  initialState,
  on(ReviewActions.setIsReviewTemplatesLoading, (state, {isReviewTemplatesLoading}) => ({...state, isReviewTemplatesLoading})),
  on(ReviewActions.getReviewTemplates, state => ({...state, isReviewTemplatesLoading: true})),
  on(ReviewActions.getReviewTemplatesComplete, (state, {reviewTemplates}) => ({
    ...state,
    isReviewTemplatesLoading: false,
    reviewTemplates
  })),
  on(ReviewActions.setIsReviewToUpdateLoading, (state, {isReviewToUpdateLoading}) => ({...state, isReviewToUpdateLoading})),
  on(ReviewActions.getReviewTemplateById, state => ({...state, isReviewToUpdateLoading: true})),
  on(ReviewActions.getReviewTemplateByIdComplete, (state, {reviewTemplate}) => {
    const reviewIndex = state.reviewTemplates.findIndex((rt) => rt.reviewTemplateId === reviewTemplate.reviewTemplateId);
    const reviewTemplates = [...state.reviewTemplates];
    if (reviewIndex > -1) {
      reviewTemplates[reviewIndex] = reviewTemplate;
    }
    return {
      ...state,
      isReviewToUpdateLoading: false,
      reviewTemplateToUpdate: reviewTemplate,
      reviewTemplates
    };
  }),
  on(ReviewActions.clearReviewTemplateToUpdate, (state) => ({...state, isReviewToUpdateLoading: false, reviewTemplateToUpdate: null})),
  on(ReviewActions.clearReviewInstances, (state) => ({...state, reviewInstances: [] })),
  on(ReviewActions.setIsReviewTemplateSaving, (state, {isReviewTemplateSaving, showModal, modalType}) => ({...state, isReviewTemplateSaving, showSaveModal: showModal, saveModalType: modalType})),
  on(ReviewActions.createReviewTemplate, state => ({...state, isReviewTemplateSaving: true})),
  on(ReviewActions.updateReviewTemplate, state => ({...state, isReviewTemplateSaving: true})),
  on(ReviewActions.cloneReviewTemplate, state => ({...state, isReviewTemplateSaving: true})),
  on(ReviewActions.deleteReviewTemplate, state => ({...state, isReviewTemplateSaving: true})),
  on(ReviewActions.getReviewInstances, state => ({...state, isReviewInstancesLoading: true})),
  on(ReviewActions.getReviewInstancesComplete, (state, {reviewInstances}) => ({
    ...state,
    isReviewInstancesLoading: false,
    reviewInstances
  })),
  on(ReviewActions.getReviewInstanceById, state => ({...state, isSelectedReviewInstanceLoading: true})),
  on(ReviewActions.getReviewInstanceByIdComplete, (state, {reviewInstance}) => ({
    ...state,
    selectedReviewInstance: reviewInstance,
    isSelectedReviewInstanceLoading: false
  })),
  on(ReviewActions.updateReviewInstance, (state, {reviewInstance}) => ({
      ...state,
      selectedReviewInstance: reviewInstance,
      isSelectedReviewInstanceLoading: false
    })),
  on(ReviewActions.selectReviewBreakdown, (state, {reviewBreakdown}) => ({...state, selectedReviewBreakdown: reviewBreakdown})),
  on(ReviewActions.updateNewestLink, (state, {link}) => ({...state, newestLink: link})),
  on(ReviewActions.setReviewTemplateSelectedCompetency, (state, { selectedCompetency }) => ({
    ...state,
    reviewTemplateSelectedCompetency: selectedCompetency
  })),
  on(ReviewActions.setReviewTemplateSelectedExperienceLevel, (state, { selectedExperienceLevel }) => ({
    ...state,
    reviewTemplateSelectedExperienceLevel: selectedExperienceLevel
  })),
  on(ReviewActions.setReviewTemplateSurveyFilterText, (state, { surveyFilterText }) => ({
    ...state,
    reviewTemplateSurveyFilterText: surveyFilterText
  })),
  on(ReviewActions.updateReviewInstanceExclusion, (state, {reviewId}) => ({
    ...state,
    reviewIdsBeingUpdated: new Set(state.reviewIdsBeingUpdated).add(reviewId)
  })),
  on(ReviewActions.updateReviewInstanceExclusionComplete, (state, { reviewId, isExcludedFromCalculation }) => {
    const updatedReviewInstances = state.reviewInstances.map(instance => {
      if (instance.reviewId !== reviewId) {
        return instance;
      }

      return {
        ...instance,
        isExcludedFromCalculation,
      };
    });

    const reviewIdsBeingUpdated = new Set(state.reviewIdsBeingUpdated);
    reviewIdsBeingUpdated.delete(reviewId);

    return {
      ...state,
      reviewInstances: updatedReviewInstances,
      reviewIdsBeingUpdated
    };
  })
);

