export const environment = {
  production: true,
  API_BASE_URL: 'https://dvt-levelup-api.azurewebsites.net/api',
  APPLICATION_BASE_URL: 'https://levelup.dvt.co.za/#/review/',
  name: 'deploy',
  issuer: 'https://login.microsoftonline.com/da42b32f-4d0b-4079-951f-eb1dbea4e2c4',
  clientId: '08608296-b9ea-42d9-8428-5a2fac755746',
  scope: 'https://levelup.dvt.co.za/api/Employees.Read.All',
  dummyClientSecret: '/TpM;^CF4->z<J*Y',
  featureFlagSkillsMatrix: false,
};
