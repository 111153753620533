<div class="d-flex">
  <div style="width: 155px; height: 155px;"
    class="mr-4 d-flex justify-content-center align-items-center">
    <div class="skeleton-animation rounded-circle"
      style="width: 148px; height: 148px;"></div>
  </div>

  <div style="padding-top: 40px;">
    <div style="padding: 6px 0 6px 0;">
      <div class="skeleton-animation"
        style="height: 24px; width: 200px"></div>
    </div>

    <div style="padding: 5px 0 5px 0">
      <div class="skeleton-animation"
        style="height: 15px; width: 150px;"></div>
    </div>
  </div>
</div>
