import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Config } from '../config';
import {Observable} from 'rxjs';
import {IWeighting} from '../models/interfaces/weightings';


@Injectable({
  providedIn: 'root'
})
export class WeightingsService {

  constructor(private http: HttpClient) {}

  getWeightingsURL = Config.weightings.getWeightings;
  deleteWeightingURL = Config.weightings.deleteWeighting;
  createWeightingURL = Config.weightings.createWeighting;
  updateWeightingURL = Config.weightings.updateWeighting;

  headers = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Ocp-Apim-Subscription-Key': Config.API_KEY,
  });

  getWeightings(): Observable<IWeighting[]> {
    return this.http.get<IWeighting[]>(this.getWeightingsURL, {headers: this.headers});
  }

  deleteWeighting(weightingId: number): Observable<void> {
    return this.http.delete<void>(this.deleteWeightingURL.replace('{weightingId}', weightingId.toString()),
      {
        headers: this.headers
      });
  }

  createWeighting(weighting: IWeighting): Observable<number> {
    const weightingToBeCreated = {...weighting};
    delete weightingToBeCreated.weightingId;
    delete weightingToBeCreated.isActive;
    return this.http.post<number>(this.createWeightingURL, {...weightingToBeCreated}, {headers: this.headers});
  }

  updateWeighting(weighting: IWeighting): Observable<boolean> {
    const weightingToBeUpdated = {...weighting};
    delete weightingToBeUpdated.isActive;
    return this.http.put<boolean>(this.updateWeightingURL, {...weightingToBeUpdated}, {headers: this.headers});
  }
}
