import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';

import * as ReviewActions from '../actions/review.actions';
import {updateReviewInstanceExclusionComplete} from '../actions/review.actions';
import {ReviewTemplatesService} from '../../services/review-templates.service';
import {Router} from '@angular/router';
import {IReviewTemplate} from '../../models/interfaces/review-template';
import {ReviewInstancesService} from '../../services/review-instances.service';
import {select, Store} from '@ngrx/store';
import {EmployeeState} from '../reducers/employee.reducer';
import {selectEmployeeProfile} from '../selectors/employee.selectors';
import Utils from '../../../utils';
import {environment} from 'src/environments/environment';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {getEmployeeProfileWithEmployeeNumber} from 'src/app/store/actions/employee.actions';


@Injectable()
export class ReviewEffects {

  // [Review] Get Review Templates
  getReviewTemplates$ = createEffect(() => this.actions$.pipe(
    ofType(ReviewActions.getReviewTemplates),
    mergeMap((action) =>
      this.reviewTemplatesService.getReviewTemplates(action.competencyId || '0', action.levelId || '0').pipe(
        map(response => {
          let reviewTemplateList: IReviewTemplate[] = [];
          if (Array.isArray(response)) {
            reviewTemplateList = response;
          } else if (response as IReviewTemplate) {
            reviewTemplateList = [response];
          }

          return ReviewActions.getReviewTemplatesComplete({reviewTemplates: reviewTemplateList});
        }),
        catchError(err => {
          console.error(err);
          return of(ReviewActions.setIsReviewTemplatesLoading({isReviewTemplatesLoading: false}));
        })
      )
    )
  ));

  // [Review] Get Review Template By ID
  getReviewTemplateById$ = createEffect(() => this.actions$.pipe(
    ofType(ReviewActions.getReviewTemplateById),
    mergeMap((action) =>
      this.reviewTemplatesService.getReviewTemplate(action.reviewTemplateId).pipe(
        map(response => {
          return ReviewActions.getReviewTemplateByIdComplete({reviewTemplate: response});
        }),
        catchError(err => {
          console.error(err);
          return of(ReviewActions.setIsReviewToUpdateLoading({isReviewToUpdateLoading: false}));
        })
      )
    )
  ));

  // [Review] Create Review Template
  createReviewTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(ReviewActions.createReviewTemplate),
    mergeMap((action) =>
      this.reviewTemplatesService.createReviewTemplate(action.reviewTemplate).pipe(
        mergeMap(response => {
          // this.notification.create(
          //   'success',
          //   'Survey Created',
          //   `${action.reviewTemplate.reviewTemplateName} has been created successfully.`
          // );
          if (action.navigateAfter) {
            this.router.navigate(['review-templates']);
          }
          return [
            ReviewActions.setIsReviewTemplateSaving({
              isReviewTemplateSaving: false,
              showModal: true,
              modalType: 'success'
            }),
            ReviewActions.getReviewTemplates({}),
          ];
        }),
        catchError(err => {
          console.error(err);
          // this.notification.create(
          //   'error',
          //   'Survey Creation Failed',
          //   `${action.reviewTemplate.reviewTemplateName} failed to be created.`
          // );
          return of(ReviewActions.setIsReviewTemplateSaving({
            isReviewTemplateSaving: false,
            showModal: true,
            modalType: 'error'
          }));
        })
      )
    )
  ));

  // [Review] Update Review Template
  updateReviewTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(ReviewActions.updateReviewTemplate),
    mergeMap(({reviewTemplate, navigateAfter}) =>
      this.reviewTemplatesService.updateReviewTemplate(reviewTemplate).pipe(
        mergeMap(_ => {
          // this.notification.create(
          //   'success',
          //   'Survey Updated',
          //   `${action.reviewTemplate.reviewTemplateName} has been updated successfully.`
          // );
          if (navigateAfter) {
            this.router.navigate(['review-templates']);
          }
          return [
            ReviewActions.setIsReviewTemplateSaving({
              isReviewTemplateSaving: false,
              showModal: true,
              modalType: 'success'
            }),
            ReviewActions.getReviewTemplateById({reviewTemplateId: reviewTemplate.reviewTemplateId.toString()})
          ];
        }),
        catchError(err => {
          console.error(err);
          // this.notification.create(
          //   'error',
          //   'Survey Update Failed',
          //   `${action.reviewTemplate.reviewTemplateName} failed to be updated.`
          // );
          return of(ReviewActions.setIsReviewTemplateSaving({
            isReviewTemplateSaving: false,
            showModal: true,
            modalType: 'error'
          }));
        })
      )
    )
  ));

  // [Review] Clone Review Template
  cloneReviewTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(ReviewActions.cloneReviewTemplate),
    mergeMap((action) =>
      this.reviewTemplatesService.createReviewTemplate({...action.reviewTemplate, reviewTemplateId: -1}).pipe(
        map(response => {
          // this.notification.create(
          //   'success',
          //   'Survey Updated',
          //   `${action.reviewTemplate.reviewTemplateName} has been updated successfully.`
          // );
          if (action.navigateAfter) {
            this.router.navigate(['review-templates']);
          }
          return ReviewActions.deleteReviewTemplate({reviewTemplate: action.reviewTemplate, showResult: false});
        }),
        catchError(err => {
          console.error(err);
          // this.notification.create(
          //   'error',
          //   'Survey Update Failed',
          //   `${action.reviewTemplate.reviewTemplateName} failed to be updated.`
          // );
          return of(ReviewActions.setIsReviewTemplateSaving({
            isReviewTemplateSaving: false,
            showModal: true,
            modalType: 'error'
          }));
        })
      )
    )
  ));

  // [Review] Delete Review Template
  deleteReviewTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(ReviewActions.deleteReviewTemplate),
    mergeMap((action) =>
      this.reviewTemplatesService.deleteReviewTemplate(action.reviewTemplate.reviewTemplateId).pipe(
        mergeMap(response => {
          if (action.showResult) {
            this.notification.create(
              'success',
              'Survey Template Deleted',
              `${action.reviewTemplate.reviewTemplateName} has been deleted.`
            );
          }
          return [
            ReviewActions.setIsReviewTemplateSaving({
              isReviewTemplateSaving: false,
              showModal: true,
              modalType: 'success'
            }),
            ReviewActions.getReviewTemplates({})
          ];
        }),
        catchError(err => {
          console.error(err);
          if (action.showResult) {
            this.notification.create(
              'error',
              'Survey Deletion Failed',
              `${action.reviewTemplate.reviewTemplateName} failed to be deleted.`
            );
          }
          return of(ReviewActions.setIsReviewTemplateSaving({
            isReviewTemplateSaving: false,
            showModal: true,
            modalType: 'error'
          }));
        })
      )
    )
  ));

  // Review Instances

  // [Review] Get Review Instances
  getReviewInstances$ = createEffect(() => this.actions$.pipe(
    ofType(ReviewActions.getReviewInstances),
    withLatestFrom(this.employeeStore.pipe(select(selectEmployeeProfile))),
    switchMap(([action, employeeProfile]) =>
      this.reviewInstanceService.getReviewInstanceList(employeeProfile?.employeeNumber || '').pipe(
        map(response => {
          return ReviewActions.getReviewInstancesComplete({reviewInstances: response?.reviews || []});
        }),
        catchError(err => {
          console.error(err);
          return of(ReviewActions.getReviewInstancesComplete({reviewInstances: []}));
        })
      )
    )
  ));

  // [Review] Get Review Instance By ID
  getReviewInstanceById$ = createEffect(() => this.actions$.pipe(
    ofType(ReviewActions.getReviewInstanceById),
    mergeMap((action) =>
      this.reviewInstanceService.getReviewInstance(action.reviewInstanceId).pipe(
        mergeMap(response => {
          return [
            ReviewActions.getReviewInstanceByIdComplete({reviewInstance: response}),
            getEmployeeProfileWithEmployeeNumber({employeeNumber: response.employeeNumber, isProtected: false, isReview: true})
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(ReviewActions.getReviewInstanceByIdComplete({reviewInstance: null}));
        })
      )
    )
  ));

  // [Review] Schedule Review Instance
  scheduleReviewInstance$ = createEffect(() => this.actions$.pipe(
    ofType(ReviewActions.scheduleReviewInstance),
    withLatestFrom(this.employeeStore.pipe(select(selectEmployeeProfile))),
    switchMap(([action, employeeProfile]) =>
      this.reviewInstanceService.scheduleReviewInstance(
        action.reviewTemplateId,
        employeeProfile.employeeNumber,
        action.reviewerFullName,
        action.reviewerPosition,
        action.reviewerEmailAddress).pipe(
        mergeMap(response => {
          if (response?.reviewId) {
            Utils.copyMessage(environment.APPLICATION_BASE_URL + response.reviewId, this.notification);
          }
          return [
            ReviewActions.updateNewestLink({link: environment.APPLICATION_BASE_URL + response.reviewId}),
            ReviewActions.getReviewInstances()
          ];
        }),
        catchError(err => {
          console.error(err);
          this.notification.create(
            'error',
            'Error',
            'There was an error while scheduling the survey.'
          );
          return EMPTY;
        })
      )
    )
  ));

  // [Review] Update Review Instance
  updateReviewInstance$ = createEffect(() => this.actions$.pipe(
    ofType(ReviewActions.updateReviewInstance),
    mergeMap((action) =>
      this.reviewInstanceService.updateReviewInstance(action.reviewInstance).pipe(
        map(response => {
          console.log('Survey Instance Updated: ', response);
        }),
        catchError(err => {
          console.error(err);
          return EMPTY;
        })
      )
    )
  ), {dispatch: false});

  // [Review] Update Review Instance Exclusion
  updateReviewInstanceExclusion$ = createEffect(() => this.actions$.pipe(
    ofType(ReviewActions.updateReviewInstanceExclusion),
    mergeMap((action) =>
      this.reviewInstanceService.updateReviewInstanceExclusion(action.reviewInstanceUpdate, action.reviewId).pipe(
        map(response => updateReviewInstanceExclusionComplete({
          reviewId: response.reviewId,
          isExcludedFromCalculation: response.isExcludedFromCalculation
        })),
        catchError(err => {
          console.error(err);
          // If there's an error, revert UI to the previous value
          return of(updateReviewInstanceExclusionComplete({
            reviewId: action.reviewId,
            isExcludedFromCalculation: !action.reviewInstanceUpdate.isExcludedFromCalculation
          }));
        })
      )
    )
  ));

  // [Review] Delete Review Instance
  deleteReviewInstance$ = createEffect(() => this.actions$.pipe(
    ofType(ReviewActions.deleteReviewInstance),
    mergeMap((action) =>
      this.reviewInstanceService.deleteReviewInstance(action.reviewInstanceId).pipe(
        map(response => {
          console.log('Survey Instance Deleted: ', response);
          return ReviewActions.getReviewInstances();
        }),
        catchError(err => {
          console.error(err);
          return EMPTY;
        })
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private employeeStore: Store<EmployeeState>,
    private reviewTemplatesService: ReviewTemplatesService,
    private reviewInstanceService: ReviewInstancesService,
    private router: Router,
    private notification: NzNotificationService
  ) {
  }

}
