import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IEmployeeBambooHR} from 'src/app/models/interfaces/employee-profile';
import {Store} from '@ngrx/store';
import {selectIsEmployeeProfileFromEmployeeNumberLoading} from 'src/app/store/selectors/employee.selectors';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();

  @Input() employeeProfile: IEmployeeBambooHR;

  isLoading$: Observable<boolean>;
  isUrlReview: boolean = false;

  constructor(
    public router: Router,
    private readonly store: Store
  ) {
  }

  ngOnInit(): void {
    let urlSection: string[] = [];
    urlSection = this.router.url.split('/');
    this.isUrlReview = urlSection.includes('review');
    this.isLoading$ = this.store.select(selectIsEmployeeProfileFromEmployeeNumberLoading);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}
