import {Injectable} from '@angular/core';
import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {routerNavigatedAction} from '@ngrx/router-store';
import {filter, map} from 'rxjs/operators';
import {getEmployeeProfileWithEmployeeNumber, noop,} from 'src/app/store/actions/employee.actions';
import {LocalStorageDirectory} from 'src/app/models/enums/local-storage-directory';
import {Store} from '@ngrx/store';
import {EmployeeState} from 'src/app/store/reducers/employee.reducer';
import {selectUserProfile} from 'src/app/store/selectors/employee.selectors';

@Injectable()
export class RouteEffects {

  navUtilization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      filter(({payload}): boolean => payload.routerState.url === '/employee/detail'),
      concatLatestFrom(() => this.employeeStore.select(selectUserProfile)),
      map(([action, user]) => {
        const employeeNumber: string = localStorage.getItem(LocalStorageDirectory.employeeNumber);
        if (employeeNumber?.length > 0) {
          return getEmployeeProfileWithEmployeeNumber({employeeNumber, isProtected: user.isAdmin, isReview: false});
        } else {
          return noop();
        }
      })
    )
  );

  constructor(
    private actions$: Actions,
    private employeeStore: Store<EmployeeState>,
  ) {}
}
