import {ApexChartPie} from './apex-chart-pie';

export const APEX_CHART_PIE_DEFAULT: ApexChartPie = {
  options: {
    series: [],
    chart: {
      type: 'pie'
    },
    labels: [],
    theme: {
      palette: 'palette8'
    },
    legend: {
      position: 'bottom'
    },
    dataLabels: {
      enabled: true,
      // formatter(val: string | number | number[], opts?: any): string | number {
      //   return '';
      // }
    },
    title: {
      text: 'no chart title set'
    },
    nodata: {
      text: 'Please select a month on the Bar Chart.'
    }
  }
};
