import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {CreateReviewComponent} from './create-review/create-review.component';

/** Feature Module */
import {SharedModule} from '../shared/shared.module';
import {IsSectionInListPipe} from '../../pipes/is-section-in-list.pipe';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule} from '@angular/forms';
import {ConfigurationModule} from '../configuration/configuration.module';
import {CreateReviewQuestionComponent} from './create-review-question/create-review-question.component';
import {FindRatingByIdPipe} from '../../pipes/find-rating-by-id.pipe';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzStepsModule} from 'ng-zorro-antd/steps';
import {NzCollapseModule} from 'ng-zorro-antd/collapse';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {MsalGuard} from '@azure/msal-angular';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {CreateReviewContainerComponent} from './create-review-container/create-review-container.component';
import {LetModule, PushModule} from '@ngrx/component';

@NgModule({
  declarations: [
    CreateReviewContainerComponent,
    CreateReviewComponent,
    IsSectionInListPipe,
    FindRatingByIdPipe,
    CreateReviewQuestionComponent
  ],
    imports: [
      LetModule, PushModule,
      CommonModule,
      SharedModule,
      RouterModule.forChild([
          {path: 'create-review', component: CreateReviewContainerComponent, canActivate: [MsalGuard]},
          {path: 'create-review/:reviewTemplateId', component: CreateReviewContainerComponent, canActivate: [MsalGuard]},
      ]),
      DragDropModule,
      FormsModule,
      ConfigurationModule,
      NzIconModule,
      NzStepsModule,
      NzCollapseModule,
      NzSwitchModule,
      NzButtonModule,
      NzRadioModule,
      NzInputModule,
      NzSelectModule,
      NzModalModule,
      NzCheckboxModule,
      NzToolTipModule,
    ],
})
export class CreateReviewModule {}
