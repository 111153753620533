import { Pipe, PipeTransform } from '@angular/core';
import {IReviewTemplate} from '../models/interfaces/review-template';
import {ICompetency} from '../models/interfaces/competency';
import {IExperienceLevel} from '../models/interfaces/experience-level';

@Pipe({
  name: 'filterReviewTemplates'
})
export class FilterReviewTemplatesPipe implements PipeTransform {

  transform(reviewTemplates: IReviewTemplate[], competency: ICompetency, experienceLevel: IExperienceLevel, filterText: string = null): IReviewTemplate[] {
    if ((!competency && !experienceLevel && (!filterText || filterText.length === 0)) || !reviewTemplates || reviewTemplates.length === 0) {
      return reviewTemplates;
    }

    if (filterText) {
      filterText = filterText.toLowerCase();
    }

    let filteredList = competency ? reviewTemplates.filter(rt => rt?.competencyId === competency.competencyId) : reviewTemplates;
    filteredList = experienceLevel ? filteredList.filter(rt => rt?.levelId === experienceLevel.levelId) : filteredList;
    filteredList = filterText ? filteredList.filter(rt =>
      rt?.competency?.competencyName?.toLowerCase().indexOf(filterText) > -1 ||
      rt?.level?.levelName?.toLowerCase().indexOf(filterText) > -1 ||
      rt?.competencyName?.toLowerCase().indexOf(filterText) > -1 ||
      rt?.levelName?.toLowerCase().indexOf(filterText) > -1 ||
      rt?.reviewTemplateName?.toLowerCase().indexOf(filterText) > -1 ||
      rt?.description?.toLowerCase().indexOf(filterText) > -1 ||
      rt?.friendlyName?.toLowerCase().indexOf(filterText) > -1
    ) : filteredList;

    return filteredList;
  }

}
