import {Component, Input} from '@angular/core';
import {LoadingIndicatorSizes} from '../../../models/general/loading-indicator-sizes';
import {IEmployeeBambooHR} from '../../../models/interfaces/employee-profile';
import {UtilsEmployee} from '../../../utils/utils-employee';

@Component({
  selector: 'app-employee-container-list',
  templateUrl: './employee-container-list.component.html',
  styleUrls: ['./employee-container-list.component.scss']
})
export class EmployeeContainerListComponent {

  @Input() set isEmployeeListLoading(loading: boolean) {
    this.loadingIndicator.loading = loading || false;
  }

  @Input() set setEmployeeListAndFilter(data: {employees: IEmployeeBambooHR[], filter: string}) {
    this.filterEmployeeList(data.employees, data.filter);
  }

  employeeList: IEmployeeBambooHR[] = [];

  loadingIndicator: {
    size: LoadingIndicatorSizes,
    loading: boolean
  } = {
    size: LoadingIndicatorSizes.xLarge,
    loading: false
  };

  private filterEmployeeList(employeeList: IEmployeeBambooHR[], employeeFilter: string): void {
    this.employeeList = UtilsEmployee.filterEmployeeList(employeeList, employeeFilter);
  }

}
