<div class="question-container">
  <h5>
    {{questionNumber}}. {{question?.questionTitle}} <sup class="text-danger" *ngIf="question?.isMandatory">*</sup>
  </h5>

  <!-- 1 - Choice / 2 - Rating -->
  <div *ngIf="question?.questionTypeId === 1 || question?.questionTypeId === 2">
    <nz-radio-group [ngModel]="question.reviewAnswers[0]?.answerId" [disabled]="disabled">
      <label
        nz-radio
        [nzValue]="choice.answerId"
        *ngFor="let choice of question?.templateAnswers"
        (click)="update(choice)">
        {{choice.answerValue}}
      </label>
    </nz-radio-group>
  </div>

  <!-- 3 - Text -->
  <div *ngIf="question?.questionTypeId === 3">
    <input
      *ngIf="question.answerLength < 500"
      nz-input
      [disabled]="disabled"
      [value]="selectedAnswer.answerValue"
      [(ngModel)]="selectedAnswer.answerValue"
      (ngModelChange)="update(selectedAnswer)">

    <textarea
      *ngIf="question.answerLength >= 500"
      nz-input
      nzAutosize
      rows="3"
      [disabled]="disabled"
      [value]="selectedAnswer.answerValue"
      [(ngModel)]="selectedAnswer.answerValue"
      (ngModelChange)="update(selectedAnswer)">
    </textarea>
  </div>

</div>
