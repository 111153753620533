<div class="completion-counters">
  <div>
    <i nz-icon nzType="check-circle" nzTheme="outline"></i>
    {{selectedEmployeeReviewBreakdown.totalCompletedReviewers}} / {{selectedEmployeeReviewBreakdown.totalReviewers}} Completed Survey{{selectedEmployeeReviewBreakdown.totalReviewers !== 1 ? 's' : ''}}
  </div>
  <div>
    <i nz-icon nzType="check-circle" nzTheme="outline"></i>
    {{selectedEmployeeReviewBreakdown.totalStartedReviewers}} / {{selectedEmployeeReviewBreakdown.totalReviewers}} In Progress
  </div>
  <div>
    <i nz-icon nzType="check-circle" nzTheme="outline"></i>
    {{selectedEmployeeReviewBreakdown.totalReviewers - selectedEmployeeReviewBreakdown.totalCompletedReviewers - selectedEmployeeReviewBreakdown.totalStartedReviewers}} / {{selectedEmployeeReviewBreakdown.totalReviewers}} Not Started
  </div>
</div>

<div class="overall-percent">
  <h4>Overall Score</h4>
  <nz-progress
    nzType="circle"
    [nzPercent]="selectedEmployeeReviewBreakdown.calculatedPercentage"
    [nzStrokeColor]="selectedEmployeeReviewBreakdown.calculatedPercentage < 50 ? '#66d2fa' : selectedEmployeeReviewBreakdown.calculatedPercentage < 75 ? '#47c0ec' : '#0d88c7'"
    [nzFormat]="formatPercentSmall">
  </nz-progress>
</div>

<h4 style="text-align: left">Section:</h4>

<div class="section-percentages">
  <div class="section-percentage" *ngFor="let section of selectedEmployeeReviewBreakdown.reviewBreakdownSections">

    <div *ngIf="!section.speechBubble" class="survey-breakdown-progress">
      <nz-progress
        nzType="circle"
        [nzPercent]="section.calculatedPercentage"
        [nzStrokeColor]="section.calculatedPercentage < 50 ? '#66d2fa' : section.calculatedPercentage < 75 ? '#47c0ec' : '#0d88c7'"
        [nzFormat]="formatPercentSmall"
        [nzWidth]="70">
      </nz-progress>
    </div>

    <div *ngIf="section.speechBubble" class="survey-breakdown-progress">
      <i nz-icon nzType="message" nzTheme="outline"></i>
    </div>

    <h4>{{section.sectionName}}{{section.exclude ? ' (Excluded)' : ''}}</h4>
  </div>
</div>

<div class="section-breakdown-container" *ngFor="let section of selectedEmployeeReviewBreakdown.reviewBreakdownSections">
  <div class="section-title-container">
    <div class="section-title-description">
      <h6>{{section.sectionName}} {{section.exclude ? '(Excluded)' : ''}}</h6>
      <p>{{section.sectionDescription}}</p>
    </div>
    <nz-progress
      *ngIf="section.calculatedPercentage > 0"
      nzType="circle"
      [nzPercent]="section.calculatedPercentage"
      [nzStrokeColor]="section.calculatedPercentage < 50 ? '#66d2fa' : section.calculatedPercentage < 75 ? '#47c0ec' : '#0d88c7'"
      [nzFormat]="formatPercentSmall"
      [nzWidth]="40">
    </nz-progress>
  </div>

  <nz-table
    *ngFor="let question of section.questions"
    #outBordered
    nzOuterBordered
    [nzTitle]="question.questionTitle + '(Max Rating: ' + getMaxRating(question) + ') (Weighting: ' + question.weighting?.weight + ')'"
    [nzData]="question.questionAnswers"
    [nzShowPagination]="false">
    <thead>
    <tr>
      <th>Reviewer</th>
      <th>Position</th>
      <th>Date Performed</th>
      <th>Response (Rating) [%]</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let answer of outBordered.data">
      <td>{{ answer.reviewerFullName }} {{answer.rating?.excludeAnswerFromCalculation ? '(Excluded)' : ''}}</td>
      <td>{{ answer.reviewerPosition }}</td>
      <td>{{ answer.reviewEndTime | date: 'yyy-MM-dd hh:mm:ss' }}</td>
      <td style="white-space: pre-wrap;">
        {{ answer.answerValue }}
        <span *ngIf="question.weighting?.weight > 0 && getMaxRating(question) > 0 && !answer.rating?.excludeAnswerFromCalculation">
            ({{ answer.rating?.rating1 }}) [{{answer.rating?.rating1 / getMaxRating(question) | percent: '1.0-0' }}]
        </span>
      </td>
    </tr>
    <tr *ngIf="question.calculatedPercentage > 0">
      <td></td>
      <td></td>
      <td></td>
      <td>
        <strong>TOTAL: </strong>
        <span style="font-size: 20px; color: #1c7eba;">
        {{ question.calculatedPercentage | number: '1.0-0' }}%
      </span>
      </td>
    </tr>
    </tbody>
  </nz-table>
</div>
