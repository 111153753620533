import { Pipe, PipeTransform } from '@angular/core';
import {IReviewBreakdown} from '../models/interfaces/review-breakdown';

@Pipe({
  name: 'filterReviewBreakdowns'
})
export class FilterReviewBreakdownsPipe implements PipeTransform {

  transform(reviewBreakdowns: IReviewBreakdown[], filterText: string = null): IReviewBreakdown[] {
    if (!filterText || filterText.length === 0 || !reviewBreakdowns || reviewBreakdowns.length === 0) {
      return reviewBreakdowns;
    }

    if (filterText) {
      filterText = filterText.toLowerCase();
    }

    return reviewBreakdowns.filter(rb =>
      rb?.reviewTemplateName?.toLowerCase().indexOf(filterText) > -1 ||
      rb?.description?.toLowerCase().indexOf(filterText) > -1
    );
  }

}
