import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {IEmployeeBambooHR} from '../../../models/interfaces/employee-profile';
import {
  selectEmployeeListAndFilter,
  selectIsEmployeeListLoading
} from '../../../store/selectors/employee.selectors';

@Component({
  selector: 'app-employee-container',
  templateUrl: './employee-container.component.html',
  styleUrls: ['./employee-container.component.scss']
})
export class EmployeeContainerComponent implements OnInit {

  isEmployeeListLoading$: Observable<boolean>;
  employeeListAndFilter$: Observable<{ employees: IEmployeeBambooHR[], filter: string }>;

  constructor(
    private readonly store: Store
  ) {
  }

  ngOnInit(): void {
    this.isEmployeeListLoading$ = this.store.select(selectIsEmployeeListLoading);
    this.employeeListAndFilter$ = this.store.select(selectEmployeeListAndFilter);
  }

}
