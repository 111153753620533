/** Angular */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

/** Plugin */
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzMenuModule} from 'ng-zorro-antd/menu';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzStepsModule} from 'ng-zorro-antd/steps';
import {NzProgressModule} from 'ng-zorro-antd/progress';

/** Components */
import {UserProfileComponent} from './user-profile/user-profile.component';
import {ProfileSkeletonComponent} from './user-profile/profile-skeleton/profile-skeleton.component';
import {ModalNotifyContentComponent} from './modal-notify-content/modal-notify-content.component';
import {LoadingIndicatorComponent} from './loading-indicator/loading-indicator.component';

/** Pipes */
import {FilterQuestionFromSectionPipe} from '../../pipes/is-question-from-section.pipe';
import {LetModule} from '@ngrx/component';

@NgModule({
    declarations: [
        // Components
        UserProfileComponent,
        ProfileSkeletonComponent,
        ModalNotifyContentComponent,
        LoadingIndicatorComponent,
        // Pipes
        FilterQuestionFromSectionPipe,
    ],
  imports: [
    // Angular
    CommonModule,
    // Plugins
    NzAvatarModule,
    NzButtonModule,
    NzDividerModule,
    NzIconModule,
    NzInputModule,
    NzLayoutModule,
    NzMenuModule,
    NzModalModule,
    NzProgressModule,
    NzRadioModule,
    NzSelectModule,
    NzStepsModule,
    NzTableModule,
    NzToolTipModule,
    LetModule
  ],
    exports: [
        // Plugins
        NzAvatarModule,
        NzButtonModule,
        NzDividerModule,
        NzIconModule,
        NzInputModule,
        NzLayoutModule,
        NzMenuModule,
        NzModalModule,
        NzProgressModule,
        NzRadioModule,
        NzSelectModule,
        NzStepsModule,
        NzTableModule,
        NzToolTipModule,
        // Components
        UserProfileComponent,
        ProfileSkeletonComponent,
        ModalNotifyContentComponent,
        LoadingIndicatorComponent,
        // Pipes
        FilterQuestionFromSectionPipe,
    ],
})
export class SharedModule {
}
