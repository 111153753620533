<div
  *ngIf="loadingIndicator.loading"
  class="emp-container-list-loading">
  <app-loading-indicator [size]="loadingIndicator.size"></app-loading-indicator>
</div>

<div
  *ngIf="!loadingIndicator.loading"
  class="employee-list-container">
  <ng-container *ngFor="let employee of employeeList">
    <app-employee-list-card
      [setEmployee]="employee">
    </app-employee-list-card>
  </ng-container>
</div>
