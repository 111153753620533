<div *ngrxLet="userProfile$; let userProfile">
  <div class="d-flex flex-column flex-lg-row">
    <app-user-profile [employeeProfile]="employeeProfile$ | ngrxPush"></app-user-profile>
  </div>

  <div class="pt-4" *ngIf="userProfile?.isAdmin">
    <nz-tabset>
      <nz-tab nzTitle="View Surveys">
        <!-- content of tab 1 -->

        <div style="padding: 0 8px;" *ngIf="(employeeReviewBreakdowns | filterReviewBreakdowns:searchText) as reviewsToDisplay">
          <h5 class="num-records d-flex">
            <span>
              {{reviewsToDisplay.length}} RECORD{{reviewsToDisplay.length !== 1 ? 'S' : ''}}
              {{!!searchText ? 'FOUND' : ''}}
            </span>

            <i [hidden]="!employeeReviewsLoading" nz-icon [nzType]="'loading'" class="d-flex align-items-center pl-2"></i>
          </h5>

          <div class="mt-4 mb-3" style="box-shadow: 0 0 8px 0 rgba(12, 12, 13, 0.1)">
            <nz-input-group [nzSuffix]="suffixIconSearch" nzSize="large">
              <input type="text" nz-input placeholder="Filter Surveys" [(ngModel)]="searchText"/>
            </nz-input-group>
            <ng-template #suffixIconSearch>
              <i nz-icon nzType="search"></i>
            </ng-template>
          </div>

          <nz-collapse
            nzBordered="false"
            [nzExpandIconPosition]="'right'"
            class="collapse-pane"
            *ngFor="let employeeReviewBreakdown of reviewsToDisplay">
            <nz-collapse-panel
              [nzHeader]="breakdownHeader"
              [(nzActive)]="employeeReviewBreakdown.expanded">
              <div style="padding: 4px 16px;">
                <app-survey-breakdown
                  *ngIf="employeeReviewBreakdown.expanded"
                  [empProfile]="employeeProfile$ | ngrxPush"
                  [setSelectedEmployeeReviewBreakdown]="employeeReviewBreakdown">
                </app-survey-breakdown>
              </div>
              <ng-template #breakdownHeader>
                <div class="d-flex flex-column">
                  <div class="heading">
                    <h4 class="d-flex m-0">
                      {{employeeReviewBreakdown.reviewTemplateName}}
                      <div class="filter-pill ml-auto" (click)="showReviewers(employeeReviewBreakdown.reviewTemplateId, $event)">View Reviewers</div>
                      <div class="filter-pill ml-1" *ngIf="reviewTemplateList | isTemplateActive: employeeReviewBreakdown.reviewTemplateId" (click)="showAddReviewer(employeeReviewBreakdown.reviewTemplateId, $event)">Add Reviewer</div>
                      <div class="filter-pill ml-1 filter-pill-grey" *ngIf="!(reviewTemplateList | isTemplateActive: employeeReviewBreakdown.reviewTemplateId)"><i nz-icon nzType="lock" nzTheme="outline"></i> Survey Closed</div>
                      <div class="filter-pill ml-1" (click)="onPrint(employeeReviewBreakdown.reviewTemplateId); $event.stopPropagation()">Download Report</div>
                    </h4>
                    <p *ngIf="employeeReviewBreakdown.createdDate" class="m-0" style="opacity: 0.7">Date Added: {{employeeReviewBreakdown.createdDate}}</p>
                  </div>
                  <div class="white-subheading d-flex" [hidden]="employeeReviewBreakdown.expanded">
                    <div>
                      <i nz-icon nzType="check-circle" nzTheme="outline"></i>
                      {{employeeReviewBreakdown.totalCompletedReviewers}} / {{employeeReviewBreakdown.totalReviewers}} Completed Survey{{employeeReviewBreakdown.totalReviewers !== 1 ? 's' : ''}}
                    </div>
                    <div>
                      <i nz-icon nzType="check-circle" nzTheme="outline"></i>
                      {{employeeReviewBreakdown.totalStartedReviewers}} / {{employeeReviewBreakdown.totalReviewers}} In Progress
                    </div>
                    <div>
                      <i nz-icon nzType="check-circle" nzTheme="outline"></i>
                      {{employeeReviewBreakdown.totalReviewers - employeeReviewBreakdown.totalCompletedReviewers - employeeReviewBreakdown.totalStartedReviewers}} / {{employeeReviewBreakdown.totalReviewers}} Not Started
                    </div>
                    <nz-progress
                      [nzPercent]="employeeReviewBreakdown.calculatedPercentage"
                      [nzFormat]="formatPercent"
                      nzType="circle"
                      nzStrokeWidth="10"
                      [nzStrokeColor]="employeeReviewBreakdown.calculatedPercentage < 50 ? '#66d2fa' : employeeReviewBreakdown.calculatedPercentage < 75 ? '#47c0ec' : '#0d88c7'"
                      [nzWidth]="45">
                    </nz-progress>
                  </div>
                </div>
              </ng-template>
            </nz-collapse-panel>
          </nz-collapse>

        </div>
      </nz-tab>

      <nz-tab nzTitle="Schedule Survey">
        <!-- content of tab 2 -->
        <app-schedule-review></app-schedule-review>
      </nz-tab>

      <nz-tab nzTitle="View Utilisation" (nzSelect)="onSelectUtil($event)">
        <!-- content of tab 3 -->
        <app-employee-utilisation-container></app-employee-utilisation-container>
      </nz-tab>

    </nz-tabset>

  </div>
  <div class="pt-4" *ngIf="!userProfile?.isAdmin">
    <app-employee-utilisation-container></app-employee-utilisation-container>
  </div>
</div>


<nz-modal
  [(nzVisible)]="isAddReviewerVisible"
  (nzOnCancel)="isAddReviewerVisible = false"
  [nzFooter]="null"
  [nzTitle]="null"
  [nzWidth]="'550px'"
  [nzBodyStyle]="{ padding: '0', background: 'transparent' }"
  [nzAutofocus]="null"
  [nzContent]="addReviewerModal">
</nz-modal>

<ng-template #addReviewerModal>
  <div style="background: white; border-radius: 16px;" class="p-3">
    <app-schedule-review [selectedReviewTemplate]="selectedReviewTemplate" [showTemplateList]="false"></app-schedule-review>
  </div>
</ng-template>

<nz-modal
  [(nzVisible)]="isViewReviewersVisible"
  (nzOnCancel)="isViewReviewersVisible = false"
  [nzFooter]="null"
  [nzTitle]="null"
  [nzWidth]="'550px'"
  [nzBodyStyle]="{ padding: '0', background: 'transparent' }"
  [nzAutofocus]="null"
  [nzContent]="viewReviewersModal">
</nz-modal>

<ng-template #viewReviewersModal>
  <div style="background: white; border-radius: 16px;" class="p-3" *ngIf="selectedReviewGroup">
    <h4>{{selectedReviewGroup.reviewTemplateName}}</h4>
    <div *ngFor="let review of selectedReviewGroup.reviews"
         class="d-flex flex-column mb-2 p-4"
         style="background: rgba(0, 0, 0, 0.03); border-radius: 8px;">
      <div class="d-flex ant-row-space-between">
        <h4>{{review.reviewerFullName}}</h4>
        <label nz-checkbox
               [nzDisabled]="(reviewIdsUpdating$ | ngrxPush).has(review.reviewId)"
               (nzCheckedChange)="updateReviewInstanceExclusion(review)"
               [nzChecked]="review.isExcludedFromCalculation">
          Exclude from results
          </label>
      </div>
      <p class="m-0">
        {{review.reviewerPosition}} |
        <a *ngIf="review.reviewerEmailAddress"
           [href]="'mailto:' + review.reviewerEmailAddress">{{review.reviewerEmailAddress}}</a>
      </p>
      <p>
          <span *ngIf="(review.reviewStartTime === '0001-01-01T00:00:00' || review.reviewStartTime === '' || !review.reviewStartTime) && (review.reviewEndTime === '0001-01-01T00:00:00' || !review.reviewEndTime); else hasStarted">
            Not started
          </span>

        <ng-template #hasStarted>
          <span *ngIf="!!review.reviewStartTime && review.reviewStartTime !== '0001-01-01T00:00:00' && review.reviewStartTime !== ''">
            Start: {{review.reviewStartTime | date: 'dd/MM/yyyy HH:mm:ss'}} |
          </span>

          <span *ngIf="!review.reviewEndTime; else completed">
              In progress
            </span>

          <ng-template #completed>
            End: {{review.reviewEndTime | date: 'dd/MM/yyyy HH:mm:ss'}}
            <span *ngIf="!!review.reviewStartTime && review.reviewStartTime !== '0001-01-01T00:00:00' && review.reviewStartTime !== ''">
               | Duration: {{review.reviewEndTime | calculateReviewDuration : review.reviewStartTime}}
            </span>
          </ng-template>
        </ng-template>
      </p>
      <div style="display: flex; gap: 1em; align-items: center; justify-content: center; flex-wrap: wrap;">
        <input style="flex: 0 0 100%" nz-input disabled [(ngModel)]="baseApplicationURL + review.reviewId"/>
        <div class="filter-pill" (click)="copyMessage(review.reviewId)">
          <i nz-icon nzType="copy" nzTheme="outline"></i> Copy Link
        </div>
        <div class="filter-pill" (click)="showDeleteReviewInstance(review)">
          <i nz-icon nzType="delete" nzTheme="outline"></i> Revoke Link
        </div>
      </div>
    </div>
  </div>
</ng-template>

<nz-modal
  [(nzVisible)]="isDeleteModalVisible"
  (nzOnCancel)="isDeleteModalVisible = false"
  [nzFooter]="null"
  [nzTitle]="null"
  [nzWidth]="'550px'"
  [nzBodyStyle]="{ padding: '0', background: 'transparent' }"
  [nzAutofocus]="null"
  [nzContent]="deleteConfirmationModal">
</nz-modal>

<ng-template #deleteConfirmationModal>
  <app-modal-notify-content
    type="warning"
    colorOverrideByType="info"
    title="Revoke Link"
    subtitle="You are about to revoke the survey link"
    [furtherInfo]="reviewInstanceToDelete?.reviewTemplateDisplayName + ': ' + reviewInstanceToDelete?.reviewerFullName"
    [createdDate]="reviewInstanceToDelete?.createdDate"
    confirmationMessage="Are you sure you want to continue?"
    cancelText="Cancel"
    okText="Revoke"
    (cancel)="isDeleteModalVisible = false"
    (ok)="deleteReviewInstance()"
  ></app-modal-notify-content>
</ng-template>
