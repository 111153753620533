import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ConfigActions from '../actions/config.actions';
import {mergeMap, map, catchError} from 'rxjs/operators';
import {WeightingsService} from '../../services/weightings.service';
import {RatingsService} from '../../services/ratings.service';
import {CompetencyService} from '../../services/competency.service';
import {ExperienceLevelService} from '../../services/experience-level.service';
import { SectionsService } from 'src/app/services/sections.service';
import {of} from 'rxjs';


@Injectable()
export class ConfigEffects {

  // Weightings

  // [Config] Get Weightings
  getWeightings$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.getWeightings),
    mergeMap(() =>
      this.weightingsService.getWeightings().pipe(
        map(response => {
          return ConfigActions.getWeightingsComplete({weightings: response});
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsWeightingsLoading({isWeightingsLoading: false}));
        })
      )
    )
  ));

  // [Config] Create Weighting
  createWeighting$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.createWeighting),
    mergeMap((action) =>
      this.weightingsService.createWeighting(action.weighting).pipe(
        map(response => {
          return ConfigActions.getWeightings();
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsWeightingsLoading({isWeightingsLoading: false}));
        })
      )
    )
  ));

  // [Config] Update Weighting
  updateWeighting$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.updateWeighting),
    mergeMap((action) =>
      this.weightingsService.updateWeighting(action.weighting).pipe(
        map(response => {
          return ConfigActions.getWeightings();
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsWeightingsLoading({isWeightingsLoading: false}));
        })
      )
    )
  ));

  // [Config] Delete Weighting
  deleteWeighting$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.deleteWeighting),
    mergeMap((action) =>
      this.weightingsService.deleteWeighting(action.weighting.weightingId).pipe(
        map(response => {
          return ConfigActions.getWeightings();
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsWeightingsLoading({isWeightingsLoading: false}));
        })
      )
    )
  ));

  // Ratings

  // [Config] Get Ratings
  getRatings$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.getRatings),
    mergeMap(() =>
      this.ratingsService.getRatings().pipe(
        map(response => {
          return ConfigActions.getRatingsComplete({ratings: response});
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsRatingsLoading({isRatingsLoading: false}));
        })
      )
    )
  ));

  // [Config] Create Rating
  creatRating$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.createRating),
    mergeMap((action) =>
      this.ratingsService.createRating(action.rating).pipe(
        map(response => {
          return ConfigActions.getRatings();
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsRatingsLoading({isRatingsLoading: false}));
        })
      )
    )
  ));

  // [Config] Update Rating
  updateRating$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.updateRating),
    mergeMap((action) =>
      this.ratingsService.updateRating(action.rating).pipe(
        map(response => {
          return ConfigActions.getRatings();
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsRatingsLoading({isRatingsLoading: false}));
        })
      )
    )
  ));

  // [Config] Delete Rating
  deleteRating$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.deleteRating),
    mergeMap((action) =>
      this.ratingsService.deleteRating(action.rating.ratingId).pipe(
        map(response => {
          return ConfigActions.getRatings();
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsRatingsLoading({isRatingsLoading: false}));
        })
      )
    )
  ));

  // Competencies

  // [Config] Get Competencies
  getCompetencies$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.getCompetencies),
    mergeMap(() =>
      this.competencyService.getAllCompetencies().pipe(
        map(response => {
          return ConfigActions.getCompetenciesComplete({competencies: response});
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsCompetenciesLoading({isCompetenciesLoading: false}));
        })
      )
    )
  ));

  // [Config] Create Competency
  creatCompetency$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.createCompetency),
    mergeMap((action) =>
      this.competencyService.createCompetency(action.competency).pipe(
        map(response => {
          return ConfigActions.getCompetencies();
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsCompetenciesLoading({isCompetenciesLoading: false}));
        })
      )
    )
  ));

  // [Config] Update Competency
  updateCompetency$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.updateCompetency),
    mergeMap((action) =>
      this.competencyService.updateCompetency(action.competency).pipe(
        map(response => {
          return ConfigActions.getCompetencies();
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsCompetenciesLoading({isCompetenciesLoading: false}));
        })
      )
    )
  ));

  // [Config] Delete Competency
  deleteCompetency$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.deleteCompetency),
    mergeMap((action) =>
      this.competencyService.deleteCompetency(action.competency.competencyId).pipe(
        map(response => {
          return ConfigActions.getCompetencies();
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsCompetenciesLoading({isCompetenciesLoading: false}));
        })
      )
    )
  ));

  // Levels of Experience

  // [Config] Get Experience Levels
  getExperienceLevels$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.getExperienceLevels),
    mergeMap(() =>
      this.experienceLevelService.getAllExperienceLevels().pipe(
        map(response => {
          return ConfigActions.getExperienceLevelsComplete({experienceLevels: response});
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsExperienceLevelsLoading({isExperienceLevelsLoading: false}));
        })
      )
    )
  ));

  // [Config] Create Experience Level
  creatExperienceLevel$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.createExperienceLevel),
    mergeMap((action) =>
      this.experienceLevelService.createLevelOfExperience(action.experienceLevel).pipe(
        map(response => {
          return ConfigActions.getExperienceLevels();
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsExperienceLevelsLoading({isExperienceLevelsLoading: false}));
        })
      )
    )
  ));

  // [Config] Update Experience Level
  updateExperienceLevel$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.updateExperienceLevel),
    mergeMap((action) =>
      this.experienceLevelService.updateLevelOfExperience(action.experienceLevel).pipe(
        map(response => {
          return ConfigActions.getExperienceLevels();
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsExperienceLevelsLoading({isExperienceLevelsLoading: false}));
        })
      )
    )
  ));

  // [Config] Delete Experience Level
  deleteExperienceLevel$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.deleteExperienceLevel),
    mergeMap((action) =>
      this.experienceLevelService.deleteLevelOfExperience(action.experienceLevel.levelId).pipe(
        map(response => {
          return ConfigActions.getExperienceLevels();
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsExperienceLevelsLoading({isExperienceLevelsLoading: false}));
        })
      )
    )
  ));

  // Sections

  // [Config] Get Sections
  getSections$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.getSections),
    mergeMap(() =>
      this.sectionsService.getAllSections().pipe(
        map(response => {
          return ConfigActions.getSectionsComplete({sections: response});
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsSectionsLoading({isSectionsLoading: false}));
        })
      )
    )
  ));

  // [Config] Create Section
  creatSection$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.createSection),
    mergeMap((action) =>
      this.sectionsService.createSection(action.section).pipe(
        map(response => {
          return ConfigActions.getSections();
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsSectionsLoading({isSectionsLoading: false}));
        })
      )
    )
  ));

  // [Config] Update Section
  updateSection$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.updateSection),
    mergeMap((action) =>
      this.sectionsService.updateSection(action.section).pipe(
        map(response => {
          return ConfigActions.getSections();
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsSectionsLoading({isSectionsLoading: false}));
        })
      )
    )
  ));

  // [Config] Delete Section
  deleteSection$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.deleteSection),
    mergeMap((action) =>
      this.sectionsService.deleteSection(action.section.sectionId).pipe(
        map(response => {
          return ConfigActions.getSections();
        }),
        catchError(err => {
          console.error(err);
          return of(ConfigActions.setIsSectionsLoading({isSectionsLoading: false}));
        })
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private weightingsService: WeightingsService,
    private ratingsService: RatingsService,
    private competencyService: CompetencyService,
    private experienceLevelService: ExperienceLevelService,
    private sectionsService: SectionsService,
  ) {}

}
