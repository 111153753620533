import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IReviewInstance, ISectionQuestion, ISectionQuestionAnswer } from '../../../models/interfaces/review-template';

@Component({
  selector: 'app-complete-review-question',
  templateUrl: './complete-review-question.component.html',
  styleUrls: ['./complete-review-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompleteReviewQuestionComponent implements OnInit {

  @Input() question: ISectionQuestion;
  @Input() reviewInstance?: IReviewInstance;
  @Input() disabled = false;
  @Output() updateAnswer = new EventEmitter<{ questionId: number; answer: ISectionQuestionAnswer[]; }>();

  selectedAnswer: ISectionQuestionAnswer = null;

  ngOnInit(): void {
    if (this.question?.questionTypeId === 3) {
      // Sanity check
      if (!this.question.templateAnswers || this.question.templateAnswers.length === 0) {
        this.selectedAnswer = {
          answerId: -1,
          answerValue: '',
          isActive: true,
          ratingId: 0
        };
      } else {
        this.selectedAnswer = this.question.templateAnswers[0];
      }

      // set the existing answer
      if (!!this.question.reviewAnswers[0]) {
        this.selectedAnswer = JSON.parse(JSON.stringify(this.selectedAnswer));
        this.selectedAnswer.answerValue = this.question.reviewAnswers[0].answerValue;
      }
    }
  }

  update(answer: ISectionQuestionAnswer): void {
    this.updateAnswer.emit({
      questionId: this.question.reviewTemplateQuestionId,
      answer: answer.answerValue ? [answer] : []
    });
  }

  get questionNumber(): number {
    let questionNumber = 0;
    if (this.reviewInstance && this.question) {
      for (let i = 0; i < this.reviewInstance.sections.length; ++i) {
        const foundIndex = this.reviewInstance.sections[i].questions.findIndex(
          (q: ISectionQuestion) => q.reviewTemplateQuestionId === this.question.reviewTemplateQuestionId);
        if (foundIndex === -1) {
          questionNumber += this.reviewInstance.sections[i].questions.length;
        } else {
          questionNumber += foundIndex + 1;
          break;
        }
      }
    }
    return questionNumber;
  }

}
