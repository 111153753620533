/** Angular */
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {RouterModule} from '@angular/router';

/** Azure */
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent
} from '@azure/msal-angular';

/** Configurations */
import {environment} from '../environments/environment';
import {Config as config} from './config';

/** Components */
import {AppComponent} from './app.component';
import {ReviewTemplatesContainerComponent} from './components/review-templates/review-templates-container/review-templates-container.component';
import {ReviewTemplatesComponent} from './components/review-templates/review-templates/review-templates.component';
import {CompleteReviewComponent} from './components/complete-review/complete-review/complete-review.component';
import {CompleteReviewQuestionComponent} from './components/complete-review/complete-review-question/complete-review-question.component';
import {PrintContentComponent} from './components/shared/print-content/print-content.component';
import {PdfLayoutComponent} from './components/shared/pdf-layout/pdf-layout.component';
import {LandingComponent} from 'src/app/components/landing/landing.component';

/** Guard */
import {EmployeeIdGuard} from './employee-id.guard';

/** Modules */
import {AppRoutingModule} from './app-routing.module';
import {EmployeeModule} from './components/employee/employee.module';
import {SharedModule} from './components/shared/shared.module';
import {CreateReviewModule} from './components/create-review/create-review.module';
import {ConfigurationModule} from './components/configuration/configuration.module';
import {SkillsMatrixModule} from './components/skills-matrix/skills-matrix.module';


/** NGRX */
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {LetModule, PushModule} from '@ngrx/component';
import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import * as fromReview from './store/reducers/review.reducer';
import {EffectsModule} from '@ngrx/effects';
import {ReviewEffects} from './store/effects/review.effects';
import * as fromConfig from './store/reducers/config.reducer';
import {ConfigEffects} from './store/effects/config.effects';
import * as fromEmployee from './store/reducers/employee.reducer';
import {EmployeeEffects} from './store/effects/employee.effects';

/** Pipes */
import {FilterReviewTemplatesPipe} from './pipes/filter-review-templates.pipe';

/** Plugins */
import {NZ_ICONS} from 'ng-zorro-antd/icon';
import {NZ_I18N, en_US} from 'ng-zorro-antd/i18n';
import {IconDefinition} from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import {NzNotificationService} from 'ng-zorro-antd/notification';

/** Services */
import {EmployeeProfileService} from './services/employee-profile.service';
import {CompetencyService} from './services/competency.service';
import {ExperienceLevelService} from './services/experience-level.service';
import {SectionsService} from './services/sections.service';
import {WeightingsService} from './services/weightings.service';
import {ReviewTemplatesService} from './services/review-templates.service';
import {ReviewInstancesService} from './services/review-instances.service';
import {routerReducer, StoreRouterConnectingModule} from '@ngrx/router-store';
import { RouteEffects } from 'src/app/store/effects/route.effects';

// console.log all actions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    console.log('action', action.type, action);
    console.log('state after reducer', reducer(state, action));
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = environment.production ? [] : [debug];

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);

// https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/samples/msal-angular-v2-samples/angular12-sample-app
// Remove this line to use Angular Universal
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string): void {
  if (environment.production) {
    console.log(message);
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.issuer,
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}


export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // Manually add specific URLs within config where others in the same object are not protected
  protectedResourceMap.set(config.employeeProfiles.getEmployeeList.toString(), [environment.scope]);
  protectedResourceMap.set(config.employeeProfiles.getMyEmployeeProfileDetails.toString(), [environment.scope]);
  protectedResourceMap.set('/internal-employees/employee-details/employee-number/', [environment.scope]);
  protectedResourceMap.set('/reviews/employees/employee-number/', [environment.scope]);
  // All review-templates
  protectedResourceMap.set('/review-templates/', [environment.scope]);
  // protectedResourceMap.set('/reviews/', [environment.scope]);
  protectedResourceMap.set('/reviews/revoke/review/', [environment.scope]);
  protectedResourceMap.set('/reviews/updates/review/', [environment.scope]);
  /** Utilisation */
  protectedResourceMap.set('/Utlilisation', [environment.scope]);
  // Automatically add all URLs within config objects where no URLs are unprotected
  Object.entries(config.competency).forEach(([key, value]) => {
    protectedResourceMap.set(value.toString(), [environment.scope]);
  });
  Object.entries(config.experienceLevel).forEach(([key, value]) => {
    protectedResourceMap.set(value.toString(), [environment.scope]);
  });
  Object.entries(config.reviewAnswers).forEach(([key, value]) => {
    protectedResourceMap.set(value.toString(), [environment.scope]);
  });
  Object.entries(config.weightings).forEach(([key, value]) => {
    protectedResourceMap.set(value.toString(), [environment.scope]);
  });
  Object.entries(config.ratings).forEach(([key, value]) => {
    protectedResourceMap.set(value.toString(), [environment.scope]);
  });
  Object.entries(config.reviews).forEach(([key, value]) => {
    protectedResourceMap.set(value.toString(), [environment.scope]);
  });

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.scope]
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  declarations: [
    // Component
    AppComponent,
    CompleteReviewComponent,
    CompleteReviewQuestionComponent,
    PrintContentComponent,
    PdfLayoutComponent,
    ReviewTemplatesContainerComponent,
    ReviewTemplatesComponent,
    LandingComponent,
    // Pipe
    FilterReviewTemplatesPipe
  ],
  imports: [
    // Angular
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    LetModule, PushModule,
    RouterModule,
    StoreModule.forRoot({}, {metaReducers}),
    EffectsModule.forRoot([]),
    StoreModule.forFeature(fromReview.reviewFeatureKey, fromReview.reducer),
    StoreModule.forFeature(fromConfig.configFeatureKey, fromConfig.reducer),
    StoreModule.forFeature(fromEmployee.employeeFeatureKey, fromEmployee.reducer),
    EffectsModule.forFeature([ReviewEffects, ConfigEffects, EmployeeEffects, RouteEffects]),
    // Azuer
    MsalModule,
    // Modules
    CreateReviewModule,
    ConfigurationModule,
    EmployeeModule,
    SkillsMatrixModule,
    FormsModule,
    SharedModule,
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot({router: routerReducer}),
    StoreDevtoolsModule.instrument({
      name: 'levelUP',
      maxAge: 25,
      logOnly: environment.production
    }),
  ],
  providers: [
    // Servives
    CompetencyService,
    EmployeeProfileService,
    ExperienceLevelService,
    ReviewTemplatesService,
    ReviewInstancesService,
    SectionsService,
    WeightingsService,
    // Pipe
    DatePipe,
    // Guard
    EmployeeIdGuard,
    // Plugins
    NzNotificationService,
    {
      provide: NZ_I18N,
      useValue: en_US
    },
    {
      provide: NZ_ICONS,
      useValue: icons
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    // Azure
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ],
  exports: [
    FormsModule
  ]
})
export class AppModule {
}
