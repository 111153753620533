<div id="pdf" *ngIf="selectedEmployeeReviewBreakdown">
  <app-user-profile [employeeProfile]="employeeProfile"></app-user-profile>

  <div class="overall-percent">
    <h2>{{selectedEmployeeReviewBreakdown.reviewTemplateName}}</h2>

    <h4>Overall Score</h4>
    <nz-progress
      nzType="circle"
      [nzPercent]="selectedEmployeeReviewBreakdown.calculatedPercentage"
      [nzStrokeColor]="getStrokeColor(selectedEmployeeReviewBreakdown.calculatedPercentage)"
      [nzFormat]="formatPercentSmall">
    </nz-progress>
  </div>

  <h4 style="text-align: left">Section:</h4>

  <div class="section-percentages">
    <div class="section-percentage" *ngFor="let section of selectedEmployeeReviewBreakdown.reviewBreakdownSections">

      <div *ngIf="!section.speechBubble" class="survey-breakdown-progress">
        <nz-progress
          nzType="circle"
          [nzPercent]="section.calculatedPercentage"
          [nzStrokeColor]="getStrokeColor(section.calculatedPercentage)"
          [nzFormat]="formatPercentSmall"
          [nzWidth]="70">
        </nz-progress>
      </div>

      <div *ngIf="section.speechBubble" class="survey-breakdown-progress">
        <i nz-icon nzType="message" nzTheme="outline"></i>
      </div>

      <h4>{{section.sectionName}}{{section.exclude ? ' (Excluded)' : ''}}</h4>
    </div>
  </div>

  <div class="section-breakdown-container" *ngFor="let section of selectedEmployeeReviewBreakdown.reviewBreakdownSections; let index">
    <div class="section-title-container">
      <div class="section-title-description">
        <h6>{{section.sectionName}} {{section.exclude ? '(Excluded)' : ''}}</h6>
        <p>{{section.sectionDescription}}</p>
      </div>
      <nz-progress
        *ngIf="section.calculatedPercentage > 0"
        nzType="circle"
        [nzPercent]="section.calculatedPercentage"
        [nzStrokeColor]="getStrokeColor(section.calculatedPercentage)"
        [nzFormat]="formatPercentSmall"
        [nzWidth]="40">
      </nz-progress>
    </div>

    <nz-table
      *ngFor="let question of section.questions; let index"
      #outBordered
      nzOuterBordered
      [nzTitle]="question.questionTitle + '(Max Rating: ' + getMaxRating(question) + ') (Weighting: ' + question.weighting?.weight + ')'"
      [nzData]="question.questionAnswers"
      [nzShowPagination]="false">
      <thead>
      <tr>
        <th>Reviewer</th>
        <th>Position</th>
        <th>Date Performed</th>
        <th>Response (Rating) [%]</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let answer of outBordered.data">
        <td>{{ answer.reviewerFullName }}</td>
        <td>{{ answer.reviewerPosition }}</td>
        <td>{{ answer.reviewEndTime | date: 'yyy-MM-dd hh:mm:ss' }}</td>
        <td style="white-space: pre-wrap;">
          {{ answer.answerValue }}
          <span *ngIf="question.weighting?.weight > 0 && getMaxRating(question) > 0">
            <span *ngIf="!answer.rating?.excludeAnswerFromCalculation">
              ({{ answer.rating?.rating1 }}) [{{answer.rating?.rating1 / getMaxRating(question) | percent: '1.0-0' }}]
            </span>
            <span *ngIf="answer.rating?.excludeAnswerFromCalculation">
              [Excluded]
            </span>
          </span>
        </td>
      </tr>
      <tr *ngIf="question.calculatedPercentage > 0">
        <td></td>
        <td></td>
        <td></td>
        <td>
          <strong>TOTAL: </strong>
          <span style="font-size: 20px; color: #1c7eba;">
          {{ question.calculatedPercentage | number: '1.0-0' }}%
        </span>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</div>
