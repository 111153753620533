import {ApexChartLineColumn} from './apex-chart-line-column';

export const APEX_CHART_LINE_COLUMN_DEFAULT: ApexChartLineColumn = {
  options: {
    series: [],
    chart: {
      type: 'line',
      height: 350
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    title: {
      text: 'no chart title set'
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'end',
    },
    labels: [],
    xaxis: {
      title: {
        text: 'no x axis title set'
      }
    },
    yaxis: [
      {
        title: {text: 'left y axis title not set'}
      }, {
        opposite: true,
        title: {text: 'right y axis title not set'}
      }
    ],
    theme: {
      palette: 'palette4'
    }
  }
};
