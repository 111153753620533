import { Pipe, PipeTransform } from '@angular/core';
import {ISection} from '../models/interfaces/review-template';

@Pipe({
  name: 'isSectionInList',
  pure: false
})
export class IsSectionInListPipe implements PipeTransform {

  transform(list: ISection[], section: ISection): boolean {
    return list?.length > 0 ? list.findIndex(s => s.sectionId === section.sectionId) > -1 : false;
  }

}
