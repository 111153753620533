<div class="modal-notify-body">
  <div class="icon-container" [class]="colorOverrideByType || type">
    <i *ngIf="type === 'info'" nz-icon nzType="info-circle" nzTheme="outline"></i>
    <i *ngIf="type === 'warning'" nz-icon nzType="exclamation-circle" nzTheme="outline"></i>
    <i *ngIf="type === 'success'" nz-icon nzType="check-circle" nzTheme="outline"></i>
    <i *ngIf="type === 'error'" nz-icon nzType="close-circle" nzTheme="outline"></i>
  </div>
  <h1 *ngIf="title" class="modal-notify-body-title">{{title}}</h1>
  <div *ngIf="subtitle" class="modal-notify-body-subtitle">{{subtitle}}</div>
  <div *ngIf="furtherInfo" class="modal-notify-body-further-info"><strong>{{furtherInfo}}</strong></div>
  <div *ngIf="createdDate" class="modal-notify-body-further-info">{{createdDate}}</div>
  <div class="modal-notify-body-confirm" *ngIf="confirmationMessage">{{confirmationMessage}}</div>
  <div class="btn-container">
    <div (click)="onCancel()" *ngIf="cancelText?.length > 0">{{cancelText}}</div>
    <div (click)="onOk()" *ngIf="okText?.length > 0">{{okText}}</div>
  </div>
</div>
