import {createSelector} from '@ngrx/store';
import {selectIsConfigManagementLoading, } from './config.selectors';
import {selectIsEmployeeManagementLoading} from './employee.selectors';
import {selectIsReviewManagementLoading} from './review.selectors';

// =======================================================================================
// Application Loading
// =======================================================================================

export const selectIsApplicationLoading = createSelector(
  selectIsConfigManagementLoading,
  selectIsEmployeeManagementLoading,
  selectIsReviewManagementLoading,
  (isConfigLoading: boolean, isEmployeesLoading: boolean, isReviewsLoading) => {
    return isConfigLoading || isEmployeesLoading|| isReviewsLoading;
}
);
