<ng-container *ngrxLet="isLoading$; let isLoading">
  <div class="d-flex" *ngIf="!isLoading">
    <div class="img-container mr-4">
      <img
        [src]="employeeProfile?.photoUrl || 'assets/img/logo-white.png'"
        class="rounded-circle float-left"
        alt="Profile picture"
        [style.object-position]="employeeProfile?.photoUrl ? 'center' : 'left'">
    </div>
    <div class="name-title-group d-flex flex-column justify-content-center">
      <div style="font-size: 24px; margin-bottom: 0;">{{ employeeProfile?.firstName }} {{ employeeProfile?.lastName }}
      </div>
      <div style="font-size: 15px; margin-bottom: 0;">{{ isUrlReview ? '' : employeeProfile?.jobTitle }}</div>
    </div>
  </div>

  <div *ngIf="isLoading">
    <app-profile-skeleton></app-profile-skeleton>
  </div>
</ng-container>
