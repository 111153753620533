import {createReducer, on} from '@ngrx/store';
import * as EmployeeActions from '../actions/employee.actions';
import {IEmployeeBambooHR} from '../../models/interfaces/employee-profile';
import {Utilisation} from '../../models/utilisation/utilisation';
import {subMonths} from 'date-fns';

export const employeeFeatureKey = 'employee';

export interface EmployeeState {
  // Employee List
  filterText: string,
  isEmployeeListLoading: boolean;
  // Employee Profile
  isEmployeeProfileLoading: boolean;
  employeeProfile: IEmployeeBambooHR;
  isMyProfileLoading: boolean;
  userProfile: IEmployeeBambooHR;
  employeeList: IEmployeeBambooHR[];
  /** Utilisation */
  isUtilisationLoading: boolean;
  utilisation: Utilisation[];
  utilisationDates: Date[];
  averageUtilisation: number;
}

export const initialState: EmployeeState = {
  // Employee List
  filterText: '',
  isEmployeeListLoading: false,
  employeeList: [],
  // Employee Profile
  isEmployeeProfileLoading: false,
  employeeProfile: null,
  isMyProfileLoading: false,
  userProfile: null,
  /** Utilisation */
  isUtilisationLoading: false,
  utilisation: [],
  utilisationDates: [subMonths(new Date(), 1), new Date()],
  averageUtilisation: 0
};

export const reducer = createReducer(
  initialState,
  // =======================================================================
  // Employee List
  // =======================================================================
  on(EmployeeActions.setEmployeeListFilter, (state, {filterText}) => {
    return {
      ...state,
      filterText
    };
  }),
  on(EmployeeActions.getEmployeeList, (state) => {
    return {
      ...state,
      isEmployeeListLoading: true
    };
  }),
  on(EmployeeActions.getEmployeeListComplete, (state, {employeeList}) => {
    return {
      ...state,
      isEmployeeListLoading: false,
      employeeList: employeeList?.length > 0 ? [...employeeList].sort((a, b) => a.firstName > b.firstName ? 1 : -1) : []
    };
  }),
  // =======================================================================
  // Employee Profile
  // =======================================================================
  on(EmployeeActions.getEmployeeProfileWithEmployeeNumber, (state) => {
    return {
      ...state,
      isEmployeeProfileLoading: true
    };
  }),
  on(EmployeeActions.getEmployeeProfileWithEmployeeNumberComplete, (state, {employeeProfile}) => ({
    ...state,
    isEmployeeProfileLoading: false,
    employeeProfile
  })),
  on(EmployeeActions.getUserProfile, (state) => {
    return {
      ...state,
      isMyProfileLoading: true
    };
  }),
  on(EmployeeActions.getUserProfileComplete, (state, {userProfile}) => (
    {
      ...state,
      isMyProfileLoading: false,
      userProfile
    }
  )),

   // * ===============================================================
   // * Utilisation
   // * ===============================================================
  on(EmployeeActions.getUtilisationForLoggedInEmployee, (state) => ({
    ...state,
    isUtilisationLoading: true,
    utilisation: []
  })),
  on(EmployeeActions.getUtilisationForLoggedInEmployeeByDateRange, (state, {datesSelected}) => ({
    ...state,
    isUtilisationLoading: true,
    utilisation: []
  })),
  on(EmployeeActions.getUtilisationForLoggedInEmployeeByDateRangeAndEmailAddress, (state, {datesSelected, emailAddress}) => ({
    ...state,
    isUtilisationLoading: true,
    utilisation: []
  })),
  on(EmployeeActions.getUtilisationForLoggedInEmployeeComplete, (state, {utilization}) => ({
    ...state,
    isUtilisationLoading: false,
    utilisation: utilization
  })),
  on(EmployeeActions.setIsUtilisationLoading, (state, {isLoading}) => ({
    ...state,
    isUtilisationLoading: isLoading
  })),
  on(EmployeeActions.setUtilisationDates, (state, {utilisationDates}) => ({
    ...state,
    utilisationDates
  })),
  on(EmployeeActions.getAverageUtilisationComplete, (state, {utilization}) => ({
    ...state,
    averageUtilisation: utilization
  })),
);

