export interface IStep {
  index: number;
  sectionName: string;
  status: string;
}

export const STEPSTATUS = {
  finish: 'finish',
  process: 'process',
  wait: 'wait'
};
