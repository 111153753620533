import { Injectable } from '@angular/core';
import { Config } from '../config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IRating} from '../models/interfaces/ratings';

@Injectable({
  providedIn: 'root'
})
export class RatingsService {

  constructor(private http: HttpClient) { }

  getRatingsURL = Config.ratings.getRatings;
  deleteRatingURL = Config.ratings.deleteRating;
  createRatingURL = Config.ratings.createRating;
  updateRatingURL = Config.ratings.updateRating;

  headers = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Ocp-Apim-Subscription-Key': Config.API_KEY,
  });

  getRatings(): Observable<IRating[]> {
    return this.http.get<IRating[]>(this.getRatingsURL, {headers: this.headers});
  }

  deleteRating(ratingId: number): Observable<void> {
    const options = {
      headers: this.headers,
      body: {
        ratingId
      },
    };

    return this.http.delete<void>(this.deleteRatingURL, options);
  }

  createRating(rating: IRating): Observable<number> {
    const ratingToBeCreated = {
      ratingDescription: rating.ratingDescription,
      rating: rating.rating1
    };
    return this.http.post<number>(this.createRatingURL, {...ratingToBeCreated}, {headers: this.headers});
  }

  updateRating(rating: IRating): Observable<boolean> {
    const ratingToBeUpdated = {
      ratingId: rating.ratingId,
      ratingDescription: rating.ratingDescription,
      rating: rating.rating1
    };
    return this.http.put<boolean>(this.updateRatingURL, {...ratingToBeUpdated}, {headers: this.headers});
  }
}
