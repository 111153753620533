import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Utilisation} from '../models/utilisation/utilisation';
import {Config as config} from '../config';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UtilisationService {

  urlGetUtilisation = config.utilisation.getUtilisation;
  urlGetUtilisationByDateRange = config.utilisation.getUtilisationByDateRange;
  urlGetUtilisationByDateRangeAndEmail = config.utilisation.getUtilisationByDateRangeAndEmail;
  urlAverageGetUtilisationByDateRange = config.utilisation.getAverageUtilisationByDateRange;

  headers = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Ocp-Apim-Subscription-Key': config.API_KEY,
  });

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe
  ) {
  }

  getUtilisation(): Observable<Utilisation[]> {
    return this.http.get<Utilisation[]>(
      this.urlGetUtilisation, {
        headers: this.headers
      }
    );
  }

  getUtilisationByDateRange(datesSelected: Date[]): Observable<Utilisation[]> {
    const url = this.urlGetUtilisationByDateRange
      .replace('{dateFrom}', this.datePipe.transform(datesSelected[0], 'yyyy-MM-dd'))
      .replace('{dateTo}', this.datePipe.transform(datesSelected[1], 'yyyy-MM-dd'));
    return this.http.get<Utilisation[]>(
      url, {
        headers: this.headers
      }
    );
  }

  getUtilisationByDateRangeAndEmailAddress(datesSelected: Date[], emailAddress?: string): Observable<Utilisation[]> {
    const url = this.urlGetUtilisationByDateRangeAndEmail
      .replace('{email}', emailAddress)
      .replace('{dateFrom}', this.datePipe.transform(datesSelected[0], 'yyyy-MM-dd'))
      .replace('{dateTo}', this.datePipe.transform(datesSelected[1], 'yyyy-MM-dd'));
    return this.http.get<Utilisation[]>(
      url, {
        headers: this.headers
      }
    );
  }

  getAverageUtilisationByDateRangeAndEmailAddress(datesSelected: Date[], emailAddress?: string): Observable<number> {
    let url = this.urlAverageGetUtilisationByDateRange
      .replace('{dateFrom}', this.datePipe.transform(datesSelected[0], 'yyyy-MM-dd'))
      .replace('{dateTo}', this.datePipe.transform(datesSelected[1], 'yyyy-MM-dd'));
    if (emailAddress) {
      url += '&emailAddress=' + emailAddress;
    }
    return this.http.get<number>(
      url, {
        headers: this.headers
      }
    );
  }
}
