<div class="employee-utilisation-container">

  <app-employee-utilisation
    [setIsEmployeeUtilizationLoading]="isUtilisationLoading$ | ngrxPush"
    [setUtilisation]="utilisation$ | ngrxPush"
    [setAverage]="average$ | ngrxPush"
  >
  </app-employee-utilisation>

</div>
