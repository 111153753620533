<div class="employee-container">

  <app-employee-container-header
    [isEmployeeListLoading]="(isEmployeeListLoading$ | ngrxPush)"
    [setEmployeeListAndFilter]="(employeeListAndFilter$ | ngrxPush)">
  </app-employee-container-header>

  <div class="employee-container-list-container">
    <app-employee-container-list
      [isEmployeeListLoading]="(isEmployeeListLoading$ | ngrxPush)"
      [setEmployeeListAndFilter]="(employeeListAndFilter$ | ngrxPush)">
    </app-employee-container-list>
  </div>

</div>
