<div class="container-fluid pb-4" *ngIf="currentPageIndex === 0">

  <div row class="mt-4">
    <h2>CREATE A SURVEY</h2>
    <div class="info-segment">
      Here's where you create a survey. Select the competency and level of experience the survey targets, then add sections to get going. If you need to you can <a [routerLink]="['/survey-config']">configure system settings here</a>.
    </div>
  </div>

  <div style="max-width: 800px;">
    <nz-steps [nzCurrent]="currentPageIndex" nzLabelPlacement="vertical" (nzIndexChange)="currentPageIndex = $event; this.sortSelectedSections();">
      <nz-step nzTitle="Build Survey"></nz-step>
      <nz-step nzTitle="Add Questions" [nzDisabled]="selectedSections.length < 1"></nz-step>
    </nz-steps>
  </div>

  <h4 class="d-flex mt-4" style="align-items: center;">
    Competency:
    <div [hidden]="!isCompetenciesLoading" class="spinner-border ml-2" style="width: 1.2rem; height: 1.2rem; border-width: 0.1rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </h4>

  <div class="d-flex mt-2" style="flex-wrap: wrap">
    <div
      class="filter-pill mb-2"
      [class.filter-pill-selected]="selectedCompetency === competency"
      (click)="selectedCompetency = competency"
      *ngFor="let competency of competencyList">
      {{competency.competencyName}}
    </div>
  </div>

  <h4 class="d-flex mt-4" style="align-items: center;">
    Level of Experience:
    <div [hidden]="!isExperienceLevelLoading" class="spinner-border ml-2" style="width: 1.2rem; height: 1.2rem; border-width: 0.1rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </h4>

  <div class="d-flex mt-2" style="flex-wrap: wrap">
    <div
      class="filter-pill mb-2"
      [class.filter-pill-selected]="selectedExperienceLevel === experienceLevel"
      (click)="selectedExperienceLevel = experienceLevel"
      *ngFor="let experienceLevel of experienceLevelList">
      {{experienceLevel.levelName}}
    </div>
  </div>

  <h4 class="d-flex mt-4" style="align-items: center;">
    Sections:
    <div [hidden]="!isSectionListLoading" class="spinner-border ml-2" style="width: 1.2rem; height: 1.2rem; border-width: 0.1rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </h4>

  <div class="info-segment mt-1 mb-0">
    Please select the sections that you would like to add to your survey. You can drag the sections to re-order them in order of preference.
  </div>

  <div class="mt-2">
    <div cdkDropList
      class="draggable-list"
      (cdkDropListDropped)="dropSection($event)">
      <div
        cdkDrag
        cdkDragLockAxis="y"
        class="draggable selectable pointer-cursor"
        [class.selected]="selectedSections | isSectionInList: section"
        (click)="toggleSection(section)"
        *ngFor="let section of sectionList">
        <div>
          <i nz-icon nzType="more" nzTheme="outline"></i>
          <h6 style="border-bottom: 1px solid rgba(0,0,0,0.05); padding-bottom: 0.5em;">
            <label style="margin-right: 1em" nz-checkbox [ngModel]="selectedSections | isSectionInList: section" (nzCheckedChange)="toggleSection(section)" (click)="$event.stopPropagation()"></label>
            {{section.sectionName}}
          </h6>
          {{section.sectionDescription}}
        </div>
      </div>
    </div>
  </div>

  <div class="mt-4 d-flex">
<!--    Implement correctly -->
<!--    <div-->
<!--      class="filter-pill ml-1"-->
<!--      [style.cursor]="saving ? 'not-allowed' : 'pointer'"-->
<!--      [style.opacity]="saving ? 0.4 : 1"-->
<!--      (click)="!saving ? save(false) : null">-->
<!--      <i *ngIf="saving" nz-icon nzType="loading" nzTheme="outline"></i>-->
<!--      <i *ngIf="!saving" nz-icon nzType="save" nzTheme="outline"></i>-->
<!--      {{saving ? 'Saving...' : 'Save Draft'}}-->
<!--    </div>-->
    <div
      class="filter-pill filter-pill-selected ml-1"
      [style.cursor]="selectedSections.length < 1 ? 'not-allowed' : 'pointer'"
      [style.opacity]="selectedSections.length < 1 ? 0.4 : 1"
      (click)="selectedSections.length > 0 ? nextPage() : null">
      Next
    </div>
  </div>

</div>

<div class="container-fluid pb-4" *ngIf="currentPageIndex === 1">

  <div row class="mt-4">
    <div class="info-segment">
      In order to create your review, please add questions to the sections you previously selected.
    </div>
    <h2>{{reviewTemplateToUpdate ? 'UPDATE' : 'CREATE'}} DVT SURVEY</h2>
    <div class="template-name">
      <h4 *ngIf="!reviewTemplateToUpdate">{{selectedExperienceLevel?.levelName}} {{selectedCompetency?.competencyName}} Survey</h4>
      <div *ngIf="!isEditingFriendlyName" class="template-name-flex">
        <h4 *ngIf="reviewTemplateToUpdate">{{reviewTemplateToUpdate.reviewTemplateDisplayName}}</h4>
<!--    Todo: Reimplement the followin when API is ready    -->
<!--        <i nz-icon nzType="edit" nzTheme="outline" class="edit-icon" nz-tooltip nzTooltipTitle="Edit Template Name" (click)="isEditingFriendlyName = true"></i>-->
      </div>
      <div *ngIf="isEditingFriendlyName" class="template-name-flex ml-1">
        <h4 *ngIf="reviewTemplateToUpdate">{{reviewTemplateToUpdate.levelName}} {{reviewTemplateToUpdate.competencyName}} Review</h4>
        <input nz-input [(ngModel)]="friendlyName" class="w-50 ml-2" placeholder="Add custom name to be used"/>
      </div>
    </div>
    <p *ngIf="reviewTemplateToUpdate?.createdDate">Date Created: {{reviewTemplateToUpdate?.createdDate}}</p>
  </div>

  <div style="max-width: 800px;">
    <nz-steps [nzCurrent]="currentPageIndex" nzLabelPlacement="vertical" (nzIndexChange)="currentPageIndex = $event">
      <nz-step nzTitle="Build Survey"></nz-step>
      <nz-step nzTitle="Add Questions"></nz-step>
    </nz-steps>
  </div>

  <h3 class="mt-4">
    Description:

    <div [hidden]="!descriptionLoading" class="spinner-border ml-2" style="width: 1.2rem; height: 1.2rem; border-width: 0.1rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </h3>

  <textarea class="form-control mb-4" rows="3" [(ngModel)]="description"></textarea>

  <!-- Sections -->
  <nz-collapse nzBordered="false" [nzExpandIconPosition]="'right'" class="collapse-pane" *ngFor="let section of selectedSections; let i = index">
    <nz-collapse-panel [nzHeader]="sectionHeader" [nzActive]="false" (nzActiveChange)="sectionsCollapsed[i] = $event">

      <div style="padding: 4px 16px;">
        <!-- List of Section Questions -->
        <div cdkDropList
             class="draggable-list"
             (cdkDropListDropped)="dropQuestion($event, section)">
          <app-create-review-question
            *ngFor="let question of sectionsQuestions[section.sectionId]"
            cdkDrag
            cdkDragLockAxis="y"
            class="draggable grab-cursor"
            [weightings]="weightings"
            [ratings]="ratings"
            [section]="section"
            [question]="question"
            (questionWeightingUpdated)="updateCanSave()"
            (deleteQuestion)="deleteQuestion(question)">
          </app-create-review-question>
        </div>

        <button nz-button nzType="primary" (click)="addQuestion(section)">+ Add Question</button>
      </div>
      <ng-template #sectionHeader>
        <div class="d-flex flex-column">
          <div class="heading">
            <h4 class="d-flex" style="align-items: center;">
              {{section.sectionName}}

              <nz-switch style="margin: auto 0 auto auto" [(ngModel)]="section.exclude" nzCheckedChildren="Yes"
                         nzUnCheckedChildren="No" (click)="$event.stopPropagation()"></nz-switch>
              <span style="font-size: 0.75rem; font-weight: 300; color: #027fba; margin: auto 0.5rem;">Exclude from survey calculation?</span>
            </h4>
            <p class="m-0">{{section?.sectionDescription}}</p>
          </div>
          <div class="white-subheading" [hidden]="sectionsCollapsed[i]">
            <div cdkDropList class="draggable-list" (cdkDropListDropped)="dropQuestion($event)" >
              <div
                cdkDrag
                cdkDragLockAxis="y"
                class="draggable grab-cursor"
                style="position: relative; margin: 0"
                *ngFor="let question of sectionsQuestions[section.sectionId]; let qi = index;">
                <div>
                  <i nz-icon nzType="more" nzTheme="outline"></i>
                  <strong>Question {{qi + 1}}: </strong>{{question?.questionTitle}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </nz-collapse-panel>
  </nz-collapse>

  <div class="mt-4 d-flex">

<!--    Todo: Implement correctly -->
<!--    <div-->
<!--      class="filter-pill ml-1 mb-2"-->
<!--      (click)="previousPage()">-->
<!--      Back-->
<!--    </div>-->

    <div
      class="filter-pill filter-pill-selected mb-2"
      [style.cursor]="saving || isCompleteReviewDisabled ? 'not-allowed' : 'pointer'"
      [style.opacity]="saving || isCompleteReviewDisabled ? 0.4 : 1"
      (click)="!saving && !isCompleteReviewDisabled ? save(false, true) : null">
      <i *ngIf="saving" nz-icon nzType="loading" nzTheme="outline"></i>
      <i *ngIf="!saving" nz-icon nzType="save" nzTheme="outline"></i>
      {{saving ? 'Saving...' : 'Save'}}
    </div>
  </div>

</div>

<nz-modal
  [(nzVisible)]="isSaveModalVisible"
  (nzOnCancel)="isSaveModalVisible = false"
  [nzFooter]="null"
  [nzTitle]="null"
  [nzWidth]="'550px'"
  [nzBodyStyle]="{ padding: '0', background: 'transparent' }"
  [nzAutofocus]="null"
  [nzContent]="saveConfirmationModal">
</nz-modal>

<ng-template #saveConfirmationModal>
  <app-modal-notify-content
    [type]="wasSaveSuccessful ? 'success' : 'error'"
    [title]="wasSaveSuccessful ? 'Survey has been saved!' : 'Survey failed to save.'"
    [subtitle]="wasSaveSuccessful ? null : 'Please try again or contact an administrator if this continues to fail.'"
    [furtherInfo]="reviewTemplateToUpdate?.reviewTemplateDisplayName"
    [createdDate]="reviewTemplateToUpdate?.createdDate"
    [cancelText]="null"
    okText="Done"
    (cancel)="closeModal()"
    (ok)="closeModal(); isSurveyComplete ? navigateToTemplates() : null;"
  ></app-modal-notify-content>
</ng-template>
