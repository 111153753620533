import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Config as config} from '../config';
import {Observable} from 'rxjs';
import {IReviewTemplate, ISection, ISectionQuestion} from '../models/interfaces/review-template';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReviewTemplatesService {

  constructor(private http: HttpClient) {
  }

  headers = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Ocp-Apim-Subscription-Key': config.API_KEY,
  });

  getReviewTemplates(competencyId: string, levelId: string): Observable<IReviewTemplate[]> {
    const url = config.reviewTemplates.getReviewTemplates.replace('{competencyId}', competencyId).replace('{levelId}', levelId);
    return this.http.get<IReviewTemplate[]>(url, {headers: this.headers}).pipe(
      map((response: IReviewTemplate[]) => {
        return this.assignIndexToMultipleQuestions(response);
      })
    );
  }

  getReviewTemplate(reviewTemplateId: string): Observable<IReviewTemplate> {
    const url = config.reviewTemplates.getReviewTemplate.replace('{reviewTemplateId}', reviewTemplateId);
    return this.http.get<IReviewTemplate>(url, {headers: this.headers}).pipe(
      map((response: IReviewTemplate) => {
        return this.assignIndexToQuestions(response);
      })
    );
  }

  deleteReviewTemplate(reviewTemplateId: number): Observable<boolean> {
    const url = config.reviewTemplates.deleteReviewTemplate.replace('{reviewTemplateId}', reviewTemplateId.toString());
    return this.http.delete<boolean>(url, {headers: this.headers});
  }

  createReviewTemplate(reviewTemplate: IReviewTemplate): Observable<IReviewTemplate> {
    const url = config.reviewTemplates.createReviewTemplate;
    return this.http.post<IReviewTemplate>(url, {...reviewTemplate}, {headers: this.headers});
  }

  updateReviewTemplate(reviewTemplate: IReviewTemplate): Observable<IReviewTemplate> {
    const url = config.reviewTemplates.updateReviewTemplate.replace('{reviewTemplateId}', reviewTemplate.reviewTemplateId + '');
    return this.http.put<IReviewTemplate>(url, {...reviewTemplate}, {headers: this.headers});
  }

  private assignIndexToMultipleQuestions(reviewTemplates: IReviewTemplate[]): IReviewTemplate[] {
    reviewTemplates.forEach((review: IReviewTemplate) => {
      review = this.assignIndexToQuestions(review);
    });
    return reviewTemplates;
  }

  private assignIndexToQuestions(reviewTemplate: IReviewTemplate): IReviewTemplate {
    reviewTemplate.sections.forEach((section: ISection) => {
      const questionOrder = [...new Set(section.questions.map((x: ISectionQuestion) => x.position))];
      if (questionOrder.length < section.questions.length) {
        section.questions = this.addIndexAsPosition(section.questions);
      }
    });
    return reviewTemplate;
  }

  private addIndexAsPosition(questions: ISectionQuestion[]): ISectionQuestion[] {
    questions.forEach((question: ISectionQuestion, index: number) => {
      question.position = index;
    });
    return questions;
  }

}
