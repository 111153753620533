import {ResolveFn} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {EmployeeState} from 'src/app/store/reducers/employee.reducer';
import {inject} from '@angular/core';
import {selectUserProfile} from 'src/app/store/selectors/employee.selectors';
import {IEmployeeBambooHR} from 'src/app/models/interfaces/employee-profile';
import {filter, take} from 'rxjs/operators';

export const userResolver: ResolveFn<Observable<IEmployeeBambooHR>> = (): Observable<IEmployeeBambooHR> => {
  const employeeStore: Store<EmployeeState> = inject(Store<EmployeeState>);

  return employeeStore
    .select(selectUserProfile)
    .pipe(
      filter(employee => !!employee),
      take(1)
    );
};
