import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromConfig from '../reducers/config.reducer';
import {ConfigState} from '../reducers/config.reducer';
import {selectUserProfile} from 'src/app/store/selectors/employee.selectors';
import {IEmployeeBambooHR} from 'src/app/models/interfaces/employee-profile';
import {selectCurrentRoute} from './router.selectors';

export const selectConfigState = createFeatureSelector<fromConfig.ConfigState>(
  fromConfig.configFeatureKey
);

// =======================================================================
// Application
// =======================================================================
export const selectIsApplicationMenuVisible = createSelector(
  selectUserProfile,
  selectCurrentRoute,
  (userProfile: IEmployeeBambooHR, route) => {
    return (userProfile && route?.url?.[0]?.path !== 'review') ? userProfile?.isAdmin : false;
  }
);


// =======================================================================
// Weightings
// =======================================================================
export const selectIsWeightingsLoading = createSelector(
  selectConfigState,
  (state: ConfigState) => state.isWeightingsLoading
);

export const selectWeightings = createSelector(
  selectConfigState,
  (state: ConfigState) => state.weightings.filter(w => w.isActive)
);

// =======================================================================
// Ratings
// =======================================================================

export const selectIsRatingsLoading = createSelector(
  selectConfigState,
  (state: ConfigState) => state.isRatingsLoading
);

export const selectAllRatings = createSelector(
  selectConfigState,
  (state: ConfigState) => state.ratings
);

export const selectRatings = createSelector(
  selectConfigState,
  (state: ConfigState) => state.ratings.filter(r => r.isActive)
);

// =======================================================================
// Competencies
// =======================================================================

export const selectIsCompetenciesLoading = createSelector(
  selectConfigState,
  (state: ConfigState) => state.isCompetenciesLoading
);

export const selectCompetencies = createSelector(
  selectConfigState,
  (state: ConfigState) => state.competencies
);

// =======================================================================
// Levels of Experience
// =======================================================================

export const selectIsExperienceLevelsLoading = createSelector(
  selectConfigState,
  (state: ConfigState) => state.isExperienceLevelsLoading
);

export const selectExperienceLevels = createSelector(
  selectConfigState,
  (state: ConfigState) => state.experienceLevels
);

// =======================================================================
// Sections
// =======================================================================

export const selectIsSectionsLoading = createSelector(
  selectConfigState,
  (state: ConfigState) => state.isSectionsLoading
);

export const selectSections = createSelector(
  selectConfigState,
  (state: ConfigState) => state.sections
);


// =======================================================================
// Config Management
// =======================================================================
export const selectIsConfigManagementLoading = createSelector(
  selectIsWeightingsLoading,
  selectIsRatingsLoading,
  selectIsCompetenciesLoading,
  selectIsExperienceLevelsLoading,
  selectIsSectionsLoading,
  (weightings: boolean, ratings: boolean, competencies: boolean, experienceLevels: boolean, sections: boolean) => {
    return weightings || ratings || competencies || experienceLevels || sections;
  }
);
