import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ISection, ISectionQuestion, ISectionQuestionAnswer} from '../../../models/interfaces/review-template';
import {IRating} from '../../../models/interfaces/ratings';
import {IWeighting} from '../../../models/interfaces/weightings';

@Component({
  selector: 'app-create-review-question',
  templateUrl: './create-review-question.component.html',
  styleUrls: ['./create-review-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateReviewQuestionComponent {

  @Input() section: ISection;
  @Input() question: ISectionQuestion;
  @Input() ratings: IRating[];
  @Input() weightings: IWeighting[];
  @Output() deleteQuestion = new EventEmitter();
  @Output() questionWeightingUpdated = new EventEmitter();

  constructor() { }

  // Needed as at least one answer must be able to be populated for a text answer
  checkForAtLeastOneAnswer(): void {
    if (this.question && this.question.templateAnswers.length === 0) {
      this.addAnswer();
    }
  }

  preAddRatingsAsAnswers(): void {
    if (this.question && this.question.templateAnswers.length === 0 && this.ratings?.length > 0) {
      this.ratings.forEach((rating, index) => {
        const newAnswer: ISectionQuestionAnswer = {
          answerId: index,
          answerValue: rating.ratingDescription,
          isActive: true,
          ratingId: rating.ratingId
        };
        this.question.templateAnswers.push(newAnswer);
      });
    }
  }

  addAnswer(): void {
    if (this.question && this.ratings?.find(rating => rating.rating1 === -1)) {
      const newAnswer: ISectionQuestionAnswer = {
        answerId: this.question.templateAnswers.length, // lazy index + 1
        answerValue: '',
        isActive: true,
        ratingId: this.ratings.find(rating => rating.rating1 === -1).ratingId
      };
      this.question.templateAnswers.push(newAnswer);
    }
  }

  deleteAnswer(answer: ISectionQuestionAnswer): void {
    this.question.templateAnswers.splice(this.question.templateAnswers.findIndex(a => a.answerId === answer.answerId), 1);
  }

  deleteThisQuestion(): void {
    this.deleteQuestion.emit();
  }

  weightingChanged(): void {
    this.questionWeightingUpdated.emit();
  }

}
