import {IEmployeeBambooHR} from '../models/interfaces/employee-profile';

export class UtilsEmployee {

  static filterEmployeeList(employeeList: IEmployeeBambooHR[], employeeFilter: string): IEmployeeBambooHR[] {
    if (employeeFilter == null) {
      return employeeList;
    }
    const filterText = employeeFilter.toLowerCase().trim();
    return [...employeeList].filter((e: IEmployeeBambooHR) =>
      e.firstName?.toLowerCase().indexOf(filterText) > -1 ||
      e.lastName?.toLowerCase().indexOf(filterText) > -1 ||
      // e.jobTitle?.toLowerCase().indexOf(filterText) > -1 ||
      e.workEmail?.toLowerCase().indexOf(filterText) > -1
      // e.teamName?.toLowerCase().indexOf(filterText) > -1
    );
  }

}
