import { Injectable } from '@angular/core';
import { IExperienceLevel } from '../models/interfaces/experience-level';
import { Config as config } from '../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExperienceLevelService {
  constructor(private http: HttpClient) {}

  headers = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Ocp-Apim-Subscription-Key': config.API_KEY,
  });

  getAllExperienceLevels(): Observable<IExperienceLevel[]> {
    return this.http.get<IExperienceLevel[]>(config.experienceLevel.getExperienceLevel, {
      headers: this.headers,
    });
  }

  deleteLevelOfExperience(levelId: number): Observable<void> {
    return this.http.delete<void>(config.experienceLevel.deleteExperienceLevel.replace('{levelId}', levelId.toString()), {
      headers: this.headers,
    });
  }

  createLevelOfExperience(levelOfExperience: IExperienceLevel): Observable<IExperienceLevel> {
    return this.http.post<IExperienceLevel>(config.experienceLevel.createExperienceLevel, levelOfExperience, {
      headers: this.headers
    });
  }

  updateLevelOfExperience(levelOfExperience: IExperienceLevel): Observable<IExperienceLevel> {
    return this.http.put<IExperienceLevel>(config.experienceLevel.updateExperienceLevel, levelOfExperience, {
      headers: this.headers
    });
  }
}
