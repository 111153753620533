<div class="container">
  <form #scheduleReviewForm="ngForm" nz-form>
    <h3>Schedule Survey:</h3>
    <h5>
      {{showTemplateList ? 'Survey Template:' : selectedReviewTemplate?.reviewTemplateDisplayName + ' | Created on ' + selectedReviewTemplate?.createdDate}}
      <div [hidden]="!reviewTemplateListLoading" class="spinner-border ml-2" style="width: 1.2rem; height: 1.2rem; border-width: 0.1rem" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </h5>

    <nz-select
      *ngIf="showTemplateList"
      style="width: 100%"
      class="mb-2"
      nzShowSearch
      nzAllowClear
      nzPlaceHolder="Select a survey"
      [(ngModel)]="selectedReviewTemplate"
      [ngModelOptions]="{standalone: true}">
      <nz-option
        *ngFor="let reviewTemplate of reviewTemplateList"
        [nzLabel]="reviewTemplate.reviewTemplateDisplayName + ' | ' + reviewTemplate.sections.length + ' | Section(s) | ' + reviewTemplate.questionCount + ' Question(s) | Created on ' + reviewTemplate.createdDate"
        [nzValue]="reviewTemplate">
      </nz-option>
    </nz-select>

    <h5>Reviewer Full Name:</h5>
    <input
      nz-input
      name="reviewerFullNameFormControl"
      type="text"
      class="mb-2"
      [(ngModel)]="reviewerFullName"
      [ngModelOptions]="{standalone: true}"
      required>

    <h5>Reviewer Position:</h5>
    <input
      nz-input
      name="reviewerPositionFormControl"
      type="text"
      class="mb-2"
      [(ngModel)]="reviewerPosition"
      [ngModelOptions]="{standalone: true}"
      required>

    <h5>Reviewer Email:</h5>
    <input
      nz-input
      name="emailFormControl"
      type="email"
      class="mb-4"
      [(ngModel)]="reviewerEmail"
      [ngModelOptions]="{standalone: true}"
      required>

    <div
      class="filter-pill"
      [style.cursor]="schedulingReview ? 'not-allowed' : 'pointer'"
      [style.opacity]="schedulingReview ? 0.4 : 1"
      style="justify-content: center; margin-right: auto;"
      (click)="!schedulingReview && !scheduleReviewForm.invalid ? scheduleReview() : null">
      <i *ngIf="schedulingReview" nz-icon nzType="loading" nzTheme="outline"></i> {{schedulingReview ? 'Scheduling...' : 'Schedule Survey'}}
    </div>
  </form>
</div>

<nz-modal
  [nzVisible]="!!newestLink && newestLink.length > 0"
  (nzOnCancel)="clearNewestLink()"
  [nzFooter]="null"
  [nzTitle]="null"
  [nzWidth]="'550px'"
  [nzBodyStyle]="{ padding: '0', background: 'transparent' }"
  [nzAutofocus]="null"
  [nzContent]="scheduleConfirmationModal">
</nz-modal>

<ng-template #scheduleConfirmationModal>
  <app-modal-notify-content
    [type]="'success'"
    [title]="'Survey has been scheduled!'"
    [subtitle]="'The link to access the survey as the reviewer is as follows:'"
    [furtherInfo]="newestLink"
    [cancelText]="null"
    okText="Done"
    (cancel)="clearNewestLink()"
    (ok)="clearNewestLink()"
  ></app-modal-notify-content>
</ng-template>
